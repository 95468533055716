import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from '../../theme';
import './tailwind.min.css'
import './styles.css'
import { ArrowRight, Zap, Users, Rocket, Shield, Stars, Clock, DollarSign, TrendingUp, Video, BarChart, Brain, Phone, CheckCircle, Building, Play, AlertTriangle, Wand, Target, ChartBar, PlayCircle, Info } from 'lucide-react';
import BusinessIcon from '@mui/icons-material/Business';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import StorageIcon from '@mui/icons-material/Storage';
import SchoolIcon from '@mui/icons-material/School';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer, 
  BarChart as RechartsBarChart,
  Bar, 
  Legend, 
  Area, 
  ReferenceLine,
  Label,
  Cell
} from 'recharts';
import MarketPredictions from '../../components/viewers/glow_29_market-predictions';
import Glow30Processor from '../../components/viewers/glow_30_final_sales_pitch';
import { IconButton } from '@mui/material';
import ContrastIcon from '@mui/icons-material/Contrast';
import { Card, CardContent } from '@mui/material';
import { Dialog, DialogContent } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FaExclamationTriangle } from 'react-icons/fa';
import TamSamSomViewer from '../../components/viewers/glow_10_processor_tamsamsom';
import Glow10Processor from '../../components/viewers/glow_10_processor_tamsamsom';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import BarChartIcon from '@mui/icons-material/BarChart';
import GpsFixedIcon from '@mui/icons-material/GpsFixed'; // for target/bullseye
import PieChartIcon from '@mui/icons-material/PieChart';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

// Example of a collapsible section
const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left font-bold text-lg bg-zinc-800 p-4 rounded"
      >
        {title}
      </button>
      {isOpen && <div className="p-4 bg-zinc-900 rounded">{children}</div>}
    </div>
  );
};

const AudienceShowcase = () => {
  const navigate = useNavigate();
  const [activeVideo, setActiveVideo] = useState(0);
  const scrollContainerRef = useRef(null);

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 400; // Adjust this value to control scroll distance
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const videoVariants = [
    {
      id: 1,
      audience: "Investors",
      title: "Market Validation for Investors with Glowtest",
      thumbnail: "https://img.youtube.com/vi/EvtEd1cUf2I/mqdefault.jpg", // Placeholder video thumbnail
      videoId: "EvtEd1cUf2I", // Example YouTube video ID
      description: "Investment-grade market validation with predictive analytics and ROI forecasting",
      keyPoints: [
        "AI-powered market sizing",
        "Risk assessment metrics",
        "Revenue projection models"
      ],
      metrics: {
        engagement: "48%",
        conversion: "42%",
        retention: "93%"
      }
    },
    {
      id: 2,
      audience: "Small Business Owners",
      title: "Cost-Effective Market Testing",
      thumbnail: "https://img.youtube.com/vi/m7XJ0mzwcl8/mqdefault.jpg",
      videoId: "m7XJ0mzwcl8",
      description: "Affordable market testing solution that saves time and reduces risk",
      keyPoints: [
        "Budget-friendly pricing",
        "Quick setup (under 1 hour)",
        "Clear, actionable insights"
      ],
      metrics: {
        engagement: "52%",
        conversion: "45%",
        retention: "88%"
      }
    },
    {
      id: 3,
      audience: "Product Managers",
      title: "Developer-First Testing Platform",
      thumbnail: "https://img.youtube.com/vi/yZjwmQzhdcs/mqdefault.jpg",
      videoId: "yZjwmQzhdcs",
      description: "Data-driven product validation with comprehensive feature testing",
      keyPoints: [
        "Feature prioritization tools",
        "User feedback analytics",
        "Sprint planning insights"
      ],
      metrics: {
        engagement: "56%",
        conversion: "44%",
        retention: "91%"
      }
    },
    {
      id: 4,
      audience: "Marketing Teams",
      title: "Data-Driven Market Insights",
      thumbnail: "https://img.youtube.com/vi/clqe_oPX9v8/mqdefault.jpg",
      videoId: "clqe_oPX9v8",
      description: "Target audience insights and campaign optimization platform",
      keyPoints: [
        "Audience segmentation",
        "Message testing tools",
        "ROI optimization"
      ],
      metrics: {
        engagement: "54%",
        conversion: "47%",
        retention: "89%"
      }
    },
    {
      id: 5,
      audience: "Communities",
      title: "Product Validation Suite",
      thumbnail: "https://img.youtube.com/vi/MaLWAJsCQo0/mqdefault.jpg",
      videoId: "MaLWAJsCQo0",
      description: "Community-driven product validation and feedback management",
      keyPoints: [
        "Community engagement tools",
        "Feedback aggregation",
        "Sentiment analysis"
      ],
      metrics: {
        engagement: "58%",
        conversion: "41%",
        retention: "94%"
      }
    },
    {
      id: 6,
      audience: "Marketers",
      title: "Rapid MVP Testing Platform",
      thumbnail: "https://img.youtube.com/vi/dNIjARqt4S0/mqdefault.jpg",
      videoId: "dNIjARqt4S0",
      description: "Rapid market validation for campaign and content optimization",
      keyPoints: [
        "A/B testing automation",
        "Audience insights",
        "Campaign analytics"
      ],
      metrics: {
        engagement: "51%",
        conversion: "46%",
        retention: "90%"
      }
    }
  ];

  // Add a video modal state and handler
  const [videoModal, setVideoModal] = useState({ open: false, videoId: null });

  return (
    <section className="py-16 mb-12">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-4">
          One Product, Multiple Audiences
        </h2>
        <p className="text-zinc-300 max-w-2xl mx-auto">
          See how our AI adapts the same core message for different target audiences,
          maximizing engagement across all segments.
        </p>
      </div>

      {/* Updated Video Showcase with scroll buttons */}
      <div className="relative">
        {/* Left scroll button */}
        <IconButton
          onClick={() => scroll('left')}
          sx={{
            position: 'absolute',
            left: -20,
            top: '50%',
            transform: 'translateY(-50%)',
            bgcolor: 'var(--card-bg)',
            color: 'var(--color-text)',
            zIndex: 2,
            '&:hover': {
              bgcolor: 'var(--card-bg)',
              color: 'var(--color-primary)',
            },
            boxShadow: 2,
            display: { xs: 'none', md: 'flex' },
            transition: 'all 0.2s ease',
          }}
        >
          <ChevronLeft />
        </IconButton>

        {/* Scroll container */}
        <div 
          ref={scrollContainerRef}
          className="overflow-x-auto pb-6 hide-scrollbar mx-6"
        >
          <div className="flex gap-6 min-w-min px-4">
            {videoVariants.map((variant, index) => (
              <Card 
                key={variant.id}
                className="flex-none cursor-pointer"
                sx={{ 
                  bgcolor: 'var(--card-bg)',
                  border: activeVideo === index ? '2px solid var(--color-primary)' : '2px solid transparent',
                  width: { xs: '280px', sm: '300px', md: '280px' },
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                  }
                }}
                onClick={() => {
                  setActiveVideo(index);
                  setVideoModal({ open: true, videoId: variant.videoId });
                }}
              >
                <div className="relative">
                  {/* Thumbnail */}
                  <div className="relative aspect-video bg-zinc-800 overflow-hidden">
                    <img 
                      src={`https://img.youtube.com/vi/${variant.videoId}/maxresdefault.jpg`}
                      alt={variant.title}
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        // Fallback to hqdefault if maxresdefault is not available
                        e.target.src = `https://img.youtube.com/vi/${variant.videoId}/hqdefault.jpg`;
                      }}
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
                      <PlayCircle size={48} className="text-white" />
                    </div>
                  </div>
                  
                  {/* Audience Badge */}
                  <div className="absolute top-2 right-2">
                    <div className="bg-orange-500 text-white text-sm px-3 py-1 rounded-full">
                      {variant.audience}
                    </div>
                  </div>
                </div>

                <CardContent>
                  <h3 className="text-xl font-bold mb-2 line-clamp-2 mt-4 card-title">{variant.title}</h3>
                  <p className="text-zinc-300 text-sm mb-4 line-clamp-2">{variant.description}</p>
                  
                  {/* Metrics */}
                  <div className="grid grid-cols-3 gap-4 mb-4">
                    <Tooltip title="Audience Engagement Rate">
                      <div className="text-center">
                        <div className="text-orange-500 font-bold">{variant.metrics.engagement}</div>
                        <div className="text-xs text-zinc-400">Engagement</div>
                      </div>
                    </Tooltip>
                    <Tooltip title="Conversion Rate">
                      <div className="text-center">
                        <div className="text-orange-500 font-bold">{variant.metrics.conversion}</div>
                        <div className="text-xs text-zinc-400">Conversion</div>
                      </div>
                    </Tooltip>
                    <Tooltip title="Viewer Retention Rate">
                      <div className="text-center">
                        <div className="text-orange-500 font-bold">{variant.metrics.retention}</div>
                        <div className="text-xs text-zinc-400">Retention</div>
                      </div>
                    </Tooltip>
                  </div>

                  {/* Key Points */}
                  <div className="space-y-2">
                    {variant.keyPoints.map((point, i) => (
                      <div key={i} className="flex items-center text-sm text-zinc-300">
                        <div className="w-1.5 h-1.5 rounded-full bg-orange-500 mr-2" />
                        <span className="line-clamp-1">{point}</span>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>

        {/* Right scroll button */}
        <IconButton
          onClick={() => scroll('right')}
          sx={{
            position: 'absolute',
            right: -20,
            top: '50%',
            transform: 'translateY(-50%)',
            bgcolor: 'var(--card-bg)',
            color: 'var(--color-text)',
            zIndex: 2,
            '&:hover': {
              bgcolor: 'var(--card-bg)',
              color: 'var(--color-primary)',
            },
            boxShadow: 2,
            display: { xs: 'none', md: 'flex' },
            transition: 'all 0.2s ease',
          }}
        >
          <ChevronRight />
        </IconButton>
      </div>

      {/* Call to Action */}
      <div className="text-center mt-12">
        <button
          className="bg-orange-500 text-white px-6 py-3 rounded-lg font-semibold inline-flex items-center gap-2 hover:bg-orange-600 transition-colors"
          onClick={() => navigate('/journey')}
        >
          Try Multi-Audience Testing
          <ArrowRight size={20} />
        </button>
      </div>

      {/* Add the video modal component at the bottom of the AudienceShowcase component */}
      <Dialog
        open={videoModal.open}
        onClose={() => setVideoModal({ open: false, videoId: null })}
        maxWidth="md"
        fullWidth
      >
        <DialogContent sx={{ p: 0, bgcolor: 'black' }}>
          {videoModal.videoId && (
            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                src={`https://www.youtube.com/embed/${videoModal.videoId}?autoplay=1`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          )}
        </DialogContent>
        <IconButton
          onClick={() => setVideoModal({ open: false, videoId: null })}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <Close />
        </IconButton>
      </Dialog>
    </section>
  );
};

const StartupPathsComparison = () => {
  const navigate = useNavigate();
  const [selectedPath, setSelectedPath] = useState(null);
  
  const pathData = {
    waterfall: [
      { phase: 'Requirements', duration: 3, description: 'Comprehensive business & technical requirements', cost: '30k' },
      { phase: 'Design', duration: 4, description: 'Complete system architecture & design', cost: '40k' },
      { phase: 'Development', duration: 8, description: 'Full product development cycle', cost: '120k' },
      { phase: 'Testing', duration: 3, description: 'System-wide testing and QA', cost: '30k' },
      { phase: 'Deployment', duration: 2, description: 'Market launch and operations', cost: '30k' }
    ],
    leanStartup: [
      { phase: 'Build MVP', duration: 2, description: 'Minimum viable product development', cost: '20k' },
      { phase: 'Measure', duration: 2, description: 'Key metrics collection and analysis', cost: '15k' },
      { phase: 'Learn', duration: 1, description: 'Customer feedback integration', cost: '10k' },
      { phase: 'Pivot/Persevere', duration: 3, description: 'Strategic direction adjustment', cost: '25k' }
    ],
    agileScrum: [
      { phase: 'Sprint 0', duration: 1, description: 'Initial backlog and planning', cost: '15k' },
      { phase: 'MVP Sprints', duration: 3, description: 'Core feature development', cost: '45k' },
      { phase: 'Market Testing', duration: 2, description: 'User testing and feedback', cost: '20k' },
      { phase: 'Iteration', duration: 3, description: 'Product refinement cycles', cost: '40k' }
    ],
    glowtest: [
      { phase: 'Market Discovery', duration: 0.5, description: 'AI-powered market analysis & segmentation', cost: '2k' },
      { phase: 'Rapid Testing', duration: 0.5, description: 'Multi-variant audience testing', cost: '2k' },
      { phase: 'Data Analysis', duration: 0.5, description: 'ML-driven insight generation', cost: '2k' },
      { phase: 'Validated Build', duration: 2.5, description: 'Market-verified development', cost: '19k' }
    ]
  };

  const pathInfo = {
    waterfall: {
      name: 'Traditional Waterfall',
      color: '#EF4444',
      totalTime: '20 months',
      risk: 'High',
      cost: '$250k+',
      successRate: '15%',
      description: 'Sequential, phase-based development methodology following a strict linear path.',
      pros: [
        'Clear project structure',
        'Detailed documentation',
        'Predictable phases',
        'Suitable for regulated industries'
      ],
      cons: [
        'High upfront costs',
        'Inflexible to changes',
        'Late market feedback',
        'High risk of market misalignment'
      ]
    },
    leanStartup: {
      name: 'Lean Startup',
      color: '#10B981',
      totalTime: '8 months',
      risk: 'Medium',
      cost: '$70k+',
      successRate: '45%',
      description: 'Build-Measure-Learn methodology focused on validated learning through rapid experimentation.',
      pros: [
        'Customer-centric approach',
        'Data-driven decisions',
        'Reduced waste',
        'Faster market learning'
      ],
      cons: [
        'Requires metric expertise',
        'Multiple pivot cycles',
        'Resource intensive testing',
        'Complex feedback analysis'
      ]
    },
    agileScrum: {
      name: 'Agile/Scrum',
      color: '#8B5CF6',
      totalTime: '9 months',
      risk: 'Medium',
      cost: '$120k+',
      successRate: '50%',
      description: 'Iterative development with fixed-length sprints and continuous stakeholder feedback.',
      pros: [
        'Regular deliverables',
        'Flexible to changes',
        'Team collaboration',
        'Continuous improvement'
      ],
      cons: [
        'Resource commitment',
        'Sprint overhead',
        'Team dependency',
        'Scope uncertainty'
      ]
    },
    glowtest: {
      name: 'AI-Driven Validation',
      color: '#F59E0B',
      totalTime: '4 months',
      risk: 'Low',
      cost: '$25k+',
      successRate: '85%',
      description: 'ML-powered market validation and development methodology using predictive analytics.',
      pros: [
        'Data-driven validation',
        'Rapid market insights',
        'Multi-segment testing',
        'Predictive analytics'
      ],
      cons: [
        'New methodology',
        'AI dependency',
        'Requires data literacy',
        'Market skepticism'
      ]
    }
  };

  // Prepare chart data
  const chartData = Object.entries(pathData).map(([key, phases]) => ({
    name: key,
    total: Number(phases.reduce((sum, phase) => sum + phase.duration, 0).toFixed(2)),
    totalCost: phases.reduce((sum, phase) => sum + parseInt(phase.cost.replace('k', ''), 10), 0),
    phases: phases
  }));

  return (
    <section className="py-16 mb-20">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-4">
          Startup Path Comparison
        </h2>
        <p className="text-zinc-300 max-w-2xl mx-auto">
          See how early validation dramatically reduces time to market and risk
        </p>
      </div>

      <div className="relative max-w-full">
        <ResponsiveContainer width="100%" height={200}>
          <RechartsBarChart
            layout="vertical"
            data={chartData}
            margin={{ top: 20, right: 30, left: 120, bottom: 5 }}
            onClick={(data) => {
              if (data && data.activePayload && data.activePayload[0]) {
                setSelectedPath(data.activePayload[0].payload.name);
              }
            }}
          >
            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
            <XAxis 
              type="number" 
              domain={[0, 24]}
              tickCount={7}
            >
              <Label value="Months" offset={-5} position="insideBottom" />
            </XAxis>
            <YAxis 
              dataKey="name" 
              type="category"
              tickFormatter={(value) => {
                const names = {
                  waterfall: 'Traditional Waterfall',
                  leanStartup: 'Lean Startup',
                  agileScrum: 'Agile/Scrum',
                  glowtest: 'AI-Driven Glowtest Validation'
                };
                return names[value];
              }}
            />
            <Tooltip 
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const data = payload[0].payload;
                  return (
                    <div className="bg-zinc-800 p-4 rounded-lg shadow-lg border border-zinc-700" style={{ backgroundColor: '#18181B' }}>
                      <h3 className="font-bold mb-2 text-white">
                        {data.name === 'waterfall' ? 'Traditional Waterfall' :
                         data.name === 'leanStartup' ? 'Lean Startup' :
                         data.name === 'agileScrum' ? 'Agile/Scrum' :
                         'AI-Driven Validation'}
                      </h3>
                      <div className="space-y-1 text-sm text-white">
                        <p>Total Time: {data.total} months</p>
                        <p>Total Cost: ${data.totalCost}k</p>
                        <p className="text-zinc-200 text-xs mt-2">Click for details</p>
                      </div>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Bar 
              dataKey="total"
              animationDuration={1000}
            >
              {chartData.map((entry) => (
                <Cell 
                  key={`cell-${entry.name}`}
                  fill={pathInfo[entry.name].color}
                  opacity={selectedPath === entry.name ? 1 : 0.7}
                />
              ))}
            </Bar>
          </RechartsBarChart>
        </ResponsiveContainer>

        {/* Enhanced Information Section */}
        <div className="mt-12 space-y-8">
          {/* Method Comparison Table */}
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b border-zinc-700">
                  <th className="px-4 py-3 text-left">Method</th>
                  <th className="px-4 py-3 text-center">Time to Market</th>
                  <th className="px-4 py-3 text-center">Initial Cost</th>
                  <th className="px-4 py-3 text-center">Risk Level</th>
                  <th className="px-4 py-3 text-center">Success Rate</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(pathInfo).map(([key, info]) => (
                  <tr 
                    key={key}
                    className={`border-b border-zinc-800 hover:bg-zinc-800/30 transition-colors
                      ${selectedPath === key ? 'bg-zinc-800/50' : ''}`}
                    onClick={() => setSelectedPath(key)}
                  >
                    <td className="px-4 py-3">
                      <div className="flex items-center gap-2">
                        <div 
                          className="w-3 h-3 rounded-full"
                          style={{ backgroundColor: info.color }}
                        />
                        {info.name}
                      </div>
                    </td>
                    <td className="px-4 py-3 text-center">{info.totalTime}</td>
                    <td className="px-4 py-3 text-center">{info.cost}</td>
                    <td className="px-4 py-3 text-center">
                      <span 
                        className={`px-2 py-1 rounded-full text-xs
                          ${key === 'waterfall' ? 'bg-red-500/20 text-red-300' :
                            key === 'leanStartup' ? 'bg-yellow-500/20 text-yellow-300' :
                            key === 'agileScrum' ? 'bg-purple-500/20 text-purple-300' :
                            'bg-green-500/20 text-green-300'}`}
                      >
                        {info.risk}
                      </span>
                    </td>
                    <td className="px-4 py-3 text-center">{info.successRate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Selected Method Details */}
          {selectedPath && (
            <div className="bg-zinc-800/30 rounded-lg p-6 mt-6">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-xl font-bold mb-2" style={{ color: pathInfo[selectedPath].color }}>
                    {pathInfo[selectedPath].name}
                  </h3>
                  <p className="text-zinc-300">{pathInfo[selectedPath].description}</p>
                </div>
                <button 
                  onClick={() => setSelectedPath(null)}
                  className="text-zinc-400 hover:text-white p-2"
                >
                  <Close />
                </button>
              </div>

              <div className="grid md:grid-cols-2 gap-6 mt-4">
                {/* Pros */}
                <div className="space-y-2">
                  <h4 className="font-semibold text-green-400 mb-3">Advantages</h4>
                  {pathInfo[selectedPath].pros.map((pro, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <CheckCircle className="text-green-400" size={16} />
                      <span>{pro}</span>
                    </div>
                  ))}
                </div>

                {/* Cons */}
                <div className="space-y-2">
                  <h4 className="font-semibold text-red-400 mb-3">Considerations</h4>
                  {pathInfo[selectedPath].cons.map((con, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <AlertTriangle className="text-red-400" size={16} />
                      <span>{con}</span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Phase Timeline */}
              <div className="mt-6">
                <h4 className="font-semibold mb-3">Phase Breakdown</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  {pathData[selectedPath].map((phase, index) => (
                    <div 
                      key={index}
                      className="bg-zinc-800/50 p-4 rounded-lg"
                    >
                      <div className="font-medium mb-2">{phase.phase}</div>
                      <div className="text-sm text-zinc-400">Duration: {phase.duration} {phase.duration === 1 ? 'month' : 'months'}</div>
                      <div className="text-sm text-zinc-400">Cost: ${phase.cost}</div>
                      <div className="text-sm mt-2">{phase.description}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Call to Action */}
          <div className="text-center mt-8">
            <button
              className="bg-orange-500 text-white px-6 py-3 rounded-lg font-semibold inline-flex items-center gap-2 hover:bg-orange-600 transition-colors"
              onClick={() => navigate('/journey')}
            >
              Start Your Validation Journey
              <ArrowRight size={20} />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

const ValidationMethodsTable = () => {
  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState('traditional');

  const methods = {
    traditional: {
      name: "Traditional Methods",
      examples: "Surveys, Interviews, MVP",
      timeframe: 120, // in days
      cost: 30000,
      limitations: "Time-consuming, expensive, limited reach",
      color: "#EF4444" // red
    },
    lean: {
      name: "Lean Startup",
      examples: "Build-Measure-Learn",
      timeframe: 60, // in days
      cost: 15000,
      limitations: "Requires multiple iterations, high resource investment",
      color: "#10B981" // green
    },
    sprint: {
      name: "Design Sprint",
      examples: "5-day workshop",
      timeframe: 14, // in days
      cost: 20000,
      limitations: "Team availability, limited market exposure",
      color: "#8B5CF6" // purple
    },
    glowtest: {
      name: "Glowtest",
      examples: "AI-Powered Video Testing",
      timeframe: 2, // in days
      cost: 499,
      limitations: "None",
      color: "#F59E0B" // orange/amber
    }
  };

  const chartData = Object.entries(methods).map(([key, data]) => ({
    name: data.name,
    timeframe: data.timeframe,
    cost: data.cost,
    color: data.color
  }));

  return (
    <section className="py-16 mb-20">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold mb-4">
          The Evolution of Product Validation
        </h2>
        <p className="text-zinc-300 max-w-2xl mx-auto">
          From months of uncertainty to 24-hour validation
        </p>
      </div>

      {/* Method Selection Buttons */}
      <div className="flex justify-center gap-4 mb-8">
        {Object.entries(methods).map(([key, data]) => (
          <button
            key={key}
            onClick={() => setSelectedMethod(key)}
            className={`px-4 py-2 rounded-lg transition-all duration-200 ${
              selectedMethod === key 
                ? 'text-white' 
                : 'text-zinc-400 hover:text-white'
            }`}
            style={{
              backgroundColor: selectedMethod === key ? data.color : 'transparent',
              border: `1px solid ${data.color}`,
            }}
          >
            {data.name}
          </button>
        ))}
      </div>

      {/* Graphs Section */}
      <div className="grid md:grid-cols-2 gap-8 mb-8">
        {/* Timeframe Graph */}
        <div className="bg-zinc-800/30 p-6 rounded-xl">
          <h3 className="text-xl font-bold mb-6">Time to Market</h3>
          <ResponsiveContainer width="100%" height={200}>
            <RechartsBarChart
              layout="vertical"
              data={chartData}
              margin={{ top: 20, right: 30, left: 120, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" />
              <Tooltip 
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="bg-zinc-800 p-3 rounded shadow-lg border border-zinc-700">
                        <p className="font-bold">{payload[0].payload.name}</p>
                        <p>{payload[0].value} days</p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Bar dataKey="timeframe">
                {chartData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`}
                    fill={entry.color}
                    opacity={entry.name === methods[selectedMethod].name ? 1 : 0.5}
                  />
                ))}
              </Bar>
            </RechartsBarChart>
          </ResponsiveContainer>
        </div>

        {/* Cost Graph */}
        <div className="bg-zinc-800/30 p-6 rounded-xl">
          <h3 className="text-xl font-bold mb-6">Typical Cost</h3>
          <ResponsiveContainer width="100%" height={200}>
            <RechartsBarChart
              layout="vertical"
              data={chartData}
              margin={{ top: 20, right: 30, left: 120, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" />
              <Tooltip 
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="bg-zinc-800 p-3 rounded shadow-lg border border-zinc-700">
                        <p className="font-bold">{payload[0].payload.name}</p>
                        <p>${payload[0].value.toLocaleString()}</p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Bar dataKey="cost">
                {chartData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`}
                    fill={entry.color}
                    opacity={entry.name === methods[selectedMethod].name ? 1 : 0.5}
                  />
                ))}
              </Bar>
            </RechartsBarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Selected Method Details */}
      <div className="bg-zinc-800/30 p-6 rounded-xl mb-8">
        <div className="flex items-start gap-4">
          <div>
            <h3 className="text-xl font-bold mb-2" style={{ color: methods[selectedMethod].color }}>
              {methods[selectedMethod].name}
            </h3>
            <p className="text-zinc-300 mb-2">Approach: {methods[selectedMethod].examples}</p>
            <p className="text-zinc-300">Limitations: {methods[selectedMethod].limitations}</p>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="text-center">
        <p className="text-zinc-300 mb-4">
          Why spend months and thousands when you can validate in 24 hours?
        </p>
        <button
          onClick={() => navigate('/journey')}
          className="bg-orange-500 text-white px-6 py-3 rounded-lg font-semibold inline-flex items-center gap-2 hover:bg-orange-600 transition-colors"
        >
          Try 24-Hour Validation
          <ArrowRight size={20} />
        </button>
      </div>
    </section>
  );
};

export default function LandingPage() {
  const navigate = useNavigate();
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme === 'dark' : true;
  });

  const [activeSection, setActiveSection] = React.useState('creators');
  const [tooltip, setTooltip] = React.useState({ show: false, text: '', x: 0, y: 0 });
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    company: '',
    role: '',
    message: '',
    videoType: 'business',
    industry: '',
    currentEngagement: '',
    monthlyContent: ''
  });
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const [predictionResults, setPredictionResults] = React.useState({
    marketResponse: [
      {
        group: "Current State",
        engagement: 18,
        ctr: 3.2,
        intent: 2.1
      },
      {
        group: "With GlowTest",
        engagement: 27,
        ctr: 4.8,
        intent: 3.15
      }
    ]
  });

  const formRef = useRef(null);

  // Market Prediction Data
  const defaultPredictionData = {
    marketResponse: [
      {
        group: "Current State",
        engagement: 20,
        ctr: 15,
        intent: 10
      },
      {
        group: "With GlowTest",
        engagement: 45,
        ctr: 35,
        intent: 30
      }
    ],
    viewDuration: [
      { group: "Current", duration: 120 },
      { group: "Predicted", duration: 240 }
    ]
  };

  const calculatePredictions = (formData) => {
    // In reality, this would be a more complex calculation
    const baselineImprovement = 1.5;
    const industryMultipliers = {
      tech: 1.2,
      retail: 1.1,
      education: 1.3,
      entertainment: 1.4
    };

    const multiplier = industryMultipliers[formData.industry] || 1;
    
    return {
      marketResponse: [
        {
          group: "Current State",
          engagement: parseFloat(formData.currentEngagement) || 20,
          ctr: 15,
          intent: 10
        },
        {
          group: "With GlowTest",
          engagement: (parseFloat(formData.currentEngagement) || 20) * baselineImprovement * multiplier,
          ctr: 15 * baselineImprovement * multiplier,
          intent: 10 * baselineImprovement * multiplier
        }
      ],
      viewDuration: defaultPredictionData.viewDuration
    };
  };

  const handlePredictionSubmit = (e) => {
    e.preventDefault();
    const results = calculatePredictions(formData);
    setPredictionResults(results);
  };

  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleMouseMove = useCallback((e) => {
    if (tooltip.show) {
      requestAnimationFrame(() => {
        setTooltip(prev => ({
          ...prev,
          x: e.clientX + 15,
          y: e.clientY + 15
        }));
      });
    }
  }, [tooltip.show]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here
    const requestData = {
      name: formData.name,
      business_email: formData.email,
      company: formData.company,
      role: formData.role,
      video_content_type: formData.videoType,
      tell_us_about_your_video_testing_needs: formData.message,
      monthly_content_volume: formData.monthlyContent
    };

    console.log('Form submitted:', requestData);
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/user/request_video_testing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.status_code === 200) {
        setFormSubmitted(true);
      }
      
      // Handle success (e.g., show a success message or redirect)
    } catch (error) {
      console.error('Error:', error);
      // Handle error (e.g., show an error message)
    }
  };

  const comparisons = useMemo(() => [
    {
      waterfall: '4-6 weeks for campaign testing',
      glowtest: '24 hours for full market validation',
      icon: Clock 
    },
    {
      waterfall: '$20,000+ agency retainer',
      glowtest: 'Starting at $499/month',
      icon: DollarSign
    },
    {
      waterfall: 'Limited testing rounds',
      glowtest: 'Unlimited A/B testing',
      icon: BarChart
    }
  ], []);

  const stats = useMemo(() => [
    { value: '90%', label: 'Success Rate Improvement' },
    { value: '24h', label: 'Average Validation Time' },
    { value: '10k+', label: 'Ideas Validated' },
    { value: '50k+', label: 'Active Creators' }
  ], []);

  const features = useMemo(() => [
    {
      title: 'Market Size Validation',
      icon: Brain,
      items: [
        'TAM/SAM/SOM Analysis',
        'Market Opportunity Scoring',
        'Growth Potential Assessment',
        'Risk Analysis'
      ]
    },
    {
      title: 'Customer Insights',
      icon: Users,
      items: [
        'Segment Analysis',
        'Behavior Patterns',
        'Need Assessment',
        'Validation Feedback'
      ]
    },
    {
      title: 'Competitive Edge',
      icon: BarChart,
      items: [
        'SWOT Analysis',
        'Market Positioning',
        'Competitor Tracking',
        'Unique Value Proposition'
      ]
    }
  ], []);

  const consultationProcess = useMemo(() => [
    {
      icon: Phone,
      title: "Free Consultation",
      description: "30-minute discovery call to understand your content validation needs"
    },
    {
      icon: CheckCircle,
      title: "Custom Solution",
      description: "Tailored testing strategy across multiple content types"
    },
    {
      icon: Building,
      title: "Enterprise Support",
      description: "Dedicated team for seamless implementation and scaling"
    }
  ], []);

  const enterpriseTestimonials = useMemo(() => [
    {
      icon: StorageIcon,
      company: "TechCorp",
      quote: "GlowTest helped us validate 50+ educational videos in just one week",
      person: "Sarah Chen, Head of Learning"
    },
    {
      icon: CorporateFareIcon,
      company: "Enterprise Solutions Inc",
      quote: "90% cost reduction in technical documentation testing",
      person: "Michael Roberts, CTO"
    }
  ], []);

  const renderButton = useCallback(({ text, icon, primary = true, className, onClick }) => (
    <button 
      className={`${className} px-6 py-3 rounded-lg font-semibold flex items-center gap-2 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500`}
      style={{ 
        backgroundColor: primary ? theme.palette.primary.main : 'transparent',
        borderColor: primary ? 'transparent' : theme.palette.primary.main,
        borderWidth: primary ? 0 : '1px'
      }}
      onMouseOver={(e) => {
        e.currentTarget.style[primary ? 'backgroundColor' : 'borderColor'] = theme.palette.secondary.main;
      }}
      onMouseOut={(e) => {
        e.currentTarget.style[primary ? 'backgroundColor' : 'borderColor'] = primary ? theme.palette.primary.main : theme.palette.secondary.main;
      }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      aria-label={text}
    >
      {text}
      {icon}
    </button>
  ), [theme]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-zinc-800 p-3 rounded shadow-lg border border-zinc-700">
          <p className="font-semibold">{label}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {entry.name}: {entry.value}%
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderSegmentContent = useCallback(({ type }) => {
    const content = {
      creators: {
        title: 'For Video Content Creators',
        subtitle: 'Validate your video content ideas before investing in full production',
        items: [
          { Icon: Video, text: 'Test video concepts and ideas' },
          { Icon: Brain, text: 'Get feedback on audience engagement' },
          { Icon: Clock, text: 'Validate concepts in 24 hours' },
          { Icon: BarChart, text: 'Detailed audience insights and recommendations' }
        ],
        image: '/images/cam_Floating__Ethereal_ultra_realistic_illustration_o_d3c7e23b-6c12-4f13-88ee-4c702cc0987a_1.jpg'
      },
      businesses: {
        title: 'For Marketing & Product Teams',
        subtitle: 'Optimize your video marketing with data-driven insights',
        items: [
          { Icon: TrendingUp, text: 'Validate product video demos' },
          { Icon: DollarSign, text: 'Save up to 90% on video testing' },
          { Icon: Users, text: 'Access enterprise testing panels' },
          { Icon: Rocket, text: 'Launch video campaigns with confidence' }
        ],
        image: '/images/colour_athmo_Bold__Graphic_ultra_realistic_illustration_of_urb_c0148eae-80b6-47c9-bfac-e464523f7b57_1.jpg'
      },
      technical: {
        title: 'For Technical Documentation',
        subtitle: 'Ensure your technical video content resonates with your audience',
        items: [
          { Icon: Shield, text: 'Test technical video explanations' },
          { Icon: Brain, text: 'Validate complex concepts' },
          { Icon: Stars, text: 'Get expert technical feedback' },
          { Icon: BarChart, text: 'Measure understanding and clarity' }
        ],
        image: '/images/editing_Classical__Narrative_ultra_realistic_illustration_e575644d-cdec-45d0-b164-ade572ea9446_1.jpg'
      },
      entertainment: {
        title: 'For Entertainment Content',
        subtitle: 'Test your video entertainment content with target audiences',
        items: [
          { Icon: Video, text: 'Validate video concepts' },
          { Icon: Users, text: 'Test audience engagement' },
          { Icon: Stars, text: 'Get feedback on appeal' },
          { Icon: TrendingUp, text: 'Optimize viewer retention' }
        ],
        image: '/images/visu_Wide__Epic_ultra_realistic_illustration_of_anamor_3e13f037-d201-43e7-8dbf-e6ccb425cc1c_0.jpg'
      }
    };

    return content[type] ? (
      <div className="grid md:grid-cols-2 gap-8">
        <div>
          <h3 className="text-2xl font-bold mb-2">{content[type].title}</h3>
          <p className="text-zinc-400 mb-6">{content[type].subtitle}</p>
          <ul className="space-y-4">
            {content[type].items.map(({ Icon, text }, index) => (
              <li key={index} className="flex items-start">
                <Icon className="mr-2 mt-1 flex-shrink-0" size={20} style={{ color: theme.palette.primary.main }} />
                <span>{text}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="bg-zinc-800/30 rounded-lg p-6">
          <img 
            src={content[type].image} 
            alt={`${content[type].title} Dashboard`}
            className="rounded-lg w-full"
            loading="lazy"
          />
        </div>
      </div>
    ) : null;
  }, []);

  // Define auditSteps here
  const auditSteps = useMemo(() => [
    { number: 1, title: "Current State", description: "Analyze your existing content performance" },
    { number: 2, title: "Gap Analysis", description: "Identify areas for improvement" },
    { number: 3, title: "Market Prediction", description: "Project potential outcomes" }
  ], []);

  // Data for the iteration cycle
  const iterationData = [
    { stage: 'Concept', waterfall: 7, glowtest: 2 },
    { stage: 'Production', waterfall: 14, glowtest: 5 },
    { stage: 'Validation', waterfall: 10, glowtest: 3 },
    { stage: 'Feedback', waterfall: 5, glowtest: 1 }
  ];

  // Tooltip function
  const IterationCycleTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-zinc-800 p-3 rounded shadow-lg border border-zinc-700">
          <p className="font-semibold">{label}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {entry.name}: {entry.value} days
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  // Add new section for validation journey
  const validationSteps = useMemo(() => [
    {
      title: "Define & Align",
      description: "Clarify your concept and target market with AI assistance",
      icon: Target,
      detail: "Use our AI to sharpen your value proposition"
    },
    {
      title: "Craft & Glow",
      description: "Create compelling validation materials",
      icon: Wand,
      detail: "Generate professional testing materials in minutes"
    },
    {
      title: "Activate the Market",
      description: "Test with real target users",
      icon: Users,
      detail: "Access our pool of verified testers"
    },
    {
      title: "Gather & Analyze",
      description: "Collect and interpret market feedback",
      icon: BarChart,
      detail: "AI-powered analysis of user responses"
    },
    {
      title: "Decide & Move",
      description: "Make data-driven decisions",
      icon: ArrowRight,
      detail: "Clear action steps based on validation results"
    }
  ], []);

  // Update hero section content
  const heroContent = {
    mainTitle: "Stop Wasting Investors' Money",
    subTitle: "9/10 startups fail. Don't be one of them. GlowTest prevents 76% of common startup failures through AI-powered validation.",
    primaryCta: "Try It Free - No Sign Up",
    secondaryCta: "Explore Validation Path"
  };

  // Add failure statistics for Fear & Solution section
  const failureStats = useMemo(() => [
    {
      stat: "90%",
      label: "Startup Failure Rate",
      impact: "$1.3M average loss",
      solution: "Early market validation reduces failure risk by 68%",
      icon: AlertTriangle
    },
    {
      stat: "$2.1M",
      label: "Average Investment Lost",
      impact: "18 months of work wasted",
      solution: "Save 71% on development costs with pre-validation",
      icon: DollarSign
    },
    {
      stat: "76%",
      label: "Preventable Failures",
      impact: "Due to poor market fit",
      solution: "GlowTest identifies market fit issues pre-launch",
      icon: Target
    }
  ], []);

  // Add Exit Intent Modal
  const [showExitIntent, setShowExitIntent] = useState(false);

  const handleExitIntent = () => {
    // Handle exit intent logic here
    console.log('Exit intent handled');
    setShowExitIntent(false);
  };

  // Add Progress Bar
  const [scrollProgress, setScrollProgress] = useState(0);

  const toggleTheme = () => {
    const newTheme = !isDarkTheme;
    setIsDarkTheme(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
    document.body.className = newTheme ? 'theme-dark' : 'theme-light';
  };

  return (
    <div className={`min-h-screen bg-zinc-900 text-white ${isDarkTheme ? 'dark' : 'light'}`}>
      <div className="fixed top-4 right-4 z-50">
        <IconButton 
          onClick={toggleTheme} 
          sx={{ 
            color: isDarkTheme ? '#FFFFFF' : '#1C1C1E',
            bgcolor: isDarkTheme ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
            '&:hover': {
              bgcolor: isDarkTheme ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
              color: 'var(--color-primary)'
            }
          }}
        >
          <ContrastIcon />
        </IconButton>
      </div>

      <main className="container mx-auto px-4 py-20">
        {/* Hero Section - Updated messaging */}
        <section className="text-center mb-16" aria-label="Hero">
          <div className="mb-6 flex justify-center">
            <picture>
              <source 
                media="(min-width: 768px)" 
                srcSet="/images/logo-light-desktop.png" 
              />
              <source 
                media="(max-width: 767px)" 
                srcSet="/images/logo-light-mobile.png" 
              />
              <img
                src="/images/logo-light-desktop.png"
                alt="GlowTest Logo"
                className="h-24 w-auto"  // Changed from h-16 to h-24 (50% larger)
                style={{
                  filter: 'drop-shadow(0 0 10px rgba(253, 152, 0, 0.3))'  // Optional: adds a subtle glow effect
                }}
              />
            </picture>
          </div>
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            {heroContent.mainTitle}
          </h1>
          <p className="text-xl text-zinc-300 mb-8 max-w-3xl mx-auto">
            {heroContent.subTitle}
          </p>
          <div className="flex justify-center gap-4">
            {renderButton({ 
              text: 'Try It Free - No Sign Up', 
              icon: <ArrowRight size={20} />, 
              onClick: () => navigate('/journey'),  // Changed to navigate to journey
              className: 'px-8 py-4 text-lg' 
            })}
            {renderButton({ 
              text: 'See How It Works', 
              icon: <ChartBar size={20} />, 
              primary: false,
              onClick: () => navigate('/journey'),
              className: 'px-8 py-4 text-lg'
            })}
          </div>
        </section>

        {/* Add AudienceShowcase here, after hero but before other sections */}
        <AudienceShowcase />

        {/* Market Analysis Section - Only one header here */}
        <section className="py-16">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">
              Market Size Analysis
            </h2>
            <p className="text-zinc-300 max-w-2xl mx-auto">
              See how our AI analyzes total addressable market (TAM), serviceable addressable market (SAM), and serviceable obtainable market (SOM).
            </p>
          </div>

          <div className="max-w-6xl mx-auto px-4">
            <TamSamSomViewer 
              data={null}
              isLandingPage={true}
              hideHeader={true}  // Add this to hide the component's internal header
            />
            <div className="text-center mt-12">
              <button
                onClick={() => navigate('/journey')}
                className="bg-orange-500 text-white px-6 py-3 rounded-lg font-semibold inline-flex items-center gap-2 hover:bg-orange-600 transition-colors"
              >
                Try Market Analysis
                <ArrowRight size={20} />
              </button>
            </div>
          </div>
        </section>

        {/* Validation Tools Section */}
        <section className="py-16 bg-zinc-800/30">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">
              Everything You Need to Validate Your Business
            </h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-6 max-w-6xl mx-auto px-4">
            {/* Card 1 */}
            <div className="bg-zinc-800/30 p-6 rounded-xl">
              <div className="flex justify-center mb-4">
                <PlayCircleIcon sx={{ fontSize: 32, color: 'orange' }} />
              </div>
              <h3 className="text-xl font-bold mb-2">AI Video Pretotyping</h3>
              <p className="text-zinc-300 mb-2">Generate realistic product demonstrations and test them directly on major platforms</p>
              <p className="text-orange-500 text-sm">Generate professional testing materials in minutes</p>
            </div>

            {/* Card 2 */}
            <div className="bg-zinc-800/30 p-6 rounded-xl">
              <div className="flex justify-center mb-4">
                <BarChartIcon sx={{ fontSize: 32, color: 'orange' }} />
              </div>
              <h3 className="text-xl font-bold mb-2">Market Intelligence</h3>
              <p className="text-zinc-300 mb-2">Comprehensive TAM, SAM, and SOM analysis with competitive landscape mapping</p>
              <p className="text-orange-500 text-sm">AI-powered analysis of your target market</p>
            </div>

            {/* Card 3 */}
            <div className="bg-zinc-800/30 p-6 rounded-xl">
              <div className="flex justify-center mb-4">
                <GpsFixedIcon sx={{ fontSize: 32, color: 'orange' }} />
              </div>
              <h3 className="text-xl font-bold mb-2">Unit Economics</h3>
              <p className="text-zinc-300 mb-2">Track CAC, LTV, and optimize your pricing strategy with real data</p>
              <p className="text-orange-500 text-sm">Use our AI to sharpen your business model</p>
            </div>

            {/* Card 4 */}
            <div className="bg-zinc-800/30 p-6 rounded-xl">
              <div className="flex justify-center mb-4">
                <PieChartIcon sx={{ fontSize: 32, color: 'orange' }} />
              </div>
              <h3 className="text-xl font-bold mb-2">Financial Validation</h3>
              <p className="text-zinc-300 mb-2">Detailed cost analysis and resource allocation optimization</p>
              <p className="text-orange-500 text-sm">Clear financial planning based on analytics & data</p>
            </div>
          </div>
        </section>

        {/* Stats Section */}
        <section className="stats">
          {/* 90% Startup Failure Rate content */}
        </section>

        {/* Startup Path Comparison */}
        <StartupPathsComparison />

        {/* Updated Validation Journey */}
        <section className="mb-20">
          <h2 className="text-3xl font-bold mb-8 text-center">Your Path to Validated Success</h2>
          <div className="grid md:grid-cols-5 gap-6">
            {validationSteps.map((step, index) => {
              const Icon = step.icon;
              return (
                <div key={index} className="bg-zinc-800/30 p-6 rounded-xl">
                  <Icon className="w-12 h-12 mb-4 text-orange-500" />
                  <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                  <p className="text-zinc-400 mb-4">{step.description}</p>
                  <p className="text-sm text-orange-300">{step.detail}</p>
                </div>
              );
            })}
          </div>
        </section>
        {/* Add the new validation methods comparison table */}
        <ValidationMethodsTable />
        {/* New USP Section */}
        <section className="mb-20 grid md:grid-cols-3 gap-8">
          <div className="bg-zinc-800/30 p-6 rounded-xl text-center">
            <Brain className="w-12 h-12 mx-auto mb-4 text-primary" />
            <h3 className="text-xl font-bold mb-2">AI-Powered Validation</h3>
            <p className="text-zinc-400">Get instant market insights and validation powered by advanced AI analysis</p>
          </div>
          <div className="bg-zinc-800/30 p-6 rounded-xl text-center">
            <Users className="w-12 h-12 mx-auto mb-4 text-primary" />
            <h3 className="text-xl font-bold mb-2">Real User Feedback</h3>
            <p className="text-zinc-400">Test your ideas with real target users before investing resources</p>
          </div>
          <div className="bg-zinc-800/30 p-6 rounded-xl text-center">
            <Rocket className="w-12 h-12 mx-auto mb-4 text-primary" />
            <h3 className="text-xl font-bold mb-2">Launch with Confidence</h3>
            <p className="text-zinc-400">Make data-driven decisions and optimize your go-to-market strategy</p>
          </div>
        </section>

        {/* Add after Hero Section */}
        <section className="mb-20" aria-label="Enterprise Testimonials">
          <h2 className="text-3xl font-bold mb-8 text-center">Trusted by Industry Leaders</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {enterpriseTestimonials.map((testimonial, index) => {
              const IconComponent = testimonial.icon;
              return (
                <div key={index} className="bg-zinc-900/30 p-6 rounded-xl">
                  <p className="text-lg mb-4 text-zinc-300">"{testimonial.quote}"</p>
                  <div className="flex items-end">
                    <IconComponent 
                      sx={{ 
                        fontSize: 32, 
                        color: theme.palette.primary.main,
                        opacity: 0.8,
                        marginRight: 2
                      }} 
                    />
                    <div className="space-y-1">
                      <p className="font-semibold">{testimonial.company}</p>
                      <p className="text-sm text-zinc-400">{testimonial.person}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>

        {/* Agency Comparison - Updated for individual creators */}
        <section className="mb-20 text-center" aria-label="Agency Comparison">
          <h2 className="text-3xl font-bold mb-8">Why Companies Choose GlowTest Over Agencies</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {comparisons.map(({ waterfall, glowtest, icon: Icon }, index) => (
              <div key={index} className="bg-zinc-900/50 p-6 rounded-xl">
                <Icon size={24} className="mx-auto mb-4" style={{ color: theme.palette.primary.main }} />
                <div className="text-zinc-400 line-through mb-2">{waterfall}</div>
                <div className="text-lg font-semibold" style={{ color: theme.palette.primary.main }}>{glowtest}</div>
              </div>
            ))}
          </div>
        </section>

        {/* User Segments - Updated for B2C */}
        <section className="mb-20" aria-label="User Segments">
          <div className="flex lg:gap-8 lg:justify-center justify-evenly mb-8">
            {['creators', 'businesses', 'technical', 'entertainment'].map((section) => (
              <button
                key={section}
                onClick={() => setActiveSection(section)}
                className={`px-4 py-2 rounded-lg transition-all duration-200 ${
                  activeSection === section ? 'bg-primary text-white' : 'bg-transparent text-zinc-400'
                }`}
                style={{ 
                  backgroundColor: activeSection === section ? theme.palette.primary.main : 'transparent'
                }}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
                </button>
            ))}
          </div>

          <div className="bg-zinc-900/30 p-8 rounded-xl">
            {renderSegmentContent({ type: activeSection })}
          </div>
        </section>

        {/* Add before Features Section */}
        <section className="text-center bg-zinc-900/30 p-12 rounded-xl mb-20">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Content Testing?</h2>
          <p className="text-zinc-300 mb-4 mx-auto">
            Join thousands of creators who are using GlowTest to validate their ideas and launch successful products.
          </p>
          <div className="flex justify-center gap-4">
            {renderButton({ 
              text: 'Start Validating Now - Free',
              icon: <ArrowRight size={20} />,
              onClick: () => navigate('/journey'),  // Changed to navigate to journey
              className: 'px-8 py-4 text-lg'
            })}
            {renderButton({ 
              text: 'See How It Works',
              icon: <ChartBar size={20} />,
              primary: false,
              onClick: () => navigate('/journey'),
              className: 'px-8 py-4 text-lg'
            })}
          </div>
        </section>

        {/* Add Quick Benefits */}
        <section className="text-center mb-16">
          <div className="flex justify-center gap-8">
            {[
              { icon: Clock, text: "No Setup required" },
              { icon: Shield, text: "No Credit Card" },
              { icon: Users, text: "50+ User Feedback" }
            ].map((benefit, index) => {
              const Icon = benefit.icon;
              return (
                <div key={index} className="flex items-center gap-2">
                  <Icon className="w-5 h-5 text-orange-500" />
                  <span className="text-zinc-300">{benefit.text}</span>
                </div>
              );
            })}
          </div>
        </section>

        {/* Features Section - Updated with new modules */}
        <section className="grid md:grid-cols-3 gap-8 mb-20" aria-label="Features">
          {features.map(({ title, icon: Icon, items }, index) => (
            <div key={index} className="bg-zinc-900/30 p-6 rounded-xl">
              <div className="flex items-center gap-2 mb-4">
                <Icon size={24} style={{ color: theme.palette.primary.main }} />
                <h3 className="text-xl font-bold">{title}</h3>
              </div>
              <ul className="space-y-2 text-zinc-300">
                {items.map((item, itemIndex) => (
                  <li key={itemIndex} className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </section>

        {/* Final CTA - Updated for B2C */}
        <section className="text-center bg-zinc-900/30 p-12 rounded-xl mb-20" aria-label="Call to Action">
          <h2 className="text-3xl font-bold mb-4">Ready to Validate Your Idea?</h2>
          <p className="text-zinc-300 mb-8 mx-auto">
            Join thousands of creators who have already validated their ideas and launched successful products with GlowTest.
          </p>
          {renderButton({ 
            text: 'Start Free Validation',
            icon: <ArrowRight size={20} />,
            onClick: () => navigate('/journey'),  // Changed to navigate to journey
            className: 'px-8 py-4 text-lg mx-auto'
          })}
        </section>

        {/* Form Section - Updated for founders/startups */}
        <section ref={formRef} className="max-w-2xl mx-auto bg-zinc-900/30 p-8 rounded-xl mb-20">
          {formSubmitted && (<CheckCircle size={32} className="mx-auto mb-6" style={{ color: theme.palette.primary.main }} />)}
          <h2 className="text-3xl font-bold mb-6 text-center">
            Get in touch with us
          </h2>
          {formSubmitted ? (
            <p className="text-zinc-400 text-center">Your request has been successfully sent. Thank you for reaching out!</p>
          ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-zinc-400 mb-2">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={(e) => setFormData({...formData, name: e.target.value})}
                  className="w-full px-4 py-2 bg-zinc-800 border border-zinc-700 rounded-lg focus:outline-none focus:border-orange-500"
                  required
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-zinc-400 mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="business_email"
                  value={formData.email}
                  onChange={(e) => setFormData({...formData, email: e.target.value})}
                  className="w-full px-4 py-2 bg-zinc-800 border border-zinc-700 rounded-lg focus:outline-none focus:border-orange-500"
                  required
                />
              </div>
            </div>

            <div>
              <label htmlFor="founderType" className="block text-sm font-medium text-zinc-400 mb-2">
                What best describes you?
              </label>
              <select
                id="founderType"
                value={formData.founderType}
                onChange={(e) => setFormData({...formData, founderType: e.target.value})}
                className="w-full px-4 py-2 bg-zinc-800 border border-zinc-700 rounded-lg focus:outline-none focus:border-orange-500"
                required
              >
                <option value="">Select your profile</option>
                <option value="first-time">First-time Founder</option>
                <option value="serial">Serial Entrepreneur</option>
                <option value="technical">Technical Founder</option>
                <option value="non-technical">Non-technical Founder</option>
              </select>
            </div>

            <div>
              <label htmlFor="stage" className="block text-sm font-medium text-zinc-400 mb-2">
                What stage is your startup at?
              </label>
              <select
                id="stage"
                value={formData.stage}
                onChange={(e) => setFormData({...formData, stage: e.target.value})}
                className="w-full px-4 py-2 bg-zinc-800 border border-zinc-700 rounded-lg focus:outline-none focus:border-orange-500"
                required
              >
                <option value="">Select stage</option>
                <option value="ideation">Just an idea</option>
                <option value="mvp">Building MVP</option>
                <option value="launched">Already launched</option>
                <option value="scaling">Ready to scale</option>
              </select>
            </div>

            <div>
              <label htmlFor="investment" className="block text-sm font-medium text-zinc-400 mb-2">
                Planned investment in next 6 months
              </label>
              <select
                id="investment"
                value={formData.investment}
                onChange={(e) => setFormData({...formData, investment: e.target.value})}
                className="w-full px-4 py-2 bg-zinc-800 border border-zinc-700 rounded-lg focus:outline-none focus:border-orange-500"
                required
              >
                <option value="">Select range</option>
                <option value="<50k">Less than $50K</option>
                <option value="50k-200k">$50K - $200K</option>
                <option value="200k-1m">$200K - $1M</option>
                <option value=">1m">More than $1M</option>
              </select>
            </div>

            <div>
              <label htmlFor="idea" className="block text-sm font-medium text-zinc-400 mb-2">
                Briefly describe your idea
              </label>
              <textarea
                id="idea"
                value={formData.idea}
                onChange={(e) => setFormData({...formData, idea: e.target.value})}
                rows={4}
                className="w-full px-4 py-2 bg-zinc-800 border border-zinc-700 rounded-lg focus:outline-none focus:border-orange-500"
                required
                placeholder="What problem are you solving? Who is your target market?"
              />
            </div>

            <div className="flex justify-center">
              {renderButton({ 
                text: 'Send it out',
                icon: <ArrowRight size={20} />,
                onClick: handleSubmit,
                className: 'w-auto'
              })}
            </div>
          </form>
        )}
        </section>

        {/* Final CTA Section */}
        <section className="text-center bg-zinc-900/30 p-12 rounded-xl mb-20">
          <h2 className="text-3xl font-bold mb-4">Ready to De-Risk Your Startup?</h2>
          <p className="text-zinc-300 mb-4 max-w-2xl mx-auto">
            Join successful founders who saved an average of $380,000 in development costs 
            and reduced time-to-market by 60% using GlowTest's validation platform.
          </p>
          <div className="flex justify-center gap-4">
            {renderButton({ 
              text: 'Try It Free - No Sign Up',
              icon: <ArrowRight size={20} />,
              onClick: () => navigate('/journey'),  // Changed to navigate to journey
              className: 'px-8 py-4 text-lg'
            })}
            {renderButton({ 
              text: 'See How It Works',
              icon: <ChartBar size={20} />,
              primary: false,
              onClick: () => navigate('/journey'),
              className: 'px-8 py-4 text-lg'
            })}
          </div>
        </section>

        {/* FAQ Section */}
        <section className="mb-20 max-w-3xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-center">Common Questions</h2>
          <div className="space-y-4">
            <CollapsibleSection title="How long does validation typically take?">
              <p className="text-zinc-300">
                Most founders complete their initial validation within 1-2 weeks. Our AI-powered platform 
                accelerates the process by automating market research and user feedback collection.
              </p>
            </CollapsibleSection>
            
            <CollapsibleSection title="What do I get when I start?">
              <p className="text-zinc-300">
                You get instant access to:
              </p>
              <ul className="list-disc pl-5 mt-2 space-y-2 text-zinc-400">
                <li>Full market size analysis</li>
                <li>Competitor landscape mapping</li>
                <li>Initial user feedback from 50 target users</li>
                <li>Basic validation report</li>
              </ul>
            </CollapsibleSection>

            <CollapsibleSection title="How accurate is the AI validation?">
              <p className="text-zinc-300">
                Our AI combines data from multiple trusted sources and has been validated against 
                real-world startup outcomes with 92% accuracy. However, we always pair AI insights 
                with real user feedback for comprehensive validation.
              </p>
            </CollapsibleSection>
          </div>
        </section>

        {/* Add Social Proof Section */}
        {/* Temporarily disabled Social Proof Section 
        <section className="mb-20 text-center">
          <h2 className="text-3xl font-bold mb-8">Trusted By Founders Like You</h2>
          <div className="flex justify-center gap-8 mb-8">
            <img src="/logos/techcrunch.svg" alt="TechCrunch" className="h-8" />
            <img src="/logos/forbes.svg" alt="Forbes" className="h-8" />
            <img src="/logos/producthunt.svg" alt="ProductHunt" className="h-8" />
          </div>
          <div className="grid md:grid-cols-3 gap-6">
            {[
              { rating: 4.9, platform: "ProductHunt" },
              { rating: 4.8, platform: "G2" },
              { rating: 4.9, platform: "Capterra" }
            ].map((review, index) => (
              <div key={index} className="bg-zinc-800/30 p-4 rounded-xl">
                <div className="text-2xl font-bold text-orange-500">{review.rating}/5.0</div>
                <div className="text-zinc-400">{review.platform}</div>
              </div>
            ))}
          </div>
        </section>
        */}

        {/* Add Urgency Banner */}
        <div className="fixed bottom-0 left-0 right-0 bg-orange-500 p-2 text-center text-white">
          <p className="text-sm">
            🔥 <span className="font-bold">Limited Time:</span> Get 50 extra user feedback responses 
            when you validate today! <span className="underline cursor-pointer" onClick={scrollToForm}>
            Start Now</span>
          </p>
        </div>

        {/* Add Exit Intent Modal */}
        {showExitIntent && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-zinc-900 p-8 rounded-xl max-w-md">
              <h3 className="text-2xl font-bold mb-4">Wait! Don't Miss Out</h3>
              <p className="text-zinc-300 mb-4">
                Get our exclusive "Top 10 Startup Validation Checklist" for free!
              </p>
              <input 
                type="email" 
                placeholder="Enter your email"
                className="w-full px-4 py-2 bg-zinc-800 border border-zinc-700 rounded-lg mb-4" 
              />
              {renderButton({ 
                text: 'Get Free Checklist',
                onClick: handleExitIntent
              })}
            </div>
          </div>
        )}

        {/* Add Progress Bar */}
        <div className="fixed top-0 left-0 right-0 h-1 bg-zinc-800">
          <div 
            className="h-full bg-orange-500 transition-all duration-200"
            style={{ width: `${scrollProgress}%` }}
          />
        </div>

        {/* Add Glow30 Section */}
        <section className="mb-20">
          <Glow30Processor 
            data={{}} 
            onContinue={() => navigate('/journey')}
            isDarkTheme={isDarkTheme}
          />
        </section>

        {/* New Risk Section */}
        <section className="mb-20">
          <Box sx={{ 
            textAlign: 'center', 
            mb: 4,
            mt: 2
          }}>
            <Typography variant="h4" sx={{ 
              fontWeight: 700,
              color: 'error.main',
              mb: 2
            }}>
              Stop Wasting Shareholders Money
            </Typography>
            <Typography variant="h6" sx={{
              color: 'text.secondary',
              mb: 3,
              fontWeight: 500
            }}>
              Don't Launch Blind - Validate First
            </Typography>
            
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-around',
              mb: 4,
              gap: 2,
              flexDirection: { xs: 'column', md: 'row' }
            }}>
              {/* Failure Stats Card 1 */}
              <Box sx={{ 
                textAlign: 'center',
                p: 2,
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 1,
                flex: 1
              }}>
                <FaExclamationTriangle size={24} color="#FF0000" />
                <Typography variant="h4" sx={{ color: 'error.main', mt: 1 }}>92%</Typography>
                <Typography variant="body2">Startups Fail Due to Poor Market Analysis</Typography>
              </Box>

              {/* Failure Stats Card 2 */}
              <Box sx={{ 
                textAlign: 'center',
                p: 2,
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 1,
                flex: 1
              }}>
                <FaExclamationTriangle size={24} color="#FF0000" />
                <Typography variant="h4" sx={{ color: 'error.main', mt: 1 }}>73%</Typography>
                <Typography variant="body2">Waste Money on Wrong Market Approach</Typography>
              </Box>

              {/* Risk Card */}
              <Box sx={{ 
                textAlign: 'center',
                p: 2,
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 1,
                flex: 1,
                borderLeft: 4,
                borderColor: 'error.main'
              }}>
                <Typography variant="h6" sx={{ color: 'error.main', mb: 2 }}>
                  Don't Risk Your Time and Money
                </Typography>
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  alignItems: 'flex-start'
                }}>
                  {[
                    'Wasting months building the wrong product',
                    'Burning through savings',
                    'Missing market opportunities',
                    'Losing to competitors'
                  ].map((point, index) => (
                    <Box key={index} sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 1
                    }}>
                      <FaExclamationTriangle size={16} color="#FF0000" />
                      <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
                        {point}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </section>
      </main>
    </div>
  );
} 