import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import {
  FaRegTrashAlt ,
} from 'react-icons/fa';
import CloseIcon from '@mui/icons-material/Close';

export function CompanyList({ companies, onEdit, onDelete }) { 
  
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const ConfirmDeleteDialog = () => (
    <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this company?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onDelete(companyToDelete);
            setDeleteDialogOpen(false);
          }}
          color="secondary"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            {/* <TableCell>Actions</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {companies.map((company) => (
            <TableRow
              key={company.company_id}
              sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
            >
              <TableCell
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(company);
                }}
                sx={{ cursor: 'pointer' }}
              >
                {company.company_name}
              </TableCell>
              {/* <TableCell>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setCompanyToDelete(company.company_id);
                    setDeleteDialogOpen(true);
                  }}
                  color="error"
                >
                  <FaRegTrashAlt />
                </IconButton>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmDeleteDialog />
    </TableContainer>
  );
}

export default CompanyList;