import { useState, useEffect } from 'react';
import { Typography, Box, IconButton, Button } from '@mui/material';
import CompanyList from './list';
import CompanyForm from './form';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCompanyList, createCompany, updateCompany, selectAllCompanies, selectCompaniesStatus} from './companySlide';

export default function Companies() {
  const [editingCompany, setEditingCompany] = useState(null);
  const dispatch = useDispatch()  
  const companyStatus = useSelector(selectCompaniesStatus)
  const companies = useSelector(selectAllCompanies)

  useEffect(() => {
    if (companyStatus === 'idle') {
      dispatch(fetchCompanyList())
    }    
  }, [companyStatus])

  const addNewCompany = (companyName) => {
    if (companyName.trim()) {
      dispatch(createCompany({company_name: companyName}))
    }
  };

  const updateCompanyInfo = (updatedData) => {
    if (updatedData.company_id.trim()) {
      dispatch(updateCompany(updatedData));
    }
  };

  const deleteCompany = (companyId) => {
  };

  return (
    <>
    <Box sx={{ p: 3, bgcolor: 'background.default', }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5" component="h5" gutterBottom>
            Company Management
        </Typography>
        </Box>
        <Box mb={4}>
        <CompanyForm
            onSubmit={editingCompany ? updateCompanyInfo : addNewCompany}
            initialData={editingCompany}
            onCancel={() => setEditingCompany(null)}
        />
        </Box>
        <CompanyList
          companies={companies}
          onEdit={setEditingCompany}
          onDelete={deleteCompany}
        />
    </Box>
    </>
  );
}

