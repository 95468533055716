import React, { useState, useEffect } from 'react';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import processConfig from '../../config/processIndex.json';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
} from '@mui/material';
import { 
  Rocket, TrendingUp, DollarSign, Gem, 
  CheckCircle, ArrowRight, Users 
} from 'lucide-react';
import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, 
  Tooltip, Legend, ReferenceLine 
} from 'recharts';

const minNumberOfGroup = 10;

const GoalBasedAnalysis = ({ data, onComplete, sessionId }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_33');
  console.log('Glow33Processor received data:', data);

  // Business goals configuration
  const businessGoals = {
    speed: {
      id: 'speed',
      title: 'Fastest to Market',
      icon: Rocket,
      description: 'Launch quickly and validate market fit',
      recommendedGroups: 3,
      keyBenefits: [
        'Faster implementation with fewer groups',
        'Quick initial market validation',
        'Easier coordination and management'
      ]
    },
    growth: {
      id: 'growth',
      title: 'Fastest Growth',
      icon: TrendingUp,
      description: 'Maximize market adoption and expansion',
      recommendedGroups: 5,
      keyBenefits: [
        'Broad market coverage',
        'Balanced resource allocation',
        'Structured growth approach'
      ]
    },
    revenue: {
      id: 'revenue',
      title: 'Maximum Revenue',
      icon: DollarSign,
      description: 'Optimize for highest revenue potential',
      recommendedGroups: 7,
      keyBenefits: [
        'Detailed market segmentation',
        'Targeted pricing strategies',
        'Optimized revenue per segment'
      ]
    },
    investment: {
      id: 'investment',
      title: 'Investment Attractive',
      icon: Gem,
      description: 'Position for investment and scaling',
      recommendedGroups: minNumberOfGroup - 1,
      keyBenefits: [
        'Clear market strategy',
        'Scalable structure',
        'Attractive to investors'
      ]
    }
  };

  const groupSizeConsiderations = {
    3: {
      advantages: [
        'Quick implementation',
        'Clear focus and priorities',
        'Easy to manage and coordinate'
      ],
      considerations: [
        'Limited market coverage',
        'May miss some opportunities',
        'Less granular targeting'
      ]
    },
    5: {
      advantages: [
        'Good balance of speed and coverage',
        'Manageable complexity',
        'Efficient resource allocation'
      ],
      considerations: [
        'Moderate market coverage',
        'Some segments might overlap',
        'Medium coordination needed'
      ]
    },
    7: {
      advantages: [
        'Optimal market coverage',
        'Clear segment differentiation',
        'Balanced resource requirements'
      ],
      considerations: [
        'Higher coordination needs',
        'More complex implementation',
        'Requires more resources'
      ]
    },
    9: {
      advantages: [
        'Comprehensive market coverage',
        'Highly targeted approach',
        'Detailed market insights'
      ],
      considerations: [
        'Complex coordination',
        'Higher resource requirements',
        'Longer implementation time'
      ]
    },
    11: {
      advantages: [
        'Maximum market coverage',
        'Very detailed segmentation',
        'Complete market view'
      ],
      considerations: [
        'Highest complexity',
        'Resource intensive',
        'Most challenging to manage'
      ]
    }
  };

  // Target groups data
  const targetGroups = [
    { name: "E-commerce Product Teams", score: 95, marketSize: 2500000 },
    { name: "Digital Marketing Agencies", score: 92, marketSize: 1800000 },
    { name: "Social Media Content Creators", score: 88, marketSize: 3500000 },
    { name: "UX Research Teams", score: 85, marketSize: 800000 },
    { name: "Product Review Channels", score: 82, marketSize: 1200000 },
    { name: "Marketplace Sellers", score: 79, marketSize: 4500000 },
    { name: "Brand Marketing Teams", score: 77, marketSize: 900000 },
    { name: "Small Online Retailers", score: 74, marketSize: 5500000 },
    { name: "Crowdfunding Campaigns", score: 72, marketSize: 300000 },
    { name: "Tech Product Startups", score: 69, marketSize: 750000 },
    { name: "Affiliate Marketers", score: 67, marketSize: 2800000 }
  ];

  const SAMPLE_DATA = {
    "target_groups": [
				{
					"group_name": "Local Sports Teams",
					"score": 95,
					"key_benefits": [
						"Enhanced fan engagement through interactive overlays.",
						"Increased visibility and promotion of local athletes.",
						"Opportunities for sponsorship and advertising revenue."
					],
					"group_size": {
						"size": "100-500 teams",
						"advantages": [
							"Strong community support and loyalty.",
							"Regular events create consistent content opportunities.",
							"Potential for growth as new teams emerge."
						],
						"considerations": [
							"Limited budgets for technology investments.",
							"Need for training on how to use the service.",
							"Variability in tech-savvy among teams."
						]
					}
				},
				{
					"group_name": "Colleges and Universities",
					"score": 90,
					"key_benefits": [
						"Opportunity to showcase athletic programs to a wider audience.",
						"Engagement with current students and alumni through live events.",
						"Potential for partnerships with local businesses for sponsorships."
					],
					"group_size": {
						"size": "1,000+ institutions",
						"advantages": [
							"Diverse range of sports to cover.",
							"Established fan bases and alumni networks.",
							"Access to student volunteers for production support."
						],
						"considerations": [
							"Budget constraints due to varying funding levels.",
							"Need for compliance with NCAA and other regulations.",
							"Differing levels of interest in live streaming across sports."
						]
					}
				},
				{
					"group_name": "Amateur Sports Leagues",
					"score": 85,
					"key_benefits": [
						"Ability to reach family and friends who cannot attend games.",
						"Creation of a digital record of games for players and teams.",
						"Encouragement of increased participation through visibility."
					],
					"group_size": {
						"size": "500-1,000 leagues",
						"advantages": [
							"High community involvement and engagement.",
							"Regular schedules allow for consistent streaming.",
							"Variety of sports creates diverse content options."
						],
						"considerations": [
							"Limited technical knowledge among league organizers.",
							"Potential for inconsistent production quality.",
							"Variable interest from league members."
						]
					}
				},
				{
					"group_name": "Fitness and Wellness Centers",
					"score": 80,
					"key_benefits": [
						"Attracting new clients through live classes and events.",
						"Building a community around fitness activities.",
						"Offering personalized coaching through interactive features."
					],
					"group_size": {
						"size": "5,000+ centers",
						"advantages": [
							"Growing trend of online fitness classes.",
							"Potential for on-demand content to complement live streams.",
							"Strong focus on customer engagement and retention."
						],
						"considerations": [
							"Need for high-quality production to maintain brand image.",
							"Potential competition from established fitness streaming platforms.",
							"Varied technical capabilities across different centers."
						]
					}
				},
				{
					"group_name": "High School Sports Programs",
					"score": 75,
					"key_benefits": [
						"Providing exposure for student-athletes to scouts and recruiters.",
						"Creating a sense of school spirit and community involvement.",
						"Facilitating fundraising efforts through live events."
					],
					"group_size": {
						"size": "25,000+ schools",
						"advantages": [
							"Strong community ties and engagement.",
							"Regular sporting events throughout the school year.",
							"Access to a large audience of parents and students."
						],
						"considerations": [
							"Limited budgets for technology and equipment.",
							"Need for training staff and students on production techniques.",
							"Inconsistent interest across different sports."
						]
					}
				},
				{
					"group_name": "Esports Organizations",
					"score": 70,
					"key_benefits": [
						"Growing audience for live-streamed gaming events.",
						"Potential for sponsorships from tech and gaming companies.",
						"Creating an engaging community around competitive gaming."
					],
					"group_size": {
						"size": "1,000+ organizations",
						"advantages": [
							"Rapidly expanding market with high engagement levels.",
							"Diverse content opportunities across various games.",
							"Access to tech-savvy audiences who appreciate innovation."
						],
						"considerations": [
							"High competition in the live-streaming space.",
							"Need for high production quality to attract viewers.",
							"Varied interest in different gaming genres."
						]
					}
				},
				{
					"group_name": "Corporate Wellness Programs",
					"score": 65,
					"key_benefits": [
						"Enhancing employee engagement through live fitness activities.",
						"Promoting health and wellness culture within the organization.",
						"Opportunity for branding through sponsored events."
					],
					"group_size": {
						"size": "10,000+ companies",
						"advantages": [
							"Increased focus on employee well-being.",
							"Potential for significant viewer engagement among employees.",
							"Diverse range of activities to showcase."
						],
						"considerations": [
							"Need for alignment with corporate health goals.",
							"Varying levels of interest in fitness among employees.",
							"Budget constraints in smaller companies."
						]
					}
				},
				{
					"group_name": "Community Recreation Centers",
					"score": 60,
					"key_benefits": [
						"Providing a platform for local events and activities.",
						"Fostering community connections through shared experiences.",
						"Encouraging participation in recreational programs."
					],
					"group_size": {
						"size": "5,000+ centers",
						"advantages": [
							"Strong community involvement and engagement.",
							"Regular events provide consistent content opportunities.",
							"Variety of activities and sports to cover."
						],
						"considerations": [
							"Limited resources for advanced technology.",
							"Potential for inconsistent event scheduling.",
							"Need for training staff on live streaming."
						]
					}
				},
				{
					"group_name": "National Sports Associations",
					"score": 55,
					"key_benefits": [
						"Showcasing national talent through live events.",
						"Increasing visibility for lesser-known sports.",
						"Opportunity for sponsorship deals and partnerships."
					],
					"group_size": {
						"size": "500+ associations",
						"advantages": [
							"Access to a broad audience base.",
							"Existing infrastructure supports live events.",
							"Potential for high-quality productions with resources."
						],
						"considerations": [
							"Complexities in coordinating events across regions.",
							"Diverse interests among different sports associations.",
							"Variable budgets and funding sources."
						]
					}
				},
				{
					"group_name": "Youth Sports Camps",
					"score": 50,
					"key_benefits": [
						"Engaging parents and families with live showcases of skills.",
						"Building a camp community through shared experiences.",
						"Attracting new participants through exposure."
					],
					"group_size": {
						"size": "2,000+ camps",
						"advantages": [
							"High engagement from parents and campers.",
							"Regular events provide consistent content opportunities.",
							"Potential for unique content through different sports."
						],
						"considerations": [
							"Limited budgets for technology investments.",
							"Need for training on how to use the service.",
							"Variability in tech-savvy among camp staff."
						]
					}
				}
			]
  }

  // Data comes in directly, not wrapped
  const hasRealData = data?.target_groups;
  const displayData = hasRealData ? data : SAMPLE_DATA;
  const isDemo = !hasRealData;

  const [selectedGoal, setSelectedGoal] = useState(() => {    
    return displayData?.selectedGoal || null;
  });
  const [selectedAmount, setSelectedAmount] = useState(() => {    
    return displayData?.selectedAmount || 7;
  });
  const [selectedGroupData, setSelectedGroupData] = useState(() => {    
    return displayData?.selectedAmount ? displayData.target_groups[displayData?.selectedAmount]:  displayData.target_groups[7];
  });

  const handleChangeGroup = (index) => {
    if (index >= 0 && index < displayData.target_groups.length) {
      setSelectedAmount(index);
      setSelectedGroupData(displayData.target_groups[index]);
    }
  }

  // Get groups based on selected amount
  const getGroupsForSize = (size) => displayData.target_groups.slice(0, size + 1);

  // Calculate average score
  const calculateAverage = (groups) => {
    if (!groups || groups.length === 0) return 0;
    const sum = groups.reduce((acc, group) => acc + group.score, 0);
    return (sum / groups.length).toFixed(1);
  };

  // Color utilities
  const getFeasibilityColor = (score) => {
    if (score >= 80) return 'bg-green-500';
    if (score >= 70) return 'bg-yellow-500';
    return 'bg-red-500';
  };

  const getAverageScoreColor = (score) => {
    if (score >= 80) return 'text-green-600';
    if (score >= 70) return 'text-yellow-600';
    return 'text-red-600';
  };

  const handleContinue = () => {
    if (selectedGoal) {
      onComplete({
        processId: 'glow_33',
        data: {
          status: true,
          glow_33_response: {
            selectedGoal: selectedGoal,
            selectedAmount: selectedAmount, 
            ...data
          }
        }
      });
    }
  };

  return (
    <>
    {isDemo && (
      <Box sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(-45deg)',
        color: 'rgba(255, 140, 0, 0.45)',
        fontSize: '48px',
        fontWeight: 'bold',
        pointerEvents: 'none',
        zIndex: 9999,
        width: '100%',
        textAlign: 'center',
        userSelect: 'none',
        textTransform: 'uppercase'
      }}>
        Sample (Glowtest)
      </Box>
    )}
    <ViewerHeader 
      title="AI Video Production - Target Group Selection"
      subtitle="Choose from our variety of video types and start creating your professional video in minutes"
      processId="glow_33"
    />
    <Card className="w-full mx-auto">
      <CardContent>
        <div className="space-y-8">
          {/* Goal Selection */}
          <div className="space-y-4">
            <div className="grid grid-cols-4 gap-4">
              {Object.entries(businessGoals).map(([key, goal]) => {
                const Icon = goal.icon;
                return (
                  <Button
                    key={key}
                    variant={selectedGoal === key ? "contained" : "outlined"}
                    onClick={() => {
                      setSelectedGoal(key);
                      handleChangeGroup(goal.recommendedGroups);
                    }}
                    className="h-auto py-4 flex flex-col gap-2"
                  >
                    <Icon className="h-6 w-6" />
                    <div className="text-sm font-medium">{goal.title}</div>
                    <div className="text-xs opacity-75">{goal.description}</div>
                  </Button>
                );
              })}
            </div>
          </div>

          {selectedGoal && (
            <>
              {/* Goal Summary */}
              <div className="border border-green-200 rounded-lg p-6">
                <div className="flex items-start gap-4">
                  <div className="bg-green-100 p-3 rounded-full">
                    <Users className="h-6 w-6 text-green-600" />
                  </div>
                  <div>
                    <h4 className="text-lg font-medium">
                      Recommended: {businessGoals[selectedGoal].recommendedGroups} Target Groups
                    </h4>
                    <div className="mt-4">
                      <div className="font-medium mb-2">Key Benefits:</div>
                      <ul className="space-y-1">
                        {displayData.target_groups[businessGoals[selectedGoal].recommendedGroups].key_benefits.map((benefit, idx) => (
                          <li key={idx} className="text-sm flex items-center gap-2">
                            <CheckCircle className="h-4 w-4 text-green-500" />
                            {benefit}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* Target Groups Visualization */}
              <div className="space-y-6 border border-green-200 rounded-lg p-6">
                <div className="flex justify-around gap-2 items-center">
                  {displayData.target_groups.map((group, index) => (
                    <div key={index} className="flex flex-col items-center gap-1">
                      <Button
                        variant={selectedAmount === index ? "contained" : "outlined"}
                        onClick={() => handleChangeGroup(index)}
                        className="min-w-[60px]"
                      >
                        {index + 1}
                        {index === selectedAmount && (
                          <CheckCircle className="ml-1 text-green-500" size={14} />
                        )}
                      </Button>
                      <span className={`text-sm font-medium ${getAverageScoreColor(calculateAverage(getGroupsForSize(index)))}`}>
                        Avg: {calculateAverage(getGroupsForSize(index))}%
                      </span>
                    </div>
                  ))}
                </div>

                <div className="space-y-2">
                  {getGroupsForSize(selectedAmount).map((group) => (
                    <div key={group.group_name} className="space-y-1">
                      <div className="flex justify-between text-sm">
                        <span className="font-medium flex items-center gap-2">
                          {group.group_name}
                          <span className="text-gray-500">
                            {group.group_size.size}
                          </span>
                        </span>
                        <span>{group.score}%</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div
                          className={`h-2.5 rounded-full ${getFeasibilityColor(group.score)}`}
                          style={{ width: `${group.score}%` }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Group Size Considerations */}
                <div className="grid grid-cols-2 gap-4 ">
                  <div className="p-4 rounded-lg" style={{backgroundColor:'var(--card-bg)'}}>
                    <h4 className="font-medium mb-3">Advantages of {selectedAmount} Groups</h4>
                    <ul className="space-y-2">
                      {selectedGroupData.group_size.advantages.map((advantage, idx) => (
                        <li key={idx} className="text-sm flex items-center gap-2">
                          <CheckCircle className="h-4 w-4 text-green-500" />
                          {advantage}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="p-4 rounded-lg" style={{backgroundColor:'var(--card-bg)'}}>
                    <h4 className="font-medium mb-3">Considerations</h4>
                    <ul className="space-y-2">
                      {selectedGroupData.group_size.considerations.map((consideration, idx) => (
                        <li key={idx} className="text-sm flex items-center gap-2">
                          <ArrowRight className="h-4 w-4 text-gray-500" />
                          {consideration}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              {/* Score Legend */}
              <div className="text-sm text-gray-500 border border-green-200 rounded-lg p-6">
                <div>Feasibility Score Legend:</div>
                <div className="flex flex-wrap justify-around gap-4 mt-2">
                  <span className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-green-500 mr-2"></span>
                    High (80-100%)
                  </span>
                  <span className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-yellow-500 mr-2"></span>
                    Medium (70-79%)
                  </span>
                  <span className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-red-500 mr-2"></span>
                    Low (60-69%)
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>

    <ViewFooter
      isDisabled={!sessionId} 
      showButton={!!selectedGoal}
      onComplete={handleContinue}
      buttonText={process.footer.continueText}
    />
    </>
  );
};

export default GoalBasedAnalysis;
