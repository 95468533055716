import axios from 'axios';
import processConfig from '../config/processIndex.json';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const ProcessChainManager = {
  // Update process order to use all chains
  processOrder: [
    ...processConfig.processChains.ideaChain,
    ...processConfig.processChains.videoChain,
    ...processConfig.processChains.advertisementChain
  ].filter((value, index, self) => self.indexOf(value) === index),

  inputPrompt: new String(),
  sessionId: new String(),
  responseCache: new Map(),

  // Get chain metadata
  getChainMetadata(chainId) {
    return processConfig.chainMetadata[chainId];
  },

  // Get all chain IDs in order
  getChainIds() {
    return Object.keys(processConfig.chainMetadata)
      .sort((a, b) => processConfig.chainMetadata[a].order - processConfig.chainMetadata[b].order);
  },

  // Get processes for a specific chain
  getChainProcesses(chainId) {
    return processConfig.processChains[chainId] || [];
  },

  async runProcess(processId, sessionId, inputData = {}) {
    const process = processConfig.processes.find(p => p.id === processId) || processConfig.initialProcess;
    if (!process) {
      throw new Error(`Process ${processId} not found in configuration`);
    }

    const endpoint = `${process.endpoints.run}`;
    if(!endpoint || endpoint === 'undefined') {
      return {
        status: true
      };
    }

    console.log(`Running ${processId} with payload:`, inputData);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/${endpoint}`,
        inputData,
        {
          headers: { 
            Authorization: `Bearer ${Cookies.get('token')}` 
          }
        }
      );

      if (!response.data.status) {
        console.log('Process failed:', response.data.message);
      }
      this.responseCache.set(processId, response.data);
      return response.data.data;
    } catch (error) {
      console.error(`Error running ${processId}:`, error);
      throw error;
    }
  },

  async viewProcess(processId, sessionId) {
    const process = processConfig.processes.find(p => p.id === processId) || processConfig.initialProcess;
    
    try {
      const response = await Promise.race([
        axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/${process.endpoints.view}`,
          { session_id: sessionId },
          { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
        ),
        new Promise((_, reject) => setTimeout(() => reject(new Error('Request timed out')), 5000))
      ]);

      return this.extractProcessData(processId, response.data.data.session_details);
    } catch (error) {
      console.error(`Error viewing ${processId}:`, error);
      throw error;
    }
  },

  extractProcessData(processId, sessionDetails) {
    const responseKey = `${processId}_data`;
    const processResponse = `${processId}_response`;
    
    // Special handling for glow_30 since it doesn't require API data
    if (processId === 'glow_30') {
      return {
        status: true,
        data: sessionDetails  // Pass all session details for final pitch
      };
    }
    
    return sessionDetails[responseKey] ? 
      sessionDetails[responseKey][processResponse] : 
      null;
  },

  updateCache(sessionDetails) {
    if (!sessionDetails) return;
    this.clearAllCache();
    this.inputPrompt = sessionDetails.input_prompt || '';
    this.sessionId = sessionDetails.session_id || '';
    
    this.processOrder.forEach(processId => {
      const responseKey = `${processId}_data`;
      const processResponse = `${processId}_response`;
      
      if (sessionDetails[responseKey]?.[processResponse]) {
        this.responseCache.set(processId, {
          data: sessionDetails[responseKey],
          isCached: true,
          status: true
        });
      }
    });
    console.log('Updated Response Cache:', this.responseCache);
  },

  updateCacheElement(processId, data) {
    if (!processId || !data) return;
    this.responseCache.set(processId,data);
  },

  getCachedResponse(processId) {
    return this.responseCache.get(processId);
  },

  getCachedResponseData(processId) {
    const processResponse = `${processId}_response`;
    return this.responseCache.get(processId)?.data?.[processResponse] || null;
  },

  clearCache(processId) {
    this.responseCache.delete(processId);
  },

  // Clear all cache
  clearAllCache() {
    this.responseCache.clear();
    this.sessionId = '';
    this.inputPrompt = '';
  },

  // Add method to persist cache to cookies
  persistCache() {
    const cacheData = Object.fromEntries(this.responseCache);
    Cookies.set('processCache', JSON.stringify(cacheData), { expires: 1 }); // expires in 1 day
  },

  // Load cache from cookies
  loadCache() {
    const cachedData = Cookies.get('processCache');
    if (cachedData) {
      try {
        const parsed = JSON.parse(cachedData);
        this.responseCache = new Map(Object.entries(parsed));
      } catch (error) {
        console.error('Error loading cache from cookies:', error);
      }
    }
  },
};