import React from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import { FaPlay } from 'react-icons/fa';

import { useSelector } from 'react-redux';
import { selectSessionStatus, selectSessionProcess } from '../../components/session/sessionSlide';

const ViewFooter = ({ 
  onComplete,
  buttonText,
  isDisabled = false,
  showButton = true,
  customButtons = null
}) => {

  const sessionStatus = useSelector(selectSessionStatus)
  const sessionProcess = useSelector(selectSessionProcess)

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      justifyContent: 'center',
      gap: 2,
      mt: 4,
      pt: 3,
      borderTop: '1px solid var(--border-color)',
      width: '100%'
    }}>
      {customButtons}
      {showButton && (
        <Tooltip title={isDisabled ? (sessionStatus === 'loading' ? `Please wait until ${sessionProcess} is finished` : "Please submit your input prompt before proceeding to switch between steps.") : ""} arrow>
          <span>
            <Button
              variant="contained"
              onClick={onComplete}
              disabled={isDisabled || sessionStatus === 'loading'}
              startIcon={<FaPlay />}
              sx={{
                color: 'var(--button-text)',
                backgroundColor: 'var(--button-bg)',
                '&:hover': {
                  backgroundColor: 'var(--button-hover)'
                },
                minWidth: '200px',
                height: '48px'
              }}
            >
              {buttonText}
            </Button>
          </span>
        </Tooltip>
      )}
    </Box>
  );
};

export default ViewFooter;