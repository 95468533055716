import React, { useState } from 'react';
import './Viewers.css';
import './glow_03_processor_script.css'
import processConfig from '../../config/processIndex.json';
import { Box, CircularProgress, Button } from '@mui/material';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import { ProcessChainManager } from '../../utils/processChain';
import { generateAndSaveTargetGroupImage } from '../../utils/processUtils';

const SAMPLE_DATA = {  
  "1": {
    "video_id": "sports_fans_1",
    "audience": "Sports Fans",
    "content_reference": "1",
    "video_base": {
      "style": "Dynamic",
      "audio_base": {
        "timbre": "Upbeat electronic sounds",
        "tempo": 120,
        "core_rhythm": "4/4",
        "harmonic_base": "C major"
      },
      "color_scheme": "Blue and Gold with White accents",
      "duration_video": "120"
    },
    "scenes": [
      {
        "scene_number": 1,
        "scene_title": "Engaging Live Streaming Experience",
        "scene_type": "explanation",
        "duration_scene": "6",
        "purpose": "Showcase the immersive experience of live sports.",
        "emotion": "high intensity",
        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, lack of excitement, unengaged crowd, blurry visuals, muted colors",
        "scene_audio": {
          "narration": {
            "text_narration": "Experience live sports like never before.",
            "duration_narration": "5.4"
          },
          "background_music": {
            "style": "Energetic",
            "intensity": 8
          }
        },
        "frames": [
          {
            "frame_number": 1,
            "frame_type": "START",
            "primary_person": {
              "description": "A close-up of a fan's face",
              "action": "cheering enthusiastically",
              "expression": "excited and joyful"
            },
            "environment": {
              "setting": "Stadium",
              "background": "Crowd cheering",
              "props": [
                "Scoreboard"
              ]
            },
            "technical": {
              "camera": "Close-up shot",
              "lighting": "Natural light",
              "color_grade": "Vibrant"
            },
            "full_visual_prompt": "Photorealistic close-up of a fan cheering in a stadium, showing excitement and joy, with a blurred background of a cheering crowd and a visible scoreboard, natural light, vibrant colors.",
            "duration_frame": "2.4",
            "transition": "fade"
          },
          {
            "frame_number": 2,
            "frame_type": "IMPORTANT",
            "primary_person": {
              "description": "A group of fans",
              "action": "jumping and celebrating",
              "expression": "ecstatic"
            },
            "environment": {
              "setting": "Stadium",
              "background": "Wide shot of the crowd",
              "props": [
                "Banners"
              ]
            },
            "technical": {
              "camera": "Wide shot",
              "lighting": "Dynamic lighting",
              "color_grade": "High contrast"
            },
            "full_visual_prompt": "Dynamic wide shot of a group of ecstatic fans jumping and celebrating in a stadium, surrounded by a vibrant crowd and colorful banners, dynamic lighting, high contrast.",
            "duration_frame": "2.4",
            "transition": "zoom"
          },
          {
            "frame_number": 3,
            "frame_type": "ENDING",
            "primary_person": {
              "description": "A fan holding a smartphone",
              "action": "recording the event",
              "expression": "thrilled"
            },
            "environment": {
              "setting": "Stadium",
              "background": "Crowd in the background",
              "props": [
                "Smartphone"
              ]
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Backlit",
              "color_grade": "Warm"
            },
            "full_visual_prompt": "Medium shot of a thrilled fan holding a smartphone to record the event, with a cheering crowd in the background, backlit for a warm glow.",
            "duration_frame": "1.2",
            "transition": "fade"
          }
        ],
        "scene_visuals_basics": "Natural expressions of excitement.",
        "scene_visuals_extensive": "Dynamic footage of a crowd cheering during a live sports event, with emphasis on the scoreboard overlay.",
        "scene_audio_background": "Crowd cheering and commentary.",
        "success_metric": "Increased viewer engagement."
      },
      {
        "scene_number": 2,
        "scene_title": "Customizable Overlay",
        "scene_type": "data",
        "duration_scene": "6",
        "purpose": "Highlight the customizable branding options.",
        "emotion": "controlled",
        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, lack of clarity, unappealing designs, cluttered interface, muted colors",
        "scene_audio": {
          "narration": {
            "text_narration": "Customize your stream with unique overlays.",
            "duration_narration": "5.4"
          },
          "background_music": {
            "style": "Subtle and modern",
            "intensity": 5
          }
        },
        "frames": [
          {
            "frame_number": 1,
            "frame_type": "START",
            "primary_person": {
              "description": "A user at a computer",
              "action": "navigating the customization menu",
              "expression": "focused"
            },
            "environment": {
              "setting": "Home Office",
              "background": "Computer screen",
              "props": [
                "Mouse",
                "Keyboard"
              ]
            },
            "technical": {
              "camera": "Close-up shot",
              "lighting": "Soft light",
              "color_grade": "Cool tones"
            },
            "full_visual_prompt": "Close-up of a focused user at a computer, navigating the customization menu for overlays, with a computer screen displaying options, soft lighting, cool tones.",
            "duration_frame": "2.4",
            "transition": "slide"
          },
          {
            "frame_number": 2,
            "frame_type": "IMPORTANT",
            "primary_person": {
              "description": "A split screen of overlay options",
              "action": "showing various designs",
              "expression": "informative"
            },
            "environment": {
              "setting": "Digital Interface",
              "background": "Overlay options",
              "props": []
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Bright light",
              "color_grade": "Neutral"
            },
            "full_visual_prompt": "Medium shot of a split screen showcasing various overlay options, with informative labels and bright lighting.",
            "duration_frame": "2.4",
            "transition": "fade"
          },
          {
            "frame_number": 3,
            "frame_type": "ENDING",
            "primary_person": {
              "description": "A satisfied user",
              "action": "previewing the customized overlay",
              "expression": "satisfied"
            },
            "environment": {
              "setting": "Home Office",
              "background": "Computer screen with overlay",
              "props": []
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Natural light",
              "color_grade": "Soft tones"
            },
            "full_visual_prompt": "Medium shot of a satisfied user previewing the customized overlay on their computer screen, with natural light and soft tones.",
            "duration_frame": "1.2",
            "transition": "zoom"
          }
        ],
        "scene_visuals_basics": "Refined movements of overlay customization.",
        "scene_visuals_extensive": "Visual demonstration of a user customizing the overlay on a streaming platform, showcasing different branding options.",
        "scene_audio_background": "Slight electronic sounds.",
        "success_metric": "User satisfaction with customization options."
      },
      {
        "scene_number": 3,
        "scene_title": "Real-time Scoreboard Integration",
        "scene_type": "explanation",
        "duration_scene": "7",
        "purpose": "Explain real-time scoreboard functionality.",
        "emotion": "controlled",
        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, lack of clarity, unengaging visuals, static scoreboard, muted colors",
        "scene_audio": {
          "narration": {
            "text_narration": "Get real-time updates right on your screen.",
            "duration_narration": "6.3"
          },
          "background_music": {
            "style": "Informative",
            "intensity": 4
          }
        },
        "frames": [
          {
            "frame_number": 1,
            "frame_type": "START",
            "primary_person": {
              "description": "A close-up of the scoreboard",
              "action": "displaying current scores",
              "expression": "informative"
            },
            "environment": {
              "setting": "Stadium",
              "background": "Live game",
              "props": []
            },
            "technical": {
              "camera": "Close-up shot",
              "lighting": "Bright stadium lights",
              "color_grade": "High saturation"
            },
            "full_visual_prompt": "Close-up of the scoreboard displaying current scores during a live game, bright stadium lights illuminating the scene, high saturation.",
            "duration_frame": "2.8",
            "transition": "cut"
          },
          {
            "frame_number": 2,
            "frame_type": "IMPORTANT",
            "primary_person": {
              "description": "A split-screen showing fans reacting",
              "action": "watching the scoreboard",
              "expression": "engaged"
            },
            "environment": {
              "setting": "Stadium",
              "background": "Fans in the stands",
              "props": []
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Natural light",
              "color_grade": "Balanced"
            },
            "full_visual_prompt": "Medium shot of a split-screen showing engaged fans reacting to the scoreboard updates during the game, natural light and balanced color grading.",
            "duration_frame": "2.8",
            "transition": "fade"
          },
          {
            "frame_number": 3,
            "frame_type": "ENDING",
            "primary_person": {
              "description": "A scoreboard with live updates",
              "action": "showing the latest scores",
              "expression": "dynamic"
            },
            "environment": {
              "setting": "Stadium",
              "background": "Live game atmosphere",
              "props": []
            },
            "technical": {
              "camera": "Wide shot",
              "lighting": "Dynamic lighting",
              "color_grade": "Vibrant"
            },
            "full_visual_prompt": "Wide shot of the scoreboard showing live updates in a dynamic stadium atmosphere, vibrant colors and dynamic lighting.",
            "duration_frame": "1.4",
            "transition": "zoom"
          }
        ],
        "scene_visuals_basics": "Natural expressions of interest.",
        "scene_visuals_extensive": "Footage of the scoreboard being updated live during a game, with a split-screen showing viewer reactions.",
        "scene_audio_background": "Game commentary and scoreboard sounds.",
        "success_metric": "Viewer retention during key moments."
      },
      {
        "scene_number": 4,
        "scene_title": "Interactive Features for Engagement",
        "scene_type": "explanation",
        "duration_scene": "7",
        "purpose": "Demonstrate interactive features for viewers.",
        "emotion": "high intensity",
        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, lack of engagement, static interactions, muted colors",
        "scene_audio": {
          "narration": {
            "text_narration": "Engage with polls and live chats during the game.",
            "duration_narration": "6.3"
          },
          "background_music": {
            "style": "Upbeat",
            "intensity": 9
          }
        },
        "frames": [
          {
            "frame_number": 1,
            "frame_type": "START",
            "primary_person": {
              "description": "A fan using a smartphone",
              "action": "participating in a live poll",
              "expression": "enthusiastic"
            },
            "environment": {
              "setting": "Stadium",
              "background": "Crowd cheering",
              "props": [
                "Smartphone"
              ]
            },
            "technical": {
              "camera": "Close-up shot",
              "lighting": "Natural light",
              "color_grade": "Bright"
            },
            "full_visual_prompt": "Close-up of an enthusiastic fan using a smartphone to participate in a live poll during the game, with a cheering crowd in the background, natural light, bright colors.",
            "duration_frame": "2.8",
            "transition": "cut"
          },
          {
            "frame_number": 2,
            "frame_type": "IMPORTANT",
            "primary_person": {
              "description": "A group of fans interacting",
              "action": "engaging in a live chat",
              "expression": "excited"
            },
            "environment": {
              "setting": "Stadium",
              "background": "Fans in the stands",
              "props": []
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Dynamic lighting",
              "color_grade": "Vibrant"
            },
            "full_visual_prompt": "Medium shot of a group of excited fans engaging in a live chat during the game, vibrant colors and dynamic lighting.",
            "duration_frame": "2.8",
            "transition": "fade"
          },
          {
            "frame_number": 3,
            "frame_type": "ENDING",
            "primary_person": {
              "description": "A scoreboard showing poll results",
              "action": "displaying results",
              "expression": "dynamic"
            },
            "environment": {
              "setting": "Stadium",
              "background": "Live game atmosphere",
              "props": []
            },
            "technical": {
              "camera": "Wide shot",
              "lighting": "Bright stadium lights",
              "color_grade": "High saturation"
            },
            "full_visual_prompt": "Wide shot of a scoreboard displaying live poll results in a bright stadium atmosphere, high saturation.",
            "duration_frame": "1.4",
            "transition": "zoom"
          }
        ],
        "scene_visuals_basics": "Enthusiastic reactions and interactions.",
        "scene_visuals_extensive": "Close-ups of fans using their devices to participate in live polls and chats while watching the game.",
        "scene_audio_background": "Exciting crowd noises and chat sounds.",
        "success_metric": "Increased interaction rates."
      },
      {
        "scene_number": 5,
        "scene_title": "High-quality Visuals and Sound",
        "scene_type": "data",
        "duration_scene": "6",
        "purpose": "Emphasize the quality of the broadcast.",
        "emotion": "controlled",
        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, lack of clarity, unappealing visuals, muted colors, distorted sound",
        "scene_audio": {
          "narration": {
            "text_narration": "Enjoy stunning visuals and crystal-clear sound.",
            "duration_narration": "5.4"
          },
          "background_music": {
            "style": "Calm and refined",
            "intensity": 3
          }
        },
        "frames": [
          {
            "frame_number": 1,
            "frame_type": "START",
            "primary_person": {
              "description": "A wide shot of the stadium",
              "action": "showing the crowd",
              "expression": "neutral"
            },
            "environment": {
              "setting": "Stadium",
              "background": "Crowd and field",
              "props": []
            },
            "technical": {
              "camera": "Wide shot",
              "lighting": "Natural light",
              "color_grade": "Bright"
            },
            "full_visual_prompt": "Wide shot of the stadium showcasing the crowd and the field under natural light, bright colors.",
            "duration_frame": "2.4",
            "transition": "cut"
          },
          {
            "frame_number": 2,
            "frame_type": "IMPORTANT",
            "primary_person": {
              "description": "Close-up of sound equipment",
              "action": "in action",
              "expression": "focused"
            },
            "environment": {
              "setting": "Broadcast area",
              "background": "Sound mixing board",
              "props": []
            },
            "technical": {
              "camera": "Close-up shot",
              "lighting": "Studio light",
              "color_grade": "Neutral"
            },
            "full_visual_prompt": "Close-up of sound equipment in action at the broadcast area, studio lighting providing a neutral color grade.",
            "duration_frame": "2.4",
            "transition": "fade"
          },
          {
            "frame_number": 3,
            "frame_type": "ENDING",
            "primary_person": {
              "description": "A fan enjoying the broadcast",
              "action": "watching intently",
              "expression": "satisfied"
            },
            "environment": {
              "setting": "Living Room",
              "background": "TV screen",
              "props": []
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Warm light",
              "color_grade": "Soft tones"
            },
            "full_visual_prompt": "Medium shot of a satisfied fan watching the broadcast in a living room, warm light and soft tones creating a cozy atmosphere.",
            "duration_frame": "1.2",
            "transition": "zoom"
          }
        ],
        "scene_visuals_basics": "Refined movements showcasing quality.",
        "scene_visuals_extensive": "Wide shots of the stadium with clear visuals, mixed with close-ups of the sound equipment in action.",
        "scene_audio_background": "High-quality audio samples from the event.",
        "success_metric": "Positive feedback on broadcast quality."
      },
      {
        "scene_number": 6,
        "scene_title": "Call to Action",
        "scene_type": "emotional",
        "duration_scene": "5",
        "purpose": "Encourage viewers to join the experience.",
        "emotion": "high intensity",
        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, lack of excitement, muted colors, disengaged fans",
        "scene_audio": {
          "narration": {
            "text_narration": "Join us and elevate your game day experience!",
            "duration_narration": "4.5"
          },
          "background_music": {
            "style": "Exciting",
            "intensity": 10
          }
        },
        "frames": [
          {
            "frame_number": 1,
            "frame_type": "START",
            "primary_person": {
              "description": "A group of fans celebrating",
              "action": "cheering together",
              "expression": "ecstatic"
            },
            "environment": {
              "setting": "Stadium",
              "background": "Crowd around them",
              "props": []
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Dynamic lighting",
              "color_grade": "Bright"
            },
            "full_visual_prompt": "Medium shot of a group of ecstatic fans celebrating together in a stadium, surrounded by a cheering crowd, bright colors and dynamic lighting.",
            "duration_frame": "2.0",
            "transition": "fade"
          },
          {
            "frame_number": 2,
            "frame_type": "IMPORTANT",
            "primary_person": {
              "description": "A fan holding a sign",
              "action": "encouraging others",
              "expression": "enthusiastic"
            },
            "environment": {
              "setting": "Stadium",
              "background": "Cheering crowd",
              "props": [
                "Sign"
              ]
            },
            "technical": {
              "camera": "Close-up shot",
              "lighting": "Natural light",
              "color_grade": "Vibrant"
            },
            "full_visual_prompt": "Close-up of an enthusiastic fan holding a sign and encouraging others to join in the excitement, with a cheering crowd in the background, natural light and vibrant colors.",
            "duration_frame": "2.0",
            "transition": "zoom"
          },
          {
            "frame_number": 3,
            "frame_type": "ENDING",
            "primary_person": {
              "description": "A montage of fans",
              "action": "celebrating and cheering",
              "expression": "joyful"
            },
            "environment": {
              "setting": "Stadium",
              "background": "Vibrant atmosphere",
              "props": []
            },
            "technical": {
              "camera": "Wide shot",
              "lighting": "Bright stadium lights",
              "color_grade": "High saturation"
            },
            "full_visual_prompt": "Wide shot of a joyful montage of fans celebrating and cheering in a vibrant stadium atmosphere, high saturation and bright stadium lights.",
            "duration_frame": "1.0",
            "transition": "cut"
          }
        ],
        "scene_visuals_basics": "Group of fans celebrating together.",
        "scene_visuals_extensive": "A montage of fans enjoying the game, cheering, and engaging with the stream, creating a vibrant atmosphere.",
        "scene_audio_background": "Cheering and celebrations.",
        "success_metric": "Increased subscriptions and viewer counts."
      }
    ]
  },
  "2": {
    "video_id": "Event Organizers_2",
    "audience": "Event Organizers",
    "content_reference": "2",
    "video_base": {
      "style": "Dynamic",
      "audio_base": {
        "timbre": "Electronic beats and live sound effects",
        "tempo": "120",
        "core_rhythm": "Four on the floor",
        "harmonic_base": "C major"
      },
      "color_scheme": "Blue and White with Yellow accents",
      "duration_video": "120"
    },
    "scenes": [
      {
        "scene_number": "1",
        "scene_title": "Introduction to Live Streaming",
        "scene_type": "data",
        "duration_scene": "6",
        "purpose": "Introduce the concept of professional live streaming",
        "emotion": "subtle",
        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, low resolution, out of focus, grainy, poorly framed, unbalanced, exaggerated expressions",
        "scene_audio": {
          "narration": {
            "text_narration": "Discover the power of live streaming for your events.",
            "duration_narration": "5.4"
          },
          "background_music": {
            "style": "Upbeat",
            "intensity": "5"
          }
        },
        "frames": [
          {
            "frame_number": "1",
            "frame_type": "START",
            "primary_person": {
              "description": "Technician setting up a camera",
              "action": "Adjusting the camera angle",
              "expression": "Focused"
            },
            "environment": {
              "setting": "Event venue",
              "background": "Wide shot of the venue with equipment",
              "props": [
                "Cameras",
                "Tripods",
                "Lighting equipment"
              ]
            },
            "technical": {
              "camera": "Wide shot",
              "lighting": "Natural light",
              "color_grade": "Vibrant"
            },
            "full_visual_prompt": "Wide shot of an event venue with technicians setting up cameras and equipment, showcasing a dynamic environment.",
            "duration_frame": "2.1",
            "transition": "Fade in"
          },
          {
            "frame_number": "2",
            "frame_type": "IMPORTANT",
            "primary_person": {
              "description": "Group of technicians",
              "action": "Collaborating on setup",
              "expression": "Engaged"
            },
            "environment": {
              "setting": "Event venue",
              "background": "Close-up of technicians working together",
              "props": [
                "Microphones",
                "Cables"
              ]
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Soft key light",
              "color_grade": "Warm"
            },
            "full_visual_prompt": "Close-up of technicians collaborating on the setup of live streaming equipment, showcasing teamwork.",
            "duration_frame": "2.1",
            "transition": "Cut"
          },
          {
            "frame_number": "3",
            "frame_type": "ENDING",
            "primary_person": {
              "description": "Technician giving a thumbs up",
              "action": "Confirming readiness",
              "expression": "Satisfied"
            },
            "environment": {
              "setting": "Event venue",
              "background": "Wide shot of the fully set up area",
              "props": [
                "Monitors",
                "Streaming equipment"
              ]
            },
            "technical": {
              "camera": "Wide shot",
              "lighting": "Balanced lighting",
              "color_grade": "Bright"
            },
            "full_visual_prompt": "Wide shot of the event venue with technicians giving a thumbs up, indicating the setup is ready for live streaming.",
            "duration_frame": "1.8",
            "transition": "Fade out"
          }
        ],
        "scene_visuals_basics": "Professional setup of live streaming equipment",
        "scene_visuals_extensive": "Wide shot of an event venue with technicians setting up cameras and equipment, showcasing a dynamic environment.",
        "scene_audio_background": "Ambient sound of equipment being set up and light chatter",
        "success_metric": "Viewer engagement and awareness"
      },
      {
        "scene_number": "2",
        "scene_title": "Enhancing Viewer Experience",
        "scene_type": "explanation",
        "duration_scene": "8",
        "purpose": "Showcase customizable overlays for live streams",
        "emotion": "controlled",
        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, low resolution, out of focus, grainy, poorly framed, unbalanced, exaggerated expressions",
        "scene_audio": {
          "narration": {
            "text_narration": "Enhance viewer experience with customizable overlays.",
            "duration_narration": "7.2"
          },
          "background_music": {
            "style": "Techno",
            "intensity": "6"
          }
        },
        "frames": [
          {
            "frame_number": "1",
            "frame_type": "START",
            "primary_person": {
              "description": "Designer working on an overlay",
              "action": "Creating a new design",
              "expression": "Concentrated"
            },
            "environment": {
              "setting": "Design studio",
              "background": "Close-up of a computer screen",
              "props": [
                "Graphic design software",
                "Mouse"
              ]
            },
            "technical": {
              "camera": "Close-up shot",
              "lighting": "Studio light",
              "color_grade": "Cool"
            },
            "full_visual_prompt": "Close-up of a designer working on a customizable overlay for live streams, showcasing creativity.",
            "duration_frame": "2.4",
            "transition": "Zoom in"
          },
          {
            "frame_number": "2",
            "frame_type": "IMPORTANT",
            "primary_person": {
              "description": "Audience watching the stream",
              "action": "Reacting to the overlay",
              "expression": "Excited"
            },
            "environment": {
              "setting": "Home setting",
              "background": "Medium shot of viewers engaged with the live stream",
              "props": [
                "Laptops",
                "Tablets"
              ]
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Ambient light",
              "color_grade": "Bright"
            },
            "full_visual_prompt": "Medium shot of an audience watching the live stream with excitement, showcasing their engagement with the overlays.",
            "duration_frame": "2.4",
            "transition": "Cut"
          },
          {
            "frame_number": "3",
            "frame_type": "ENDING",
            "primary_person": {
              "description": "Overlay on screen",
              "action": "Displaying event information",
              "expression": "Informative"
            },
            "environment": {
              "setting": "On-screen display",
              "background": "Close-up of the overlay in action",
              "props": [
                "Event details",
                "Interactive elements"
              ]
            },
            "technical": {
              "camera": "Close-up shot",
              "lighting": "Backlit",
              "color_grade": "Vibrant"
            },
            "full_visual_prompt": "Close-up of a customizable overlay displaying event information, enhancing viewer experience.",
            "duration_frame": "3.2",
            "transition": "Fade out"
          }
        ],
        "scene_visuals_basics": "Close-ups of overlay designs and interactions",
        "scene_visuals_extensive": "Close-up shots of screen overlays in action, displaying event information and audience reactions, with a focus on engagement.",
        "scene_audio_background": "Sound of excitement from the audience",
        "success_metric": "Increased viewer retention"
      },
      {
        "scene_number": "3",
        "scene_title": "Seamless Scoreboard Updates",
        "scene_type": "data",
        "duration_scene": "7",
        "purpose": "Demonstrate real-time scoreboard updates",
        "emotion": "subtle",
        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, low resolution, out of focus, grainy, poorly framed, unbalanced, exaggerated expressions",
        "scene_audio": {
          "narration": {
            "text_narration": "Stay updated with seamless scoreboard integration.",
            "duration_narration": "6.3"
          },
          "background_music": {
            "style": "Motivational",
            "intensity": "4"
          }
        },
        "frames": [
          {
            "frame_number": "1",
            "frame_type": "START",
            "primary_person": {
              "description": "Technician monitoring the scoreboard",
              "action": "Adjusting settings",
              "expression": "Focused"
            },
            "environment": {
              "setting": "Event control room",
              "background": "Close-up of scoreboard display",
              "props": [
                "Control panel",
                "Monitors"
              ]
            },
            "technical": {
              "camera": "Close-up shot",
              "lighting": "Ambient light",
              "color_grade": "Neutral"
            },
            "full_visual_prompt": "Close-up of a technician monitoring the scoreboard, ensuring real-time updates during the event.",
            "duration_frame": "2.3",
            "transition": "Cut"
          },
          {
            "frame_number": "2",
            "frame_type": "IMPORTANT",
            "primary_person": {
              "description": "Scoreboard in action",
              "action": "Updating scores",
              "expression": "Dynamic"
            },
            "environment": {
              "setting": "Event venue",
              "background": "Wide shot of scoreboard",
              "props": [
                "Spectators",
                "Event details"
              ]
            },
            "technical": {
              "camera": "Wide shot",
              "lighting": "Bright venue lights",
              "color_grade": "Vibrant"
            },
            "full_visual_prompt": "Wide shot of the scoreboard updating in real-time during an event, with spectators reacting positively.",
            "duration_frame": "2.3",
            "transition": "Zoom out"
          },
          {
            "frame_number": "3",
            "frame_type": "ENDING",
            "primary_person": {
              "description": "Spectators cheering",
              "action": "Reacting to the scoreboard",
              "expression": "Joyful"
            },
            "environment": {
              "setting": "Event venue",
              "background": "Medium shot of the audience",
              "props": [
                "Flags",
                "Banners"
              ]
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Spotlights",
              "color_grade": "Bright"
            },
            "full_visual_prompt": "Medium shot of spectators cheering and reacting to the scoreboard updates, showcasing engagement.",
            "duration_frame": "2.4",
            "transition": "Fade out"
          }
        ],
        "scene_visuals_basics": "Scoreboard transitioning smoothly during a game",
        "scene_visuals_extensive": "Wide shot of a scoreboard updating in real-time during an event, with spectators reacting positively.",
        "scene_audio_background": "Cheering and commentary from the event",
        "success_metric": "Improved audience engagement"
      },
      {
        "scene_number": "4",
        "scene_title": "Attracting Sponsors",
        "scene_type": "explanation",
        "duration_scene": "8",
        "purpose": "Explain how branded content attracts sponsors",
        "emotion": "controlled",
        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, low resolution, out of focus, grainy, poorly framed, unbalanced, exaggerated expressions",
        "scene_audio": {
          "narration": {
            "text_narration": "Attract sponsors with branded content and visibility.",
            "duration_narration": "7.2"
          },
          "background_music": {
            "style": "Corporate",
            "intensity": "5"
          }
        },
        "frames": [
          {
            "frame_number": "1",
            "frame_type": "START",
            "primary_person": {
              "description": "Event manager discussing sponsorships",
              "action": "Presenting to a group",
              "expression": "Persuasive"
            },
            "environment": {
              "setting": "Meeting room",
              "background": "Medium shot of the manager and attendees",
              "props": [
                "Presentation slides",
                "Notepads"
              ]
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Soft lighting",
              "color_grade": "Warm"
            },
            "full_visual_prompt": "Medium shot of an event manager presenting to a group about the importance of sponsorships.",
            "duration_frame": "2.4",
            "transition": "Cut"
          },
          {
            "frame_number": "2",
            "frame_type": "IMPORTANT",
            "primary_person": {
              "description": "Event banners with sponsor logos",
              "action": "On display",
              "expression": "Prominent"
            },
            "environment": {
              "setting": "Event venue",
              "background": "Wide shot of banners",
              "props": [
                "Sponsor logos",
                "Event materials"
              ]
            },
            "technical": {
              "camera": "Wide shot",
              "lighting": "Bright venue lights",
              "color_grade": "Vibrant"
            },
            "full_visual_prompt": "Wide shot of event banners displaying sponsor logos, showcasing visibility and branding.",
            "duration_frame": "2.4",
            "transition": "Zoom in"
          },
          {
            "frame_number": "3",
            "frame_type": "ENDING",
            "primary_person": {
              "description": "Attendees interacting with sponsors",
              "action": "Networking",
              "expression": "Engaged"
            },
            "environment": {
              "setting": "Event venue",
              "background": "Medium shot of networking",
              "props": [
                "Business cards",
                "Promotional materials"
              ]
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Natural light",
              "color_grade": "Neutral"
            },
            "full_visual_prompt": "Medium shot of attendees interacting with sponsors, highlighting networking opportunities.",
            "duration_frame": "3.2",
            "transition": "Fade out"
          }
        ],
        "scene_visuals_basics": "Event banners displaying sponsor logos",
        "scene_visuals_extensive": "Medium shots of event banners and screens displaying sponsor logos, showing sponsors interacting with attendees.",
        "scene_audio_background": "Networking sounds and conversations",
        "success_metric": "Increased sponsorship inquiries"
      },
      {
        "scene_number": "5",
        "scene_title": "Integration with Existing Systems",
        "scene_type": "data",
        "duration_scene": "7",
        "purpose": "Highlight easy integration with existing systems",
        "emotion": "subtle",
        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, low resolution, out of focus, grainy, poorly framed, unbalanced, exaggerated expressions",
        "scene_audio": {
          "narration": {
            "text_narration": "Easily integrate with your existing systems.",
            "duration_narration": "6.3"
          },
          "background_music": {
            "style": "Relaxed",
            "intensity": "3"
          }
        },
        "frames": [
          {
            "frame_number": "1",
            "frame_type": "START",
            "primary_person": {
              "description": "Technician connecting devices",
              "action": "Plugging in cables",
              "expression": "Concentrated"
            },
            "environment": {
              "setting": "Event control room",
              "background": "Close-up of technician's hands",
              "props": [
                "Cables",
                "Devices"
              ]
            },
            "technical": {
              "camera": "Close-up shot",
              "lighting": "Soft lighting",
              "color_grade": "Neutral"
            },
            "full_visual_prompt": "Close-up of a technician connecting devices for integration with existing systems.",
            "duration_frame": "2.4",
            "transition": "Cut"
          },
          {
            "frame_number": "2",
            "frame_type": "IMPORTANT",
            "primary_person": {
              "description": "Technician interacting with software",
              "action": "Navigating through settings",
              "expression": "Focused"
            },
            "environment": {
              "setting": "Control room",
              "background": "Medium shot of the technician at the computer",
              "props": [
                "Computer",
                "Software interface"
              ]
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Natural light",
              "color_grade": "Cool"
            },
            "full_visual_prompt": "Medium shot of a technician interacting with live stream software, showcasing integration with existing systems.",
            "duration_frame": "2.4",
            "transition": "Zoom in"
          },
          {
            "frame_number": "3",
            "frame_type": "ENDING",
            "primary_person": {
              "description": "Event management dashboard",
              "action": "Displaying integrated systems",
              "expression": "Informative"
            },
            "environment": {
              "setting": "On-screen display",
              "background": "Close-up of the dashboard",
              "props": [
                "Graphs",
                "Data"
              ]
            },
            "technical": {
              "camera": "Close-up shot",
              "lighting": "Backlit",
              "color_grade": "Vibrant"
            },
            "full_visual_prompt": "Close-up of an event management dashboard displaying integrated systems, showcasing seamless functionality.",
            "duration_frame": "2.2",
            "transition": "Fade out"
          }
        ],
        "scene_visuals_basics": "Technicians connecting devices",
        "scene_visuals_extensive": "Close-up of technicians integrating live stream software with existing event management systems, showcasing smooth connectivity.",
        "scene_audio_background": "Sound of devices connecting and soft background chat",
        "success_metric": "Streamlined event management"
      },
      {
        "scene_number": "6",
        "scene_title": "Conclusion and Call-to-Action",
        "scene_type": "emotional",
        "duration_scene": "8",
        "purpose": "Wrap up the video and encourage action",
        "emotion": "high intensity",
        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, low resolution, out of focus, grainy, poorly framed, unbalanced, exaggerated expressions",
        "scene_audio": {
          "narration": {
            "text_narration": "Elevate your events with our live streaming solutions today!",
            "duration_narration": "7.2"
          },
          "background_music": {
            "style": "Epic",
            "intensity": "8"
          }
        },
        "frames": [
          {
            "frame_number": "1",
            "frame_type": "START",
            "primary_person": {
              "description": "Event host addressing the audience",
              "action": "Encouraging participation",
              "expression": "Energetic"
            },
            "environment": {
              "setting": "Event stage",
              "background": "Wide shot of the host and audience",
              "props": [
                "Microphone",
                "Stage lights"
              ]
            },
            "technical": {
              "camera": "Wide shot",
              "lighting": "Spotlights",
              "color_grade": "Bright"
            },
            "full_visual_prompt": "Wide shot of the event host addressing the audience, encouraging them to engage with the live streaming solutions.",
            "duration_frame": "2.4",
            "transition": "Fade in"
          },
          {
            "frame_number": "2",
            "frame_type": "IMPORTANT",
            "primary_person": {
              "description": "Audience cheering",
              "action": "Responding to the host",
              "expression": "Excited"
            },
            "environment": {
              "setting": "Event venue",
              "background": "Medium shot of the audience",
              "props": [
                "Flags",
                "Banners"
              ]
            },
            "technical": {
              "camera": "Medium shot",
              "lighting": "Dynamic lighting",
              "color_grade": "Vibrant"
            },
            "full_visual_prompt": "Medium shot of the audience cheering and responding enthusiastically to the host's call-to-action.",
            "duration_frame": "2.4",
            "transition": "Cut"
          },
          {
            "frame_number": "3",
            "frame_type": "ENDING",
            "primary_person": {
              "description": "Event branding on screen",
              "action": "Displaying contact information",
              "expression": "Inviting"
            },
            "environment": {
              "setting": "On-screen display",
              "background": "Close-up of branding",
              "props": [
                "Contact details",
                "Event logo"
              ]
            },
            "technical": {
              "camera": "Close-up shot",
              "lighting": "Backlit",
              "color_grade": "Polished"
            },
            "full_visual_prompt": "Close-up of event branding on screen, displaying contact information and inviting inquiries.",
            "duration_frame": "3.2",
            "transition": "Fade out"
          }
        ],
        "scene_visuals_basics": "Enthusiastic audience enjoying the event",
        "scene_visuals_extensive": "Wide shot of the audience cheering and enjoying the event, with a focus on the excitement created by the live stream.",
        "scene_audio_background": "Roaring applause and excitement",
        "success_metric": "Increased inquiries and bookings"
      }
    ]
  }
}

const SAMPLE_GROUP_IMAGES = {
  "1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/sports_fans.jpeg",
  "2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/event_organizers.jpeg"
}

const SAMPLE_SCENE_IMAGES = {
  "1_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_1_engaging_live_streaming_experience.jpeg",
  "1_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_2_customizable_overlay.jpeg",
  "1_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_3_real-time_scoreboard_integration.jpeg",
  "1_4": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_4_interactive_features_for_engagement.jpeg",
  "1_5": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_5_high-quality_visuals_and_sound.jpeg",
  "1_6": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_6_call_to_action.jpeg",
  "2_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_1_introduction_to_live_streaming.jpeg",
  "2_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_2_enhancing_viewer_experience.jpeg",
  "2_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_3_seamless_scoreboard_updates.jpeg",
  "2_4": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_4_attracting_sponsors.jpeg",
  "2_5": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_5_integration_with_existing_systems.jpeg",
  "2_6": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_6_conclusion_and_call-to-action.jpeg"
}

const SAMPLE_FRAME_IMAGES = {
  "1_1_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_1_1.jpeg",
  "1_1_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_1_2.jpeg",
  "1_1_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_1_3.jpeg",
  "1_2_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_2_1.jpeg",
  "1_2_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_2_2.jpeg",
  "1_2_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_2_3.jpeg",
  "1_3_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_3_1.jpeg",
  "1_3_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_3_2.jpeg",
  "1_3_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_3_3.jpeg",
  "1_4_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_4_1.jpeg",
  "1_4_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_4_2.jpeg",
  "1_4_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_4_3.jpeg",
  "1_5_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_5_1.jpeg",
  "1_5_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_5_2.jpeg",
  "1_5_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_5_3.jpeg",
  "1_6_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_6_1.jpeg",
  "1_6_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_6_2.jpeg",
  "1_6_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_6_3.jpeg",
  "2_1_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_1_1.jpeg",
  "2_1_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_1_2.jpeg",
  "2_1_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_1_3.jpeg",
  "2_2_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_2_1.jpeg",
  "2_2_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_2_2.jpeg",
  "2_2_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_2_3.jpeg",
  "2_3_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_3_1.jpeg",
  "2_3_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_3_2.jpeg",
  "2_3_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_3_3.jpeg",
  "2_4_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_4_1.jpeg",
  "2_4_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_4_2.jpeg",
  "2_4_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_4_3.jpeg",
  "2_5_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_5_1.jpeg",
  "2_5_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_5_2.jpeg",
  "2_5_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_5_3.jpeg",
  "2_6_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_6_1.jpeg",
  "2_6_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_6_2.jpeg",
  "2_6_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_6_3.jpeg"
}


const Glow03ProcessorScript = ({ data, onComplete, sessionId }) => {
  
  const process = processConfig.processes.find(p => p.id === 'glow_03');

  // Data comes in directly, not wrapped
  const marketData = Object.entries(data).length;
  const hasRealData = Object.entries(data).length ? true : false ;  // Check for markets array
  const displayData = hasRealData ? data : SAMPLE_DATA;
  const isDemo = !hasRealData;

  const [isLoading, setIsLoading] = useState(false);
  const [processedData, setProcessedData] = useState(data);

  const [expandedGroups, setExpandedGroups] = useState({});
  const [expandedScenes, setExpandedScenes] = useState({});
  const [expandedFrames, setExpandedFrames] = useState({});

  const [hoverScene, setHoverScene] = useState()
  const [hoverFrame, setHoverFrame] = useState()
  const [hoverRelatedNode, setHoverRelatedNode] = useState()
  const [selectedScene, setSelectedScene] = useState()
  const [selectedFrame, setSelectedFrame] = useState()

  const [groupImages, setGroupImages] = useState({});
  const [loadingGroupImages, setLoadingGroupImages] = useState({});
  const [frameImages, setFrameImages] = useState({});
  const [loadingFrameImages, setLoadingFrameImages] = useState({});
  const [sceneImages, setSceneImages] = useState({});
  const [loadingSceneImages, setLoadingSceneImages] = useState({});

  React.useEffect(() => {
    if (displayData && hasRealData) {
      Object.entries(displayData).forEach(([groupId, group]) => {
        if (group.audience && !groupImages[groupId]) {
          generateImageForGroup(group, groupId);
        }
        
        group.scenes?.forEach(scene => {
          const sceneKey = `${groupId}_${scene.scene_number}`;
          if (scene.scene_visuals_extensive && !sceneImages[sceneKey]) {
            generateImageForScene(scene, sceneKey);
          }
          scene.frames?.forEach(frame => {
            const frameKey = `${groupId}_${scene.scene_number}_${frame.frame_number}`;
            if (frame.full_visual_prompt && !frameImages[frameKey]) {
              generateImageForFrame(frame, frameKey);
            }
          });
        });
      });
    } else {
      setGroupImages(SAMPLE_GROUP_IMAGES)
      setSceneImages(SAMPLE_SCENE_IMAGES)
      setFrameImages(SAMPLE_FRAME_IMAGES)
    }
  }, [displayData]);

  const generateImageForGroup = async (group, groupId) => {
    if (!group.audience || groupImages[groupId]) return;
    
    setLoadingGroupImages(prev => ({ ...prev, [groupId]: true }));
    const targetGroupName = group.audience.toLowerCase().replace(/\s+/g, '_');
    try {
      const imagePaths = await generateAndSaveTargetGroupImage(
        `Professional video scene for ${group.audience} with ${group.video_base.style} style and ${group.video_base.color_scheme} color scheme`,
        `${sessionId}/${targetGroupName}`
      );
      
      setGroupImages(prev => ({
        ...prev,
        [groupId]: imagePaths.s3Path
      }));
    } catch (error) {
      console.error('Failed to generate group image:', error);
    } finally {
      setLoadingGroupImages(prev => ({ ...prev, [groupId]: false }));
    }
  };

  const generateImageForFrame = async (frame, frameKey) => {
    if (!frame.full_visual_prompt || frameImages[frameKey]) return;
    
    setLoadingFrameImages(prev => ({ ...prev, [frameKey]: true }));
    try {
      const imagePaths = await generateAndSaveTargetGroupImage(
        frame.full_visual_prompt,
        `${sessionId}/frame_${frameKey}`
      );
      
      setFrameImages(prev => ({
        ...prev,
        [frameKey]: imagePaths.s3Path
      }));
    } catch (error) {
      console.error('Failed to generate frame image:', error);
    } finally {
      setLoadingFrameImages(prev => ({ ...prev, [frameKey]: false }));
    }
  };

  const generateImageForScene = async (scene, sceneKey) => {
    const sceneName = `scene_${scene.scene_number}_${scene.scene_title.toLowerCase().replace(/\s+/g, '_')}`;
    
    if (!scene.scene_visuals_extensive || sceneImages[sceneKey]) return;
    
    setLoadingSceneImages(prev => ({ ...prev, [sceneKey]: true }));
    try {
      const imagePaths = await generateAndSaveTargetGroupImage(
        scene.scene_visuals_extensive,
        `${sessionId}/${sceneName}`
      );
      setSceneImages(prev => ({
        ...prev,
        [sceneKey]: imagePaths
      }));
    } catch (error) {
      console.error('Failed to generate scene image:', error);
    } finally {
      setLoadingSceneImages(prev => ({ ...prev, [sceneKey]: false }));
    }
  };

  const toggleGroup = (groupId) => {
    setExpandedGroups(prev => ({
      ...prev,
      [groupId]: !prev[groupId]
    }));
  };

  const toggleScene = (sceneId, sceneNumber) => {
    setExpandedScenes(prev => ({
      ...prev,
      [sceneId]: !prev[sceneId]
    }));
    if(selectedScene === sceneNumber) setSelectedScene()
    else setSelectedScene(sceneNumber);
  };

  const toggleFrame = (frameId, frameNumber) => {
    setExpandedFrames(prev => ({
      ...prev,
      [frameId]: !prev[frameId]
    }));
    if(selectedScene && selectedFrame === frameNumber) setSelectedFrame()
    else setSelectedFrame(frameNumber);
  };

  const selectedWidth = 50
  const renderFrame = (frame, groupId, scene, selectedSceneData) => {
    const frameKey = `${groupId}_${scene?.scene_number}_${frame.frame_number}`;
    const sceneKey = `${groupId}_${scene.scene_number}`;
    // const isExpanded = expandedFrames[frameKey];
    const isSelected = frame.frame_number === selectedFrame;
    const sceneWidth = (scene?.frames?.length / (frameCount - selectedSceneData?.frames?.length)) * selectedWidth
    const selectedFrameData = (selectedFrame && !!selectedSceneData) ? selectedSceneData.frames?.find(frame => frame.frame_number === selectedFrame) : {};

    return (
      <div className='frame-wrapper' 
        style={{ 
          width: !selectedFrame ? (selectedSceneData?.scene_number ? 
          (selectedSceneData?.scene_number === scene?.scene_number ? 
            `${(1/scene?.frames?.length) * selectedWidth}%` : 
            `${(1/scene?.frames?.length) * sceneWidth}%`) : 
            `100%`) : 
            selectedScene && selectedScene === scene.scene_number && selectedFrame === frame.frame_number && selectedFrameData ? 
            `calc(${selectedWidth}%)` : 
            `calc(1/${frameCount - 1} * ${selectedWidth}%)`
          }}>
        <div key={frameKey} className={`frame-card${selectedScene && selectedScene === scene.scene_number && selectedFrame === frame.frame_number && selectedFrameData ? (isSelected ? ' selected' : ' ') : ''} ${(!selectedScene && hoverScene === scene.scene_number) || (!selectedScene && hoverFrame === groupId) ? ' hover' : ''}`}>
            <div className="frame-image">
              {loadingFrameImages[frameKey] ? (
                <CircularProgress size={24} />
              ) : frameImages[frameKey] ? (
                <img 
                  src={frameImages[frameKey]} 
                  alt={`Visual for Frame ${frame.frame_number}`}
                  onClick={() => {
                    if (selectedScene !== scene.scene_number) {
                      setSelectedScene()
                      toggleScene(sceneKey, scene.scene_number); 
                    }
                    toggleFrame(frameKey, frame.frame_number);
                  }}
                  onMouseEnter={() => { setHoverRelatedNode({frame: groupId, scene: scene.scene_number}) }}
                  onMouseLeave={() => { setHoverRelatedNode() }}
                />
              ) : (
                <Button 
                  onClick={(e) => {
                    e.stopPropagation();
                    generateImageForFrame(frame, frameKey);
                  }}
                  variant="outlined"
                  size="small"
                >
                  Generate Frame Visual
                </Button>
              )}
            </div>
        </div>
        {selectedScene && selectedScene === scene.scene_number && selectedFrame === frame.frame_number && selectedFrameData && renderFrameDetail(selectedFrameData)}
      </div>
    );
  };

  const renderFrameDetail = (frameData) => {
    return (
      <div className="frame-details">
        {frameData.primary_person && (
          <div className="frame-item">
            <strong>Primary Person:</strong>
            <p>Description: {frameData.primary_person.description}</p>
            <p>Action: {frameData.primary_person.action}</p>
            <p>Expression: {frameData.primary_person.expression}</p>
          </div>
        )}
        
        {frameData.environment && (
          <div className="frame-item">
            <strong>Environment:</strong>
            <p>Setting: {frameData.environment.setting}</p>
            <p>Background: {frameData.environment.background}</p>
            <p>Props: {frameData.environment.props.join(', ')}</p>
          </div>
        )}
        
        {frameData.technical && (
          <div className="frame-item">
            <strong>Technical:</strong>
            <p>Camera: {frameData.technical.camera}</p>
            <p>Lighting: {frameData.technical.lighting}</p>
            {frameData.technical.color_grade && (
              <p>Color Grade: {frameData.technical.color_grade}</p>
            )}
          </div>
        )}
        
        {frameData.audio && (
          <div className="frame-item">
            <strong>Audio:</strong>
            <p>Narration: {frameData.audio.narration || 'None'}</p>
            <p>Music: {frameData.audio.music}</p>
          </div>
        )}
        
        <div className="frame-details">
          <div className="frame-item">
            <strong>Visual Prompt:</strong>
            <p>{frameData.full_visual_prompt}</p>
          </div>
          <p><strong>Duration:</strong> {frameData.duration}</p>
          <p><strong>Transition:</strong> {frameData.transition}</p>
        </div>
      </div>
    )
  }
  
  const renderScene = (sceneData, groupId, selectedSceneData) => {
    const sceneKey = `${groupId}_${sceneData.scene_number}`;
    // const isExpanded = expandedScenes[sceneKey];
    const isSelected = sceneData.scene_number === selectedScene;
    const otherWidth = (((sceneData?.frames?.length / (frameCount - selectedSceneData?.frames?.length)) * selectedWidth) + '%')
    
    return (
      <div className='scene-wrapper' 
        style={{ width: `${selectedScene ? (isSelected ? (selectedWidth + '%') : otherWidth) : (''+ ((sceneData?.frames?.length / frameCount) * 100) + '%')}` }}>
        <div key={sceneKey} className={`scene-card${selectedScene ? isSelected ? ' selected' : '' : ''} ${(!selectedScene && hoverFrame === groupId) || (!selectedScene && hoverRelatedNode?.scene === sceneData.scene_number) ? 'hover' : ''}`}>
          <div className="scene-image">
            {loadingSceneImages[sceneKey] ? (
              <CircularProgress size={24} />
            ) : sceneImages[sceneKey] ? (
              <img 
                src={sceneImages[sceneKey]} 
                alt={`Visual for Scene ${sceneData.scene_number}`}
                onClick={() => {
                  toggleScene(sceneKey, sceneData.scene_number)
                  setSelectedFrame()
                }}
                onMouseEnter={() => { setHoverScene(sceneData.scene_number); setHoverRelatedNode({frame: groupId}) }}
                onMouseLeave={() => { setHoverScene(); setHoverRelatedNode() }}
              />
            ) : (
              <Button 
                onClick={() => generateImageForScene(sceneData, sceneKey)}
                variant="outlined"
                size="small"
              >
                Generate Scene Visual
              </Button>
            )}
          </div>
        </div>
        {selectedScene && selectedScene === sceneData.scene_number && renderSceneDetail(sceneData)}
      </div>
    );
  };

  const renderSceneDetail = (sceneData) => {
    return (
      <div className="scene-details">
        <p><strong>Scene Length:</strong> {sceneData.scene_length}</p>
        <p><strong>Purpose:</strong> {sceneData.purpose}</p>
        <p><strong>Emotion:</strong> {sceneData.emotion}</p>
        <div className="scene-item">
          <strong>Visual Basics:</strong>
          <p>{sceneData.scene_visuals_basics}</p>
        </div>
        <div className="scene-item">
          <strong>Visual Details:</strong>
          <p>{sceneData.scene_visuals_extensive}</p>
        </div>
        <div className="scene-item">
          <strong>Background Audio:</strong>
          <p>{sceneData.scene_audio_background}</p>
        </div>
        <div className="scene-item">
          <strong>Success Metric:</strong>
          <p>{sceneData.success_metric}</p>
        </div>
      </div>
    )
  }

  let frameCount = 0

  const renderTargetGroup = (groupData, groupId) => {
    frameCount = 0
    // const isExpanded = expandedGroups[groupId];
    const selectedSceneData = selectedScene ? groupData.scenes.find(scene => scene.scene_number === selectedScene) : {};
    groupData.scenes?.forEach((sceneItem) => {
      sceneItem?.frames?.forEach((frameItem) => frameCount += 1)
    })

    return (
      <div key={`group-${groupId}`} className="target-group glow_03">
        <div className={`group-content ${(!selectedScene && hoverFrame === groupId) || (!selectedScene && hoverRelatedNode?.frame === groupId) ? 'hover' : ''}`}
              onMouseEnter={() => { setHoverFrame(groupId) }}
              onMouseLeave={() => { setHoverFrame()}}>
          <div className='group-card'>
            <div className="group-image" onClick={() => toggleGroup(groupId)}>
              {loadingGroupImages[groupId] ? (
                <CircularProgress size={24} />
              ) : groupImages[groupId] ? (
                <img 
                  src={groupImages[groupId]} 
                  alt={`Visual for ${groupData.audience}`}
                  onClick={() => toggleGroup(groupId)}
                />
              ) : (
                <Button 
                  onClick={() => generateImageForGroup(groupData, groupId)}
                  variant="outlined"
                  size="small"
                >
                  Generate Visual
                </Button>
              )}
            </div>
          </div>

          <div className="group-detail">
            <h4>Video Base Information</h4>
            <p><strong>Style:</strong> {groupData.video_base.style}</p>
            <p><strong>Duration:</strong> {groupData.video_base.target_duration_in_sec} seconds</p>
            <p><strong>Color Scheme:</strong> {groupData.video_base.color_scheme}</p>
          </div>
          
          <div className="group-detail">
            <h4>Audio Base</h4>
            <p><strong>Timbre:</strong> {groupData.video_base.audio_base.timbre}</p>
            <p><strong>Tempo:</strong> {groupData.video_base.audio_base.tempo}</p>
            <p><strong>Core Rhythm:</strong> {groupData.video_base.audio_base.core_rhythm}</p>
            <p><strong>Harmonic Base:</strong> {groupData.video_base.audio_base.harmonic_base}</p>
          </div>
        </div>
        
        {true && (
          <div className="scene-content">
            {groupData.scenes.map(scene => renderScene(scene, groupId, selectedSceneData))}
          </div>
        )}
        
        {true && (
          <div className="frame-content">
            {groupData.scenes.map(scene => scene.frames?.map(frame => renderFrame(frame, groupId, scene, selectedSceneData)))}
          </div>
        )}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="process-viewer">
        <Box>
          <ViewerHeader 
            title={process.info.title}
            subtitle={process.info.description}
            processId="glow_03"
            sessionId={sessionId}
          />
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            minHeight: '200px' 
          }}>
            <CircularProgress />
          </Box>
        </Box>
      </div>
    );
  }

  if (!displayData) {
    return <div>No data available</div>;
  }

  return (
    <div className="process-viewer">
      <Box>
        {isDemo && (
          <Box sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) rotate(-45deg)',
            color: 'rgba(255, 140, 0, 0.45)',
            fontSize: '48px',
            fontWeight: 'bold',
            pointerEvents: 'none',
            zIndex: 9999,
            width: '100%',
            textAlign: 'center',
            userSelect: 'none',
            textTransform: 'uppercase'
          }}>
            Sample (Glowtest)
          </Box>
        )}
        <ViewerHeader 
          title={process.info.title}
          subtitle={process.info.description}
          processId="glow_03"
          sessionId={sessionId}
        />
        {displayData && 
          Object.entries(displayData).map(([groupId, group]) => 
            renderTargetGroup(group, groupId)
          )}
        <ViewFooter
          isDisabled={!sessionId} 
          onComplete={onComplete}
          buttonText={process.footer.continueText}
        />
      </Box>
    </div>
  );
};

export default Glow03ProcessorScript;