import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from './App';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
// import { ThemeProvider } from './ThemeContext';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeProvider theme = {theme} defaultMode="dark">
      <Provider store={store}>
          <App />
      </Provider>
   </ThemeProvider>
);