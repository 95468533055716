import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Stack,
  styled,
  createTheme,
  ThemeProvider
} from '@mui/material';
import processConfig from '../../config/processIndex.json';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import {
  LightbulbOutlined,
  ArrowForward,
  People,
  CheckCircleOutline,
  Search,
  Code,
  Build,
  TrendingUp
} from '@mui/icons-material';

// Create theme with your corporate orange
const theme = createTheme({
  palette: {
    primary: {
      main: '#FD9800',
      light: '#FDB544',
      dark: '#D68100',
      contrastText: '#FFFFFF',
    },
    grey: {
      200: '#EFEFEF'
    }
  }
});

const ProgressBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '4px',
  marginBottom: theme.spacing(2),
  '& .bar': {
    flex: 1,
    height: '4px',
    backgroundColor: theme.palette.grey[200],
    borderRadius: '2px',
    transition: 'all 0.3s ease',
  },
  '& .active': {
    backgroundColor: theme.palette.primary.main,
  }
}));

const OptionButton = styled(Button)(({ theme, active }) => ({
  flex: 1,
  flexDirection: 'column',
  padding: theme.spacing(2),
  backgroundColor: active ? theme.palette.primary.main : theme.palette.background.paper,
  color: active ? theme.palette.primary.contrastText : theme.palette.text.primary,
  border: `1px solid ${active ? theme.palette.primary.main : theme.palette.divider}`,
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: active ? theme.palette.primary.dark : 'rgba(253, 152, 0, 0.08)',
    transform: 'translateY(-2px)',
  },
  '& .MuiSvgIcon-root': {
    marginBottom: theme.spacing(1),
    fontSize: '28px',
    transition: 'transform 0.2s ease',
  },
  '& .sublabel': {
    fontSize: '0.75rem',
    color: active ? theme.palette.primary.contrastText : theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
  '&:hover .MuiSvgIcon-root': {
    transform: 'scale(1.1)',
  }
}));

const StageAssessment = ({data, onComplete, sessionId }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_08');
  
  
  const questions = [
    {
      id: 'stage',
      question: "What best describes your current stage?",
      options: [
        { value: 'idea_only', label: "Just an idea", icon: LightbulbOutlined },
        { value: 'early_prototype', label: "Prototype/MVP", icon: Code },
        { value: 'working_product', label: "Working product", icon: Build },
        { value: 'revenue_generating', label: "Paying customers", icon: TrendingUp }
      ]
    },
    {
      id: 'validation',
      question: "Have you validated this with potential users?",
      options: [
        { value: 'no_validation', label: "Not yet", icon: Search },
        { value: 'informal_talks', label: "Informal talks", icon: People },
        { value: 'structured_research', label: "Structured research", icon: CheckCircleOutline },
        { value: 'paying_customers', label: "Paying customers", icon: TrendingUp }
      ]
    },
    {
      id: 'team_expertise',
      question: "What's your team's expertise in this specific field?",
      options: [
        { value: 'learning', label: "Learning the space", subLabel: "New to industry", icon: Search },
        { value: 'some_experience', label: "Some experience", subLabel: "1-3 years relevant", icon: Build },
        { value: 'deep_knowledge', label: "Deep knowledge", subLabel: "3-7 years relevant", icon: CheckCircleOutline },
        { value: 'industry_expert', label: "Industry expert", subLabel: "7+ years relevant", icon: TrendingUp }
      ]
    },
    {
      id: 'team',
      question: "What's your current team situation?",
      options: [
        { value: 'solo_founder', label: "Just me", icon: People },
        { value: 'multiple_cofounders', label: "Co-founders", icon: People },
        { value: 'small_team_2_5', label: "Small team", icon: People },
        { value: 'established_team_6_plus', label: "Established", icon: Build }
      ]
    },
    {
      id: 'budget',
      question: "What is your budget for this project?",
      options: [
        { value: 'budget_under_10k', label: "Under $10K", subLabel: "MVP Budget", icon: LightbulbOutlined },
        { value: 'budget_10k_50k', label: "$10K-$50K", subLabel: "Basic Product", icon: Code },
        { value: 'budget_50k_100k', label: "$50K-$100K", subLabel: "Full Product", icon: Build },
        { value: 'budget_over_100k', label: "Over $100K", subLabel: "Enterprise Scale", icon: TrendingUp }
      ]
    },
    {
      id: 'goal',
      question: "What's your primary goal right now?",
      options: [
        { value: 'goal_validate_idea', label: "Validate idea", icon: Search },
        { value: 'goal_build_product', label: "Build product", icon: Build },
        { value: 'goal_acquire_users', label: "Get users", icon: People },
        { value: 'goal_scale_business', label: "Scale business", icon: TrendingUp }
      ]
    }
  ];
  
  const getAnswers = () => {
    if (typeof data === 'object') {
      return questions.reduce((acc, question) => {
        if (data[question.id]) {
          acc[question.id] = data[question.id];
        }
        return acc;
      }, {});
    }
    return {};
  }

  const [answers, setAnswers] = useState(getAnswers());

  const handleSelect = (questionId, value) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: value
    }));
  };

  const getCompletionStatus = () => {
    return Object.keys(answers).length === questions.length;
  };

  return (
    <ThemeProvider theme={theme}>
    <ViewerHeader 
      title={process.info.title}
      subtitle={process.info.description}
      processId="glow_09"
    />
      <Box sx={{ 
          p: 3, 
          backgroundColor: 'var(--color-surface)',
          }}>
        <Stack spacing={3} sx={{ maxWidth: 900, mx: 'auto'}}>
          {questions.map((q) => (
            <Card key={q.id} elevation={0} sx={{ borderRadius: 2,
              backgroundColor: 'var(--card-bg)' }}>
              <CardHeader 
                title={q.question} 
                sx={{ 
                  '& .MuiCardHeader-title': {
                    fontSize: '1.1rem',
                    fontWeight: 500
                  },
                  color: 'var(--color-text)'
                }}
              />
              <CardContent>
                <ProgressBar>
                  {q.options.map((_, index) => (
                    <Box
                      key={index}
                      className={`bar ${index <= (answers[q.id] ? q.options.findIndex(opt => opt.value === answers[q.id]) : -1) ? 'active' : ''}`}
                    />
                  ))}
                </ProgressBar>
                <Stack
                  direction="row"
                  spacing={1}
                >
                  {q.options.map((option) => {
                    const Icon = option.icon;
                    return (
                      <OptionButton
                        key={option.value}
                        active={answers[q.id] === option.value ? 'active' : ''}
                        onClick={() => handleSelect(q.id, option.value)}
                        variant="outlined"
                      >
                        <Icon />
                        <Typography variant="button">{option.label}</Typography>
                        {option.subLabel && (
                          <Typography className="sublabel">
                            {option.subLabel}
                          </Typography>
                        )}
                      </OptionButton>
                    );
                  })}
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
        <ViewFooter
          isDisabled={!sessionId} 
          showButton={getCompletionStatus()}
          onComplete={() => onComplete({processId: 'glow_08', data: {status: true, glow_08_response: answers}})}
          buttonText={process.footer.continueText}
        />
      </Box>
    </ThemeProvider>
  );
};

export default StageAssessment;
