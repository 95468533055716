import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { TextField, Box, Button, useTheme, Card, CardContent, Typography, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { FaPlay, FaChartLine, FaExclamationTriangle, FaBuilding, FaPlus } from 'react-icons/fa';
import ViewerHeader from './common/ViewerHeader';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCompanyList, companySelected, createCompany, selectAllCompanies, selectCompaniesStatus, selectSelectedCompany} from './company/companySlide';

// Import the same theme configuration
const getCustomTheme = (isDarkMode) => createTheme({
  palette: {
    mode: isDarkMode ? 'dark' : 'light',
    primary: {
      main: '#FD9800',
      light: 'rgba(253, 152, 0, 0.12)',
    },
    background: {
      paper: isDarkMode ? '#1C1C1E' : '#FFFFFF',
      default: isDarkMode ? '#000000' : '#F5F5F5',
    },
    text: {
      primary: isDarkMode ? '#FFFFFF' : '#1C1C1E',
      secondary: isDarkMode ? '#8E8E93' : '#6C757D',
    },
    divider: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
  },
});

const IdeaInputCard = ({ 
  inputText, 
  setInputText, 
  hasUnsavedChanges,
  setHasUnsavedChanges,
  onRun,
  config,
  isDarkTheme,
  sessionData = {}
}) => {
    
  const theme = useTheme();
  const [isNewCompanyDialogOpen, setIsNewCompanyDialogOpen] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState('');
  const dispatch = useDispatch()  
  const selectedCompany = useSelector(selectSelectedCompany) || ''
  const companyStatus = useSelector(selectCompaniesStatus)
  const companies = useSelector(selectAllCompanies)
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if(companyStatus === 'success' && newCompanyName) {
      const company = companies.find(company => company.company_name === newCompanyName);
      if (company) {
      dispatch(companySelected(company.company_id))
        setNewCompanyName('');
        setIsNewCompanyDialogOpen(false);
        setHasUnsavedChanges(true);
      }
    }
  }, [companyStatus, companies, newCompanyName])

  const handleCompanyChange = (event) => {
    if (event.target.value === 'add_new') {
      setIsNewCompanyDialogOpen(true);
      setIsSubmitting(false);
    } else {
      dispatch(companySelected(event.target.value))
      setHasUnsavedChanges(true);
    }
  };

  const handleAddNewCompany = async () => {
    if (newCompanyName.trim() && !isSubmitting) {
      setIsSubmitting(true);
      try {
        await dispatch(createCompany({company_name: newCompanyName.trim()}));
        // Don't clear the newCompanyName here, we'll do it after successful creation in useEffect
      } catch (error) {
        console.error('Error creating company:', error);
        setIsSubmitting(false);
      }
    }
  };

  const isFormValid = () => {
    return inputText.trim() && selectedCompany;
  };

  const handleValidateIdea = () => {
    onRun('glow_08'); // Normal flow
  };

  const handleSkipToVideo = () => {
    onRun('glow_61'); // Skip to video flow
  };

  return (
    <Box className="process-view-container" sx={{ maxWidth: '800px', margin: '0 auto' }}>
      <ViewerHeader 
        title={config?.info?.title}
        subtitle={config?.info?.description}
        processId="glow_01"
      />

      <TextField
        fullWidth
        multiline
        rows={3}
        variant="outlined"
        placeholder={config.info.placeholderText}
        value={inputText}
        onChange={(e) => {
          setInputText(e.target.value);
          setHasUnsavedChanges(true);
        }}
        sx={{
          mb: 2,
          '& .MuiOutlinedInput-root': {
            borderRadius: 2,
          }
        }}
      />
      
      <FormControl 
        fullWidth 
        variant="outlined" 
        sx={{ mb: 2 }}
      >
        <InputLabel>Allocate Idea under Company/Project </InputLabel>
        <Select
          value={selectedCompany}
          onChange={handleCompanyChange}
          label="Allocate Idea under Company/Project"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {companies.map(company => (
            <MenuItem key={company.company_id} value={company.company_id}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <FaBuilding />
                <Typography>{company.company_name}</Typography>
              </Box>
            </MenuItem>
          ))}
          <MenuItem value="add_new">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
              <FaPlus />
              <Typography>Add New Company/Project</Typography>
            </Box>
          </MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        mt: 3,
        mb: 4,
        position: 'relative'
      }}>
        <Box sx={{ 
          display: 'flex', 
          gap: 2,
          width: '100%',
          justifyContent: 'center'
        }}>
          <Button
            id="start-analysis-button"
            variant="contained"
            size="large"
            startIcon={<FaPlay />}
            onClick={handleValidateIdea}
            disabled={!isFormValid()}
            sx={{
              minWidth: '250px',
              py: 1.5,
              fontSize: '1.1rem',
              fontWeight: 600,
              bgcolor: '#FD9800',
              boxShadow: '0 4px 12px rgba(253, 152, 0, 0.4)',
              '&:hover': {
                bgcolor: '#FD9800',
                transform: 'translateY(-2px)',
                boxShadow: '0 6px 16px rgba(253, 152, 0, 0.6)',
              },
              transition: 'all 0.3s ease',
              '&.Mui-disabled': {
                bgcolor: 'action.disabledBackground',
              },
              '&.highlight': {
                animation: 'pulse 1.5s infinite'
              }
            }}
          >
            Validate Your Idea First
          </Button>

          <Button
            id="skip-to-video-button"
            variant="outlined"
            size="large"
            onClick={handleSkipToVideo}
            disabled={!isFormValid()}
            sx={{
              minWidth: '250px',
              py: 1.5,
              fontSize: '1.1rem',
              fontWeight: 600,
              color: '#FD9800',
              borderColor: '#FD9800',
              '&:hover': {
                borderColor: '#FD9800',
                bgcolor: 'rgba(253, 152, 0, 0.04)',
                transform: 'translateY(-2px)',
              },
              transition: 'all 0.3s ease',
              '&.Mui-disabled': {
                borderColor: 'action.disabledBackground',
              }
            }}
          >
            Jump directly to Express Video
          </Button>
        </Box>
        <Typography variant="body2" sx={{ 
          color: 'success.main', 
          mt: 1,
          fontWeight: 500
        }}>
          Join the 87% who succeeded with proper validation
        </Typography>
      </Box>

      <Dialog 
        open={isNewCompanyDialogOpen} 
        onClose={() => {
          setIsNewCompanyDialogOpen(false);
          setIsSubmitting(false);
          setNewCompanyName('');
        }}
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: '100%',
            maxWidth: '500px'
          }
        }}
      >
        <DialogTitle>Add New Company/Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Company/Project Name"
            fullWidth
            value={newCompanyName}
            onChange={(e) => setNewCompanyName(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={() => setIsNewCompanyDialogOpen(false)}
            color="inherit"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleAddNewCompany}
            variant="contained"
            disabled={!newCompanyName.trim() || isSubmitting}
          >
            {isSubmitting ? 'Adding...' : 'Add Company'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default IdeaInputCard;