import React from 'react';
import ViewerHeader from '../common/ViewerHeader';
import BusinessModelViewer from './glow_09_viewer_overview';
import processConfig from '../../config/processIndex.json';
import { Box, Typography } from '@mui/material';
import ViewFooter from '../common/ViewFooter';

const Glow09Processor = ({ data, onComplete, sessionId }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_09');
  return (
    <Box>
      <ViewerHeader 
        title={process.info.title}
        subtitle={process.info.description}
        processId="glow_09"
        sessionId={sessionId}
      />
      <BusinessModelViewer data={data?.glow_09_response || data || {}} />
      <ViewFooter
        isDisabled={!sessionId} 
        onComplete={onComplete}
        buttonText={process.footer.continueText}
      />
    </Box>
  );
};

export default Glow09Processor;