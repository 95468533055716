import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  TextField,
  IconButton,
  Grid,
  Chip,
  styled,
  ThemeProvider
} from '@mui/material';
import {
  Search as SearchIcon,
  ExpandMore as ChevronDownIcon,
  ChevronRight as ChevronRightIcon,
  Check as CheckIcon,
  AccessTime as ClockIcon,
  Lock as LockIcon,
  MovieCreation as EntertainmentIcon,
  School as EducationIcon,
  Campaign as MarketingIcon,
  Person as PersonalIcon,
  Business as ProfessionalIcon,
  Article as JournalismIcon
} from '@mui/icons-material';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import processConfig from '../../config/processIndex.json';

// Styled components to replace Tailwind classes
const VideoTypeButton = styled(Button)(({ theme, active }) => ({
  width: '100%',
  height: '100%',
  justifyContent: 'flex-start',
  padding: theme.spacing(2),
  backgroundColor: active ? '#FD9800' : 'var(--color-background)',
  color: active ? theme.palette.primary.contrastText : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: active ? '#E68A00' : theme.palette.action.hover,
  },
  '& .duration': {
    fontSize: '0.75rem',
    color: active ? theme.palette.primary.contrastText : theme.palette.text.secondary,
  }
}));

const CategoryRow = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: theme.palette.background,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const CATEGORY_ICONS = {
  ent: EntertainmentIcon,
  edu: EducationIcon,
  mkt: MarketingIcon,
  per: PersonalIcon,
  pro: ProfessionalIcon,
  jou: JournalismIcon
};

const isCategoryComingSoon = (category) => {
  return Object.values(category.types).every(type => type.status === 1);
};

const Glow62Processor = ({ data, onComplete, sessionId }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_62');
  
  const [searchTerm, setSearchTerm] = useState('');
  const DEFAULT_TYPE = {
    id: 'pv',
    categoryId: 'mkt',
    name: "Product Validation",
    dur: "2m",
    status: 2
  };

  // Initialize with default or saved selection
  const [selectedType, setSelectedType] = useState(() => {
    return data?.selectedType || DEFAULT_TYPE;
  });

  // Initialize expanded categories
  const [expandedCategories, setExpandedCategories] = useState(() => {
    const categoryId = data?.selectedType?.categoryId || DEFAULT_TYPE.categoryId;
    return {
      [categoryId]: true
    };
  });

  // VIDEO_INDEX moved to processConfig or separate config file
  const VIDEO_INDEX = {
    "header": {
      "version": "1.0",
      "status_codes": {
        "0": "not_available",
        "1": "coming_soon",
        "2": "available"
      }
    },
    "categories": {
      "ent": {
        "name": "Entertainment",
        "types": {
          "se": { "name": "Story Explanation", "abbr": "SE", "dur": "5m", "status": 1 },
          "ca": { "name": "Character Analysis", "abbr": "CA", "dur": "4m", "status": 1 },
          "pl": { "name": "Plot Summary", "abbr": "PL", "dur": "3m", "status": 2 },
          "tx": { "name": "Theme Exploration", "abbr": "TX", "dur": "5m", "status": 1 },
          "ls": { "name": "Live Shopping", "abbr": "LS", "dur": "15m", "status": 1 },
          "pd": { "name": "Podcast Video", "abbr": "PD", "dur": "30m", "status": 1 },
          "tv": { "name": "Travel Showcase", "abbr": "TV", "dur": "5m", "status": 2 },
          "hw": { "name": "Holiday Wishes", "abbr": "HW", "dur": "1m", "status": 2 },
          "ck": { "name": "Children's Book", "abbr": "CK", "dur": "5m", "status": 2 },
          "cs": { "name": "Children's Story", "abbr": "CS", "dur": "5m", "status": 2 },
          "bt": { "name": "Bedtime Story", "abbr": "BT", "dur": "7m", "status": 2 },
          "is": { "name": "Interactive Story", "abbr": "IS", "dur": "5m", "status": 1 },
          "ff": { "name": "Feature Film", "abbr": "FF", "dur": "120m", "status": 1 }
        }
      },
      "edu": {
        "name": "Educational",
        "types": {
          "st": { "name": "Software Tutorial", "abbr": "ST", "dur": "5m", "status": 2 },
          "cb": { "name": "Concept Breakdown", "abbr": "CB", "dur": "3m", "status": 2 },
          "ht": { "name": "How-to Guide", "abbr": "HT", "dur": "5m", "status": 2 },
          "te": { "name": "Theory Explanation", "abbr": "TE", "dur": "5m", "status": 2 },
          "ev": { "name": "Explainer Video", "abbr": "EV", "dur": "3m", "status": 2 },
          "bs": { "name": "Book Summary", "abbr": "BS", "dur": "5m", "status": 2 },
          "ea": { "name": "Educational Animation", "abbr": "EA", "dur": "3m", "status": 2 },
          "ql": { "name": "Quick Learning", "abbr": "QL", "dur": "2m", "status": 2 },
          "ps": { "name": "Problem Solution", "abbr": "PS", "dur": "4m", "status": 2 },
          "me": { "name": "Methodology Explanation", "abbr": "ME", "dur": "5m", "status": 1 },
          "sk": { "name": "Skill Development", "abbr": "SK", "dur": "6m", "status": 2 },
          "ce": { "name": "Children's Educational", "abbr": "CE", "dur": "3m", "status": 2 },
          "sl": { "name": "Social Learning", "abbr": "SL", "dur": "4m", "status": 2 }
        }
      },
      "mkt": {
        "name": "Marketing/Commercial",
        "types": {
          "pv": { "name": "Product Validation", "abbr": "PV", "dur": "2m", "status": 2 },
          "vp": { "name": "Value Proposition", "abbr": "VP", "dur": "2m", "status": 2 },
          "pc": { "name": "Product Comparison", "abbr": "PC", "dur": "3m", "status": 2 },
          "pcu": { "name": "Product Comparison Updates", "abbr": "PCU", "dur": "2m", "status": 2 },
          "fe": { "name": "Feature Explanation", "abbr": "FE", "dur": "2m", "status": 2 },
          "uc": { "name": "Use Case Scenarios", "abbr": "UC", "dur": "3m", "status": 2 },
          "br": { "name": "Brand Story", "abbr": "BR", "dur": "3m", "status": 2 },
          "ad": { "name": "Service Advertisement", "abbr": "AD", "dur": "1m", "status": 2 },
          "sh": { "name": "Sell Homepage", "abbr": "SH", "dur": "2m", "status": 2 },
          "bo": { "name": "Brand Outro", "abbr": "BO", "dur": "30s", "status": 2 },
          "wvp": { "name": "Website Value Proposition", "abbr": "WVP", "dur": "2m", "status": 2 },
          "ps": { "name": "Property Showcase", "abbr": "PS", "dur": "3m", "status": 2 },
          "tc": { "name": "TV Commercial", "abbr": "TC", "dur": "30s", "status": 2 }
        }
      },
      "per": {
        "name": "Personal/Social",
        "types": {
          "ss": { "name": "Social Stories", "abbr": "SS", "dur": "1m", "status": 2 },
          "rv": { "name": "Review Video", "abbr": "RV", "dur": "5m", "status": 2 },
          "diy": { "name": "DIY Tutorial", "abbr": "DIY", "dur": "8m", "status": 2 },
          "wt": { "name": "Wellness Tips", "abbr": "WT", "dur": "3m", "status": 2 },
          "mg": { "name": "Mindfulness Guide", "abbr": "MG", "dur": "5m", "status": 1 },
          "lh": { "name": "Life Hack Tips", "abbr": "LH", "dur": "3m", "status": 2 }
        }
      },
      "pro": {
        "name": "Professional/Technical",
        "types": {
          "ag": { "name": "API Guide", "abbr": "AG", "dur": "5m", "status": 2 },
          "ae": { "name": "Architecture Explanation", "abbr": "AE", "dur": "7m", "status": 2 },
          "cw": { "name": "Code Walkthrough", "abbr": "CW", "dur": "10m", "status": 2 },
          "so": { "name": "System Overview", "abbr": "SO", "dur": "5m", "status": 2 },
          "ig": { "name": "Integration Guide", "abbr": "IG", "dur": "8m", "status": 2 },
          "sg": { "name": "Security Guidelines", "abbr": "SG", "dur": "5m", "status": 1 },
          "pg": { "name": "Process Guide", "abbr": "PG", "dur": "5m", "status": 2 },
          "pt": { "name": "Platform Tutorial", "abbr": "PT", "dur": "5m", "status": 2 },
          "tm": { "name": "Tool Mastery", "abbr": "TM", "dur": "10m", "status": 1 },
          "pb": { "name": "Professional Best Practices", "abbr": "PB", "dur": "5m", "status": 2 },
          "smh": { "name": "Smart Home Guide", "abbr": "SMH", "dur": "5m", "status": 2 },
          "fn": { "name": "Financial Education", "abbr": "FN", "dur": "5m", "status": 2 }
        }
      },
      "jou": {
        "name": "Journalistic/Documentary",
        "types": {
          "cd": { "name": "Career Development", "abbr": "CD", "dur": "5m", "status": 1 },
          "ld": { "name": "Leadership Skills", "abbr": "LD", "dur": "5m", "status": 1 },
          "ei": { "name": "Expert Interview", "abbr": "EI", "dur": "15m", "status": 1 },
          "ii": { "name": "Industry Insights", "abbr": "II", "dur": "5m", "status": 2 },
          "sa": { "name": "Success Archive", "abbr": "SA", "dur": "7m", "status": 1 },
          "af": { "name": "Analysis Framework", "abbr": "AF", "dur": "7m", "status": 2 },
          "rs": { "name": "Research Summary", "abbr": "RS", "dur": "5m", "status": 2 },
          "cs": { "name": "Case Study", "abbr": "CS", "dur": "8m", "status": 2 },
          "ha": { "name": "Historical Analysis", "abbr": "HA", "dur": "7m", "status": 1 }
        }
      }
    }
  };

  const getFilteredTypes = () => {
    const searchLower = searchTerm.toLowerCase();
    return Object.entries(VIDEO_INDEX.categories).map(([catId, category]) => {
      const types = Object.entries(category.types)
        .map(([typeId, type]) => ({
          id: typeId,
          categoryId: catId,
          ...type
        }))
        .filter(type => type.name.toLowerCase().includes(searchLower));

      return {
        id: catId,
        name: category.name,
        types,
        isAvailable: types.some(type => type.status === 2)
      };
    }).filter(cat => cat.types.length > 0);
  };

  const toggleCategory = (categoryId) => {
    setExpandedCategories(prev => ({
      ...prev,
      [categoryId]: !prev[categoryId]
    }));
  };

  const toggleAll = (expand) => {
    const newState = {};
    Object.keys(VIDEO_INDEX.categories).forEach(category => {
      newState[category] = expand;
    });
    setExpandedCategories(newState);
  };

  const handleContinue = () => {
    if (selectedType) {
      onComplete({
        processId: 'glow_62',
        data: {
          status: true,
          glow_62_response: {
            selectedType: selectedType,
          }
        }
      });
    }
  };

  const handleTypeSelection = (type) => {
    if (type.status === 2) {
      setSelectedType(type);
    }
  };

  return (
    <>
      <ViewerHeader 
        title="Create Your Video"
        subtitle="Choose from our variety of video types and start creating your professional video in minutes"
        processId="glow_62"
      />
      <Box sx={{ p: 3}}>
        <Card elevation={0} sx={{ borderRadius: 2}}>
          <CardContent>
            <Stack spacing={3}>
              {/* Search and Controls */}
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ position: 'relative', flexGrow: 1, maxWidth: 'md' }}>
                  <TextField
                    fullWidth
                    placeholder="Search for specific types..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />
                      ),
                      sx: { pl: 1 }
                    }}
                  />
                </Box>
                <Stack direction="row" spacing={1}>
                  <Button 
                    variant="outlined"
                    size="small"
                    onClick={() => toggleAll(true)}
                  >
                    Expand All
                  </Button>
                  <Button 
                    variant="outlined"
                    size="small"
                    onClick={() => toggleAll(false)}
                  >
                    Collapse All
                  </Button>
                </Stack>
              </Box>

              {/* Status Legend */}
              <Stack direction="row" spacing={2} justifyContent="center">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: 'success.main' }} />
                  <Typography variant="body2">Available</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <ClockIcon sx={{ fontSize: 16, color: 'warning.main' }} />
                  <Typography variant="body2">Coming Soon</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <LockIcon sx={{ fontSize: 16, color: 'error.main' }} />
                  <Typography variant="body2">Not Available</Typography>
                </Box>
              </Stack>

              {/* Categories and Types */}
              <Card sx={{backgroundColor: 'var(--color-background)'}} variant="outlined">
                {getFilteredTypes().map((category) => (
                  <React.Fragment key={category.id}>
                    <CategoryRow 
                      onClick={() => toggleCategory(category.id)}
                      sx={{ 
                        p: 2, 
                        display: 'flex', 
                        alignItems: 'center',
                        opacity: isCategoryComingSoon(category) ? 0.7 : 1,
                      }}
                    >
                      <IconButton size="small" sx={{ mr: 1 }}>
                        {expandedCategories[category.id] ? 
                          <ChevronDownIcon /> : 
                          <ChevronRightIcon />}
                      </IconButton>
                      {React.createElement(CATEGORY_ICONS[category.id], {
                        sx: { 
                          mr: 2,
                          color: isCategoryComingSoon(category) ? 'text.secondary' : '#FD9800',
                          fontSize: 24
                        }
                      })}
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          flexGrow: 1,
                          color: isCategoryComingSoon(category) ? 'text.secondary' : 'text.primary'
                        }}
                      >
                        {category.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {category.types.filter(t => t.status === 2).length} available / {category.types.length} types
                      </Typography>
                    </CategoryRow>

                    {expandedCategories[category.id] && (
                      <Box sx={{ p: 2, bgcolor: 'action.hover' }}>
                        <Grid container spacing={1}>
                          {category.types.map((type) => (
                            <Grid item xs={12} sm={6} md={4} key={type.id}>
                              <VideoTypeButton
                                onClick={() => type.status === 2 && setSelectedType(type)}
                                active={(selectedType?.id === type.id) ? 'active' : ''}
                                disabled={type.status !== 2}
                              >
                                {selectedType?.id === type.id ? (
                                  <CheckIcon sx={{ mr: 1 }} />
                                ) : type.status === 2 ? (
                                  <Box 
                                    sx={{ 
                                      width: 8, 
                                      height: 8, 
                                      borderRadius: '50%', 
                                      bgcolor: 'success.main',
                                      mr: 1.5,
                                      ml: 0.5
                                    }} 
                                  />
                                ) : null}
                                <Box sx={{ flexGrow: 1 }}>
                                  <Typography variant="body1">{type.name}</Typography>
                                  <Typography className="duration" variant="caption">
                                    {type.dur}
                                  </Typography>
                                </Box>
                                {type.status !== 2 && (
                                  type.status === 1 ? 
                                    <ClockIcon sx={{ color: 'warning.main' }} /> :
                                    <LockIcon sx={{ color: 'error.main' }} />
                                )}
                              </VideoTypeButton>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}
                  </React.Fragment>
                ))}
              </Card>
            </Stack>
          </CardContent>
        </Card>

        <ViewFooter 
          isDisabled={!sessionId}
          showButton={!!selectedType}
          onComplete={handleContinue}
          buttonText={process.footer.continueText}
        />
      </Box>
    </>
  );
};

export default Glow62Processor;