import React, { useState } from 'react';
import './Viewers.css';
import { Box, CircularProgress, Button } from '@mui/material';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import processConfig from '../../config/processIndex.json';
import { generateAndSaveTargetGroupImage } from '../../utils/processUtils';

const Glow01ProcessorCustomer = ({ data, onComplete, sessionId }) => {
  const [expandedGroups, setExpandedGroups] = useState({});
  const [groupImages, setGroupImages] = useState({});
  const [loadingImages, setLoadingImages] = useState({});
  const process = processConfig.processes.find(p => p.id === 'glow_01');

  React.useEffect(() => {
    if (data?.content) {
      data.content.forEach(group => {
        if (group.visual_description && !groupImages[group.id]) {
          generateImageForGroup(group);
        }
      });
    }
  }, [data]);

  const toggleGroup = (groupId) => {
    setExpandedGroups(prev => ({
      ...prev,
      [groupId]: !prev[groupId]
    }));
  };

  const generateImageForGroup = async (group) => {
    if (!group.visual_description || groupImages[group.id]) return;
    
    const targetGroupName = group.audience.toLowerCase().replace(/\s+/g, '_');
    setLoadingImages(prev => ({ ...prev, [group.id]: true }));
    try {
      const imagePaths = await generateAndSaveTargetGroupImage(
        group.visual_description,
        `${sessionId}/${targetGroupName}`
      );
      
      console.log('Image URL:', imagePaths.s3Path);
      
      setGroupImages(prev => ({
        ...prev,
        [group.id]: imagePaths.s3Path
      }));
    } catch (error) {
      console.error('Failed to generate image:', error);
    } finally {
      setLoadingImages(prev => ({ ...prev, [group.id]: false }));
    }
  };

  const renderTargetGroup = (group) => {
    const isExpanded = expandedGroups[group.id];

    return (
      <div key={group.id} className="target-group">
        <div className="target-group-header">
          <h3 onClick={() => toggleGroup(group.id)}>
            <span className={`collapse-indicator ${isExpanded ? 'expanded' : ''}`}>▶</span>
            Group {group.id}: {group.group}
          </h3>
          
          {!isExpanded && (
            <div className="group-image">
              {loadingImages[group.id] ? (
                <CircularProgress size={24} />
              ) : groupImages[group.id] ? (
                <img 
                  src={groupImages[group.id]} 
                  alt={`Visual for ${group.group}`}
                  style={{ 
                    maxWidth: '215px',
                    maxHeight: '215px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    cursor: 'pointer'
                  }}
                  onClick={() => toggleGroup(group.id)}
                  onError={(e) => {
                    console.error('Image load error:', e);
                    console.log('Attempted URL:', groupImages[group.id]);
                  }}
                />
              ) : (
                <Button 
                  onClick={() => generateImageForGroup(group)}
                  variant="outlined"
                  size="small"
                >
                  Generate Visual
                </Button>
              )}
            </div>
          )}
        </div>
        
        {isExpanded && (
          <div className="group-content">
            <div className="group-image">
              {loadingImages[group.id] ? (
                <CircularProgress size={40} />
              ) : groupImages[group.id] ? (
                <img 
                  src={groupImages[group.id]} 
                  alt={`Visual for ${group.group}`}
                  style={{ 
                    maxWidth: '512px',
                    maxHeight: '512px',
                    width: '100%',
                    objectFit: 'contain',
                    borderRadius: '8px'
                  }}
                  onError={(e) => {
                    console.error('Image load error:', e);
                    console.log('Attempted URL:', groupImages[group.id]);
                  }}
                />
              ) : (
                <Button 
                  onClick={() => generateImageForGroup(group)}
                  variant="outlined"
                >
                  Generate Visual
                </Button>
              )}
            </div>

            <h4>Key Points:</h4>
            <ul>
              {group.key_points.map((problem, index) => (
                <li key={index}>{problem}</li>
              ))}
            </ul>

            <div className="problem-solution">
              <div className="section">
                <h4>Main Message:</h4>
                <p>{group.main_message}</p>
              </div>
              
              <div className="section">
                <h4>Desired Outcome:</h4>
                <p>{group.desired_outcome}</p>
              </div>

              <div className="section">
                <h4>Visual Description:</h4>
                <p>{group.visual_description}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Box>
      <ViewerHeader 
        title={process.info.title}
        subtitle={process.info.description}
        processId="glow_01"
        sessionId={sessionId}
      />
      <div className="target-groups-container">
        {data.content?.map(group => renderTargetGroup(group)) || 
          <p>No target groups available</p>}
      </div>
      <ViewFooter
        isDisabled={!sessionId} 
        onComplete={onComplete}
        buttonText={process.footer.continueText}
      />
    </Box>
  );
};

export default Glow01ProcessorCustomer;
