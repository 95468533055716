import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Stack,
  styled,
  createTheme,
  ThemeProvider,
  Chip,
  Slider,
  Paper,
  Grid,
  IconButton,
  CircularProgress,
  Alert
} from '@mui/material';
import processConfig from '../../config/processIndex.json';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import {
  SmartToy as AIIcon,
  HighQuality as QualityIcon,
  AspectRatio as FormatIcon,
  Lock as LockIcon,
  NewReleases as ComingSoonIcon,
  VpnKey as RequestAccessIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import { 
  FaYoutube, 
  FaInstagram, 
  FaTiktok, 
  FaFacebookF, 
  FaLinkedinIn, 
  FaPinterestP, 
  FaTwitter 
} from 'react-icons/fa';
import { 
  SiSnapchat,
  SiYoutubeshorts
} from 'react-icons/si';
import { 
  MdPresentToAll, 
  MdTv, 
  MdMovie, 
  MdPhotoCamera 
} from 'react-icons/md';
import axios from 'axios';
import Cookies from 'js-cookie';

// Instead, use the direct URL
const API_BASE_URL = 'https://glowtest-dev-service-325458756816.asia-east2.run.app';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FD9800',
      light: '#FDB544',
      dark: '#D68100',
      contrastText: '#FFFFFF',
    }
  }
});

const FormatPreview = styled(Paper)(({ theme, ratio }) => ({
  width: '120px',
  height: '120px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  border: `2px solid ${theme.palette.primary.main}`,
  overflow: 'hidden',
  '& .inner-box': {
    backgroundColor: theme.palette.primary.main,
    transition: 'all 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.contrastText,
  }
}));

const formatOptions = [
  { 
    value: 0, 
    label: "1:2", 
    ratio: 0.5,
    description: "Perfect for full-screen mobile content on TikTok, Instagram Stories, and YouTube Shorts",
    platforms: ["TikTok", "Instagram Stories", "YouTube Shorts"]
  },
  { 
    value: 1, 
    label: "9:16", 
    ratio: 0.5625,
    description: "Most popular vertical format! Optimized for vertical social media content and mobile-first viewing",
    popularityNote: "Most popular vertical format for social media",
    platforms: ["Instagram Reels", "Snapchat", "Facebook Stories"]
  },
  { 
    value: 2, 
    label: "3:4", 
    ratio: 0.75,
    description: "Ideal for Instagram feed posts and Pinterest, maximizing vertical space while maintaining readability",
    platforms: ["Instagram Feed", "Pinterest", "Facebook Feed"]
  },
  { 
    value: 3, 
    label: "4:5", 
    ratio: 0.8,
    description: "Instagram's preferred vertical format, maximizing feed space while maintaining quality",
    platforms: ["Instagram Feed", "Facebook Feed"]
  },
  { 
    value: 4, 
    label: "1:1", 
    ratio: 1,
    description: "Perfect square format for social media feeds, especially Instagram and Facebook",
    platforms: ["Instagram Feed", "Facebook Feed", "LinkedIn"]
  },
  { 
    value: 5, 
    label: "5:4", 
    ratio: 1.25,
    description: "Classic photography format, great for landscape and portrait content",
    platforms: ["Photography Sites", "Professional Portfolios"]
  },
  { 
    value: 6, 
    label: "4:3", 
    ratio: 1.33,
    description: "Traditional presentation format, ideal for business content and older displays",
    platforms: ["Business Presentations", "LinkedIn", "Traditional TV"]
  },
  { 
    value: 7, 
    label: "16:9", 
    ratio: 1.77,
    description: "Most popular horizontal format! Standard widescreen format, perfect for YouTube videos and modern displays",
    popularityNote: "Most popular horizontal format for video content",
    platforms: ["YouTube", "Presentations", "Desktop Viewing"]
  },
  { 
    value: 8, 
    label: "2:1", 
    ratio: 2,
    description: "Cinematic format popular for website headers and landscape social media content",
    platforms: ["Website Headers", "Twitter Cards", "LinkedIn Banners"]
  },
  { 
    value: 9, 
    label: "21:9", 
    ratio: 2.33,
    description: "Ultra-wide cinematic format for dramatic visual storytelling",
    platforms: ["Cinema", "Premium Video Content", "Ultrawide Displays"]
  }
];

const ModelCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .model-image': {
    width: '100%',
    height: 120,
    objectFit: 'cover',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  '& .locked-overlay': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    bottom: '64px'
  }
}));

const aiModels = [
  {
    id: 'sd_3_5_large',
    name: 'SD 3.5 Large',
    description: 'Stable Diffusion 3.5 Large - Powerful image generation',
    image: '/path/to/sd35-image.jpg',
    available: true
  },
  {
    id: 'sd_xl',
    name: 'SD XL',
    description: 'Stable Diffusion XL - Enhanced detail and quality',
    image: '/path/to/sdxl-image.jpg',
    available: false
  },
  {
    id: 'dalle3',
    name: 'DALL·E 3',
    description: 'OpenAI\'s latest image generation model',
    image: '/path/to/dalle3-image.jpg',
    available: false
  },
  {
    id: 'sora',
    name: 'Sora',
    description: 'OpenAI\'s text-to-video generation model',
    image: '/path/to/sora-image.jpg',
    available: false
  },
  {
    id: 'kling',
    name: 'Kling',
    description: 'Advanced video generation and editing',
    image: '/path/to/kling-image.jpg',
    available: false
  }
];

const resolutions = [
  {
    id: '720p',
    name: '720p HD',
    description: 'Standard HD resolution (1280×720)',
    available: true
  },
  {
    id: '1080p',
    name: '1080p Full HD',
    description: 'Full HD resolution (1920×1080)',
    available: false
  },
  {
    id: '4k',
    name: '4K Ultra HD',
    description: '4K resolution (3840×2160)',
    available: false
  }
];

// Platform icon mapping
const PLATFORM_ICONS = {
  'YouTube': FaYoutube,
  'YouTube Shorts': SiYoutubeshorts,
  'TikTok': FaTiktok,
  'Instagram Stories': FaInstagram,
  'Instagram Feed': FaInstagram,
  'Instagram Reels': FaInstagram,
  'Snapchat': SiSnapchat,
  'Facebook Stories': FaFacebookF,
  'Facebook Feed': FaFacebookF,
  'LinkedIn': FaLinkedinIn,
  'Pinterest': FaPinterestP,
  'Twitter Cards': FaTwitter,
  'Business Presentations': MdPresentToAll,
  'Traditional TV': MdTv,
  'Cinema': MdMovie,
  'Photography Sites': MdPhotoCamera,
  'Professional Portfolios': MdPhotoCamera,
  'Desktop Viewing': MdPresentToAll,
  'Premium Video Content': MdMovie,
  'Ultrawide Displays': MdTv,
  'Website Headers': MdPresentToAll,
  'LinkedIn Banners': FaLinkedinIn
};

const StyledChip = styled(Chip)(({ theme }) => ({
  '& .MuiChip-icon': {
    color: theme.palette.primary.main,
    marginLeft: '8px'
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main + '1A', // 10% opacity
  },
  transition: 'all 0.2s ease-in-out',
  cursor: 'default'
}));

const calculateDimensions = (format, resolution) => {
  const ratioOption = formatOptions[format];
  const ratio = ratioOption.ratio;
  
  let width, height;
  switch(resolution) {
    case '720p':
      // For vertical formats (ratio[0] < ratio[1]), make the height 720p
      if (parseInt(ratio[0]) < parseInt(ratio[1])) {
        height = 720;
        width = Math.round(height * (parseInt(ratio[0]) / parseInt(ratio[1])));
      } else {
        // For horizontal formats, make the shorter side (height) 720p
        height = 720;
        width = Math.round(height * (parseInt(ratio[0]) / parseInt(ratio[1])));
      }
      break;
    case '1080p':
      if (parseInt(ratio[0]) < parseInt(ratio[1])) {
        height = 1080;
        width = Math.round(height * (parseInt(ratio[0]) / parseInt(ratio[1])));
      } else {
        height = 1080;
        width = Math.round(height * (parseInt(ratio[0]) / parseInt(ratio[1])));
      }
      break;
    case '4k':
      if (parseInt(ratio[0]) < parseInt(ratio[1])) {
        height = 2160;
        width = Math.round(height * (parseInt(ratio[0]) / parseInt(ratio[1])));
      } else {
        height = 2160;
        width = Math.round(height * (parseInt(ratio[0]) / parseInt(ratio[1])));
      }
      break;
    default:
      return null;
  }

  // For vertical formats (ratio[0] < ratio[1]), swap width and height
  if (parseInt(ratio[0]) < parseInt(ratio[1])) {
    [width, height] = [height, width];
  }

  return { width, height };
};

const ProviderSelection = ({ providers, selected, onChange }) => (
  <Card elevation={0} sx={{ borderRadius: 2, backgroundColor: 'var(--card-bg)' }}>
    <CardHeader 
      title="Select Provider"
      sx={{ 
        '& .MuiCardHeader-title': {
          fontSize: '1.1rem',
          fontWeight: 500
        }
      }}
    />
    <CardContent>
      <Grid container spacing={2}>
        {providers.map((provider) => (
          <Grid item xs={12} sm={6} key={provider}>
            <Button
              fullWidth
              variant={selected === provider ? 'contained' : 'outlined'}
              onClick={() => onChange(provider)}
            >
              {provider.toUpperCase()}
            </Button>
          </Grid>
        ))}
      </Grid>
    </CardContent>
  </Card>
);

const ModelSelection = ({ config, provider, selected, onChange }) => {
  const models = config[`${provider}_config`]?.models || [];
  
  return (
    <Card elevation={0} sx={{ borderRadius: 2, backgroundColor: 'var(--card-bg)' }}>
      <CardHeader 
        title="Select Model"
        sx={{ 
          '& .MuiCardHeader-title': {
            fontSize: '1.1rem',
            fontWeight: 500
          }
        }}
      />
      <CardContent>
        <Grid container spacing={2}>
          {models.map((model) => (
            <Grid item xs={12} sm={6} md={4} key={model}>
              <ModelCard>
                <CardContent>
                  <Typography variant="h6">{model}</Typography>
                  <Button
                    fullWidth
                    variant={selected === model ? 'contained' : 'outlined'}
                    onClick={() => onChange(model)}
                  >
                    {selected === model ? 'Selected' : 'Select'}
                  </Button>
                </CardContent>
              </ModelCard>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

const StyleSelection = ({ config, provider, model, selected, onChange }) => {
  const providerConfig = config[`${provider}_config`];
  const stylesList = providerConfig?.styles_list || [];
  const stylesData = providerConfig?.styles_data || {};
  
  return (
    <Card elevation={0} sx={{ borderRadius: 2, backgroundColor: 'var(--card-bg)' }}>
      <CardHeader 
        title="Select Style"
        sx={{ 
          '& .MuiCardHeader-title': {
            fontSize: '1.1rem',
            fontWeight: 500
          }
        }}
      />
      <CardContent>
        <Grid container spacing={2}>
          {stylesList.map((style) => {
            const substyles = stylesData[style][`${model}_substyles`];
            return (
              <Grid item xs={12} sm={6} md={4} key={style}>
                <Card 
                  variant="outlined"
                  sx={{ 
                    cursor: 'pointer',
                    border: selected === style ? 2 : 1,
                    borderColor: selected === style ? 'primary.main' : 'divider'
                  }}
                  onClick={() => onChange(style)}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      {style.split('_').map(word => 
                        word.charAt(0).toUpperCase() + word.slice(1)
                      ).join(' ')}
                    </Typography>
                    {substyles && (
                      <Typography variant="body2" color="text.secondary">
                        {substyles.length} substyles available
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

const SubstyleSelection = ({ config, provider, model, style, selected, onChange }) => {
  const substyles = config[`${provider}_config`]?.styles_data[style][`${model}_substyles`];
  
  if (!substyles) return null;
  
  return (
    <Card elevation={0} sx={{ borderRadius: 2, backgroundColor: 'var(--card-bg)' }}>
      <CardHeader 
        title="Select Substyle"
        sx={{ 
          '& .MuiCardHeader-title': {
            fontSize: '1.1rem',
            fontWeight: 500
          }
        }}
      />
      <CardContent>
        <Grid container spacing={2}>
          {substyles.map((substyle) => (
            <Grid item xs={12} sm={6} md={4} key={substyle}>
              <Button
                fullWidth
                variant={selected === substyle ? 'contained' : 'outlined'}
                onClick={() => onChange(substyle)}
                sx={{ textTransform: 'none' }}
              >
                {substyle.split('_').map(word => 
                  word.charAt(0).toUpperCase() + word.slice(1)
                ).join(' ')}
              </Button>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

const AdditionalConfig = ({ config, provider, answers, onChange }) => {
  // Implementation of AdditionalConfig component
};

const Glow61Processor = ({ data, onComplete, handleFeatureRequest, sessionId }) => {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [answers, setAnswers] = useState(data?.glow_61_response || {
    format: 7,
    provider: '',
    model: '',
    style: '',
    substyle: '',
    size: '',
    quality: 'standard',
    artistic_level: null,
    no_text: false
  });
  
  // Simplified state for feature requests
  const [requestedFeatures, setRequestedFeatures] = useState(new Set());

  // Fetch configuration on component mount
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_BASE_URL}/api/v1/workflow/get_text_to_image_config`,
          {
            headers: { 
              Authorization: `Bearer ${Cookies.get('token')}`,
              accept: 'application/json'
            }
          }
        );
        setConfig(response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchConfig();
  }, []);

  const isComplete = () => {
    const complete = answers.ai_model && answers.resolution;
    return complete;
  };

  const handleRequestAccess = (id, type) => {
    if (handleFeatureRequest && handleFeatureRequest(id, type)) {
      setRequestedFeatures(prev => new Set([...prev, id]));
    }
  };

  const isRequested = (id) => requestedFeatures.has(id);

  const handleContinue = () => {
      if (answers && onComplete) {
        onComplete({
          processId: 'glow_61',
          data: {
            status: true,
            glow_61_response: answers
          }
        });
    }
  };

  const handleFormatChange = (event, newValue) => {
    setAnswers(prev => ({
      ...prev,
      format: newValue
    }));
  };

  const getFormatPreview = (format) => {
    const option = formatOptions[format];
    const ratio = option.ratio;
    let width, height;
    
    if (ratio >= 1) {
      width = 160;
      height = width / ratio;
    } else {
      height = 160;
      width = height * ratio;
    }

    return { width, height, ratio: option.label };
  };

  const getFormatInfo = (format) => {
    const option = formatOptions[format];
    return {
      ...getFormatPreview(format),
      description: option.description,
      platforms: option.platforms
    };
  };

  // Add format selection to the render
  const formatInfo = getFormatInfo(answers.format);
  
  return (
    <ThemeProvider theme={theme}>
      <ViewerHeader 
        title="Video Format Selection"
        subtitle="Choose your video format and settings"
        processId="glow_61"
      />
      <Box sx={{ p: 3, backgroundColor: 'var(--color-surface)' }}>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Stack spacing={3} sx={{ maxWidth: 900, mx: 'auto'}}>
            {/* Format Selection with enhanced information */}
            <Card elevation={0} sx={{ borderRadius: 2, backgroundColor: 'var(--card-bg)' }}>
              <CardHeader 
                title="Choose Format"
                sx={{ 
                  '& .MuiCardHeader-title': {
                    fontSize: '1.1rem',
                    fontWeight: 500
                  }
                }}
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Stack spacing={2} alignItems="center">
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 2,
                        justifyContent: 'center'
                      }}>
                        <IconButton 
                          onClick={() => handleFormatChange(null, Math.max(0, answers.format - 1))}
                          disabled={answers.format === 0}
                          sx={{ 
                            color: 'primary.main',
                            '&.Mui-disabled': {
                              color: 'action.disabled'
                            }
                          }}
                        >
                          <ChevronLeftIcon />
                        </IconButton>
                        
                        <FormatPreview>
                          <Box 
                            className="inner-box"
                            sx={{
                              width: getFormatPreview(answers.format).width * 0.75,
                              height: getFormatPreview(answers.format).height * 0.75,
                            }}
                          >
                            {getFormatPreview(answers.format).ratio}
                          </Box>
                        </FormatPreview>
                        
                        <IconButton 
                          onClick={() => handleFormatChange(null, Math.min(9, answers.format + 1))}
                          disabled={answers.format === 9}
                          sx={{ 
                            color: 'primary.main',
                            '&.Mui-disabled': {
                              color: 'action.disabled'
                            }
                          }}
                        >
                          <ChevronRightIcon />
                        </IconButton>
                      </Box>
                    </Stack>
                  </Grid>
                  
                  <Grid item xs={12} md={8}>
                    <Stack spacing={3}>
                      <Box sx={{ width: '100%', px: 2 }}>
                        <Slider
                          value={answers.format}
                          onChange={handleFormatChange}
                          step={1}
                          marks={formatOptions.map(option => ({
                            value: option.value,
                            label: option.label
                          }))}
                          min={0}
                          max={9}
                          sx={{
                            '& .MuiSlider-mark': {
                              height: '10px'
                            },
                            '& .MuiSlider-markLabel': {
                              fontSize: '0.75rem'
                            }
                          }}
                        />
                      </Box>
                      
                      <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                          {formatOptions[answers.format].label} Format
                        </Typography>
                        {formatOptions[answers.format].popularityNote && (
                          <Typography 
                            variant="subtitle2" 
                            sx={{ 
                              color: 'success.main',
                              fontWeight: 'bold',
                              mb: 1
                            }}
                          >
                            ★ {formatOptions[answers.format].popularityNote} ★
                          </Typography>
                        )}
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          {formatOptions[answers.format].description}
                        </Typography>
                        
                        {/* Resolution Display */}
                        {answers.resolution && (
                          <Paper
                            elevation={0}
                            sx={{
                              mt: 2,
                              p: 1,
                              bgcolor: 'rgba(253, 152, 0, 0.08)',
                              border: '1px solid rgba(253, 152, 0, 0.2)',
                              borderRadius: 1,
                              display: 'inline-flex',
                              alignItems: 'center',
                              gap: 1
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Resolution:
                            </Typography>
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                fontFamily: 'monospace',
                                fontWeight: 'medium',
                                color: 'primary.main'
                              }}
                            >
                              {(() => {
                                const dimensions = calculateDimensions(answers.format, answers.resolution);
                                return dimensions ? `${dimensions.width}×${dimensions.height} px` : '';
                              })()}
                            </Typography>
                          </Paper>
                        )}

                        <Stack 
                          direction="row" 
                          spacing={1} 
                          justifyContent="center" 
                          flexWrap="wrap" 
                          sx={{ gap: 1, mt: 2 }}
                        >
                          {formatOptions[answers.format].platforms.map((platform, index) => {
                            const IconComponent = PLATFORM_ICONS[platform];
                            return (
                              <StyledChip 
                                key={index} 
                                icon={IconComponent && <IconComponent />}
                                label={platform}
                                size="small" 
                                color="primary" 
                                variant="outlined"
                              />
                            );
                          })}
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Provider Selection */}
            <ProviderSelection 
              providers={config.model_providers}
              selected={answers.provider}
              onChange={(provider) => setAnswers(prev => ({
                ...prev,
                provider,
                model: '', // Reset dependent fields
                style: '',
                substyle: ''
              }))}
            />

            {/* Model Selection - shown only when provider is selected */}
            {answers.provider && (
              <ModelSelection 
                config={config}
                provider={answers.provider}
                selected={answers.model}
                onChange={(model) => setAnswers(prev => ({
                  ...prev,
                  model,
                  style: '', // Reset dependent fields
                  substyle: ''
                }))}
              />
            )}

            {/* Style Selection - shown when model is selected */}
            {answers.model && (
              <StyleSelection 
                config={config}
                provider={answers.provider}
                model={answers.model}
                selected={answers.style}
                onChange={(style) => setAnswers(prev => ({
                  ...prev,
                  style,
                  substyle: '' // Reset substyle when style changes
                }))}
              />
            )}

            {/* Substyle Selection - shown when style is selected */}
            {answers.style && (
              <SubstyleSelection 
                config={config}
                provider={answers.provider}
                model={answers.model}
                style={answers.style}
                selected={answers.substyle}
                onChange={(substyle) => setAnswers(prev => ({
                  ...prev,
                  substyle
                }))}
              />
            )}

            {/* Additional configurations based on provider/model */}
            {answers.style && (
              <AdditionalConfig 
                config={config}
                provider={answers.provider}
                answers={answers}
                onChange={setAnswers}
              />
            )}

            {/* Continue button */}
            <Button 
              variant="contained" 
              onClick={handleContinue}
              disabled={!isComplete()}
            >
              Continue
            </Button>
          </Stack>
        )}
      </Box>
      <ViewFooter
        isDisabled={!isComplete()} 
        showButton={true}
        onComplete={handleContinue}
        buttonText="Continue to Video Type"
      />
    </ThemeProvider>
  );
};

export default Glow61Processor;