import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import axios from 'axios';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { styled } from "@mui/system";
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { FcGoogle } from "react-icons/fc";
import { BsApple } from "react-icons/bs";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { signInWithPopup, getAdditionalUserInfo, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthenticateStatus, handleEmailLogin, handleEmailRegister, handleGoogleSignIn } from './authenticateSlide';

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  transition: "all 0.3s ease-in-out",
}));

const SocialButton = styled(Button)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5),
  textTransform: "none",
  fontSize: "1rem",
  border: "1px solid var(--color-border)",
  "&:hover": {
    backgroundColor: 'var(--secondary)'
  }
}));

const Authenticate = ({ setAccountInfo, auth, showNotice, setAuthFormVisible }) => {
  const authenticateStatus = useSelector(selectAuthenticateStatus);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });
  const [errors, setErrors] = useState({
    email: "",
    password: ""
  });

  useEffect(() => {
    if (authenticateStatus === 'loading') {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [authenticateStatus])
  
  const handleGoogleSignInTrigger = () => {
    dispatch(handleGoogleSignIn({auth:auth}));
  }
  const handleAppleSignIn = async () => {
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, appleProvider);
      const credential = OAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
      console.log("Apple Sign In Success:", user);
    } catch (error) {
      console.error("Apple Sign In Error:", error.message);
      showNotice(error.message, 'error')
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));

    if (name === "email") {
      setErrors((prev) => ({
        ...prev,
        email: validateEmail(value) ? "" : "Invalid email format"
      }));
    } else if (name === "password") {
      setErrors((prev) => ({
        ...prev,
        password: value.length >= 8 ? "" : "Password must be at least 8 characters"
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!errors.email && !errors.password) {
      if (activeTab === 0) dispatch(handleEmailLogin(formData))
      else if (activeTab === 1) dispatch (handleEmailRegister(formData))
    }
  };

  return (
    <StyledPaper elevation={0}>
      <IconButton
        aria-label="close"
        onClick={() => setAuthFormVisible(false)}
        sx={{ position: 'absolute', right: 16, top: 16 }}
      >
        <CloseIcon />
      </IconButton>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        variant="fullWidth"
        aria-label="auth tabs"
        sx={{ mb: 3 }}
      >
        <Tab label="Login" id="login-tab" aria-controls="login-panel" />
        <Tab label="Register" id="register-tab" aria-controls="register-panel" />
      </Tabs>

      <form onSubmit={handleSubmit}>
        {activeTab !== 0 && (
          <>
            <TextField
              fullWidth
              type="text"
              name="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={handleChange}
              error={!!errors.firstName}
              helperText={errors.firstName}
              margin="dense"
              autoComplete="given-name"
              aria-label="First Name input"
              required
            />

            <TextField
              fullWidth
              type="text"
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              error={!!errors.lastName}
              helperText={errors.lastName}
              margin="dense"
              autoComplete="family-name"
              aria-label="Last Name input"
              required
            />

            <TextField
              fullWidth
              type="text"
              name="nickname"
              label="Nickname (optional)"
              value={formData.nickname}
              onChange={handleChange}
              error={!!errors.nickname}
              helperText={errors.nickname}
              margin="dense"
              autoComplete="nickname"
              aria-label="Nickname input"
            />
          </>
        )}

        <TextField
          fullWidth
          type="email"
          name="email"
          label="Email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          margin="dense"
          autoComplete="email"
          aria-label="Email input"
          required
        />

        <TextField
          fullWidth
          type={showPassword ? "text" : "password"}
          name="password"
          label="Password"
          value={formData.password}
          onChange={handleChange}
          error={!!errors.password}
          helperText={errors.password}
          margin="dense"
          autoComplete="current-password"
          aria-label="Password input"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <Button
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={loading}
          sx={{ mt: 3, mb: 2 }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            activeTab === 0 ? "Login" : "Register"
          )}
        </Button>

        <Divider sx={{ my: 2 }}>
          <Typography color="textSecondary" variant="body2">
            OR
          </Typography>
        </Divider>

        <Box sx={{ mt: 2 }}>
          <SocialButton
            startIcon={<FcGoogle />}
            aria-label="Sign in with Google"
            onClick={handleGoogleSignInTrigger}
            disabled={loading}
          >
            Continue with Google
          </SocialButton>
          <SocialButton
            startIcon={<BsApple />}
            aria-label="Sign in with Apple"
            onClick={handleAppleSignIn}
            disabled={loading}
          >
            Continue with Apple
          </SocialButton>
        </Box>

        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ mt: 2 }}
        >
          {activeTab === 0
            ? "Don't have an account? Click Register above"
            : "Already have an account? Click Login above"}
        </Typography>
      </form>
    </StyledPaper>
  );
};

export default Authenticate;