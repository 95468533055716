import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { history } from "./helpers/history"
import LandingPage from "./pages/LandingPage"
import Journey from "./pages/Journey"
import AdminLayout from "./pages/Admin/layout";

import "./App.css";

function App() {
  return (
    <Router history={history}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/journey" element={<Journey />} />
          <Route path="/admin" element={<AdminLayout />} />
        </Routes>
    </Router>
  );
}

export default App;