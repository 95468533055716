import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app

const theme = createTheme({
  colorSchemes: {
    dark: true,
  },
  palette: {
    mode: 'light', // Default mode
    primary: {
      main: '#FD9800', // From theme-light
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    success: {
      main: '#28a745', // From theme-light
    },
    background: {
      default: '#F5F5F5', // From theme-light
      paper: '#FFFFFF', // From theme-light
    },
    text: {
      primary: '#1C1C1E', // From theme-light
      secondary: '#717585', // From theme-light
    },
    border: {
      main: 'rgba(0, 0, 0, 0.23)', // From theme-light
    },
  },
  typography: {
    fontFamily: [
      'DM Sans',
      'sans-serif'
    ].join(','),
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 400,
    },
    subtitle2: {
      fontWeight: 400,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    button: {
      fontWeight: 500,
      textTransform: 'none', // Prevents automatic uppercase transformation
    },
  }
});
theme.components = {
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '8px',
      },
    },
  },
}

export default theme;