import React, { useState, useEffect, useRef } from 'react';
import Authenticate from '../../auth/authenticate'; // Import the Authenticate class
import AccountMenu from '../../components/ui/account_menu';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged  } from "firebase/auth";
import { getFirestore, doc, setDoc, collection, onSnapshot }  from "firebase/firestore";
import axios from 'axios';
import Cookies from 'js-cookie';
import './Journey.css';
import { 
  FaCheckCircle, 
  FaList, 
  FaPlusSquare, 
  FaCircle, 
  FaTools, 
  FaCopy, 
  FaDownload, 
  FaMagic, 
  FaSave, 
  FaRedo, 
  FaCheck, 
  FaPlay,
  FaTimes,
  FaEdit,
  FaTrash,
  FaBars,
  FaSun,
  FaMoon,
  FaBuilding 
} from 'react-icons/fa';
import { useColorScheme } from '@mui/material/styles';
import Lottie from 'lottie-react';
import loadingAnimation from '../../animations/loading.json';
import MultiLevelStepper from '../../components/stepper/MultiLevelStepper';
import stepperStructure from '../../config/MultiLevelStepper.json';
import { 
  getProcessById, 
  getDisplayName, 
  canRunProcess, 
  getProcessResponse,
  getProcessStatus,
  getEndpoint,
  getAllProcesses
} from '../../utils/processUtils';
import processConfig from '../../config/processIndex.json';
import IdeaInputCard from '../../components/IdeaInputCard';
import { 
  Typography, 
  TextField, 
  Button, 
  useTheme,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  useMediaQuery
} from '@mui/material';
import ProcessCache from '../../utils/processCache';
import { ProcessChainManager } from '../../utils/processChain';
import MenuIcon from '@mui/icons-material/Menu';
import ContrastIcon from '@mui/icons-material/Contrast';
import CreditFlag from '../../components/ui/credit_flag'; // Import the CreditFlag component
import theme from '../../theme';

import {  useDispatch, useSelector } from 'react-redux';
import { fetchCompanyList, selectSelectedCompany, companySelected } from '../../components/company/companySlide'
import { signInAnonymous, selectAccountInfo, selectToken, selectAuthenticateStatus, selectAuthenticateMessage, handleValidCredentials, loadCachedAccountInfo, getRemainingCredit } from '../../auth/authenticateSlide';
import { 
  getSessionData, 
  selectSessionData, 
  selectSessionStatus, 
  selectSessionId, 
  selectGlowData, 
  selectGlowStatus, 
  updateGlowData, 
  setSessionId,
  setInputPrompt,
  clearSessionData,
  runProcess,
  selectSessionMessage
} from '../../components/session/sessionSlide';

import Glow01Processor from '../../components/viewers/glow_01_processor_customer';
import Glow02Processor from '../../components/viewers/glow_02_processor_scene';
import Glow03Processor from '../../components/viewers/glow_03_processor_script';
import Glow08Processor from '../../components/viewers/glow_08_processor_questions';
import Glow09Processor from '../../components/viewers/glow_09_processor_overview';
import Glow10Processor from '../../components/viewers/glow_10_processor_tamsamsom';
import Glow21Processor from '../../components/viewers/glow_21_market-comp-matrix';
import Glow11Processor from '../../components/viewers/glow_11_processor_prodprice';
import Glow30Processor from '../../components/viewers/glow_30_final_sales_pitch';
import Glow62Processor from '../../components/viewers/glow_62_processor_customer';
import Glow63Processor from '../../components/viewers/glow_63_processor_customer';
import Glow33Processor from '../../components/viewers/glow_33_processor_goal_base_analysis';
import Glow91Processor from '../../components/viewers/glow_91_processor_video_request_manager';
import Glow61Processor from '../../components/viewers/glow_61_processor_video_format';
import Companies from '../../components/company/company';

const processComponents = {
  'glow_08': Glow08Processor,
  'glow_09': Glow09Processor,
  'glow_10': Glow10Processor,
  'glow_21': Glow21Processor,
  'glow_11': Glow11Processor,
  'glow_01': Glow01Processor,
  'glow_02': Glow02Processor,
  'glow_03': Glow03Processor,
  'glow_30': Glow30Processor,
  'glow_33': Glow33Processor,
  'glow_61': Glow61Processor,
  'glow_62': Glow62Processor,
  'glow_63': Glow63Processor,
  'glow_91': Glow91Processor,
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
function Journey() {    
  const [inputText, setInputText] = useState('');
  const [devToolOpen, setDevToolOpen] = useState(false);
  const [apiResponses, setApiResponses] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isAnyProcessRunning, setIsAnyProcessRunning] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const [currentProcess, setCurrentProcess] = useState('');
  const modalRef = useRef(null);
  const [showSessionOptions, setShowSessionOptions] = useState(false);
  const [newSessionName, setNewSessionName] = useState('');
  const [sessionList, setSessionList] = useState([]);
  const [showSessionList, setShowSessionList] = useState(false);
  const [editingSessionId, setEditingSessionId] = useState(null);
  const dropdownRef = useRef(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
  const [isJsonView, setIsJsonView] = useState(true);
  const [isDarkTheme, setIsDarkTheme] = useState((false));
  const [isProcessViewCollapsed, setIsProcessViewCollapsed] = useState({});
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [highlightedButton, setHighlightedButton] = useState(null);
  const [initialAnswers, setInitialAnswers] = useState(null)
  const [companyDrawerOpen, setCompanyDrawerOpen] = useState(false);
  const [noticeState, setNoticeState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    severity: 'success',
    duration: 2000,
    message: 'I love glowtest'
  });
  const showNotice = (message, severity = 'success') => {    
    setNoticeState({ ...noticeState,message:message, severity: severity, open: true });
  }
  const handleNoticeClose = () => {
    setNoticeState({ ...noticeState, open: false });
  };
  
  const dispatch = useDispatch()

  const selectedCompany = useSelector(selectSelectedCompany) || ''
  const authenticateStatus = useSelector(selectAuthenticateStatus)
  const authenticateMessage = useSelector(selectAuthenticateMessage)
  const accountInfo = useSelector(selectAccountInfo)
  const activeToken = useSelector(selectToken)

  useEffect(() => {
    if (authenticateStatus === 'idle') {
      // Check cookies if existed authenticate info
      const cookieAccountInfo = Cookies.get('accountInfo');
      const token = Cookies.get('token');
      const parsedAccountInfo = cookieAccountInfo ? JSON.parse(cookieAccountInfo) : {};
      if (token && (parsedAccountInfo.userToken || parsedAccountInfo.guestToken)) {
        dispatch(loadCachedAccountInfo(parsedAccountInfo));
      } else {
        // Else dispatch signin as Guest
        dispatch(signInAnonymous())
      }      
    } else if( authenticateStatus === 'succeeded' && activeToken) {
      setShowAuthForm(false)
      dispatch(fetchCompanyList())
      dispatch(getRemainingCredit({token:activeToken}))
      initializeSessionSelection();
    } else if( authenticateStatus === 'failed') {
      showNotice(authenticateMessage, 'error')
    }
  }, [authenticateStatus, activeToken])

  const sessionId = useSelector(selectSessionId)
  const sessionStatus = useSelector(selectSessionStatus)
  const sessionMessage = useSelector(selectSessionMessage)
  const sessionData = useSelector(selectSessionData)
  const completedProcesses = useSelector((state) => 
    ProcessChainManager.processOrder.reduce((acc, glowId) => {
      acc[glowId] = selectGlowStatus(state, glowId);
      return acc;
    }, {})
  );
  
  const glowData = useSelector((state) => 
    ProcessChainManager.processOrder.reduce((acc, glowId) => {
      acc[glowId] = selectGlowData(state, glowId);
      return acc;
    }, {})
  );
  useEffect(() => {
    if( sessionStatus === 'failed') {
      showNotice(sessionMessage ?? 'Failed to load session data', 'error')
    }
  }, [sessionStatus])

  useEffect(() => {
    if(sessionData) setInputText(sessionData.inputPrompt ?? '');
  }, [sessionData.inputPrompt])

  useEffect(() => {
    if(sessionData && sessionData.companyId) dispatch(companySelected(sessionData.companyId))
  }, [sessionData.companyId])


  // ... existing state variables ...
  const [showAuthForm, setShowAuthForm] = useState(false); // State to manage auth form visibility

  // Function to toggle the authentication form
  const toggleAuthForm = () => {
    setShowAuthForm(prev => !prev);
  };
  
  const processCache = new ProcessCache();
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };  
  
  const firebaseApp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseApp);
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  useEffect(() => {
    // Check if selected then filter session list (pass, filter when render)
    // Check if current sessiondetail is not belong to company then clean
    if(sessionData && sessionData.companyId && sessionData.companyId != selectedCompany) {
      dispatch(clearSessionData())
    }
  }, [selectedCompany])

  // Add these handlers at the top of your App component
  const handleSessionListClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowSessionList(true);
  };

  const handleDevToolsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDevToolOpen(!devToolOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      // Remove these since we're not using icicle anymore
      // setWidth(window.innerWidth * 0.8);
      // setHeight(window.innerHeight * 0.6);
    };
    
    const savedTheme = localStorage.getItem('theme');
    setMode(savedTheme)
    setIsDarkTheme(savedTheme === 'dark')

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Add this useEffect to handle theme changes
  useEffect(() => {
    document.body.className = isDarkTheme ? 'theme-dark' : 'theme-light';
  }, [isDarkTheme]);

  const toggleTheme = () => {
    const newMode = !isDarkTheme;
    setIsDarkTheme(!isDarkTheme);
    setMode(newMode ? 'dark' : 'light')
    localStorage.setItem('theme', newMode ? 'dark' : 'light');
  };

  const fetchSessionList = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/session/get_session_list`,
        {
          headers: { Authorization: `Bearer ${Cookies.get('token')}` },
        }
      );
      setSessionList(response.data.data.sessions || []);
      return response.data;
    } catch (error) {
      console.error('Error fetching session list:', error);
      showToast('Failed to fetch session list');
      return { status_code: 400, data: [] };
    }
  };

  const getSessionDetails = async (id) => {
    dispatch(getSessionData(id))
  };

  // Add a ref to track initialization
  const isInitialized = useRef(false);

  const initializeSessionSelection = async () => {
    try {
      const listResponse = await fetchSessionList();
      
      const hasValidSessions = listResponse.data?.sessions && 
                            Array.isArray(listResponse.data.sessions) && 
                            listResponse.data.sessions.length > 0;
      // Clear session data if:
      // 1. Response has error (status_code 400)
      // 2. Response data is empty or invalid
      // 3. No sessions in the list
      if (listResponse.status_code === 400 || !hasValidSessions) {
        dispatch(clearSessionData())
        return;
      }

      const savedSessionId = Cookies.get('session_id');
      const sessionExists = listResponse.data.sessions.some(
        session => session.session_id === savedSessionId
      );
      
      const targetSessionId = (savedSessionId && sessionExists) ? 
        savedSessionId : 
        listResponse.data.sessions[0].session_id;

      Cookies.set('session_id', targetSessionId);
      
      // Use existing getSessionDetails function
      await getSessionDetails(targetSessionId);
    } catch (error) {
      console.error('Initialization error:', error);
    }
  };

  const { mode, setMode } = useColorScheme();
  if (!mode) {
    return null;
  }

  const addApiResponse = (response) => {
    const timestamp = new Date().toLocaleString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    setApiResponses((prev) => [...prev, { ...response, showDetails: false, timestamp }]);
  };

  const toggleResponseDetails = (index) => {
    setApiResponses((prev) =>
      prev.map((resp, i) =>
        i === index ? { ...resp, showDetails: !resp.showDetails } : resp
      )
    );
  };

  const formatJSON = (json) => {
    try {
      return JSON.stringify(JSON.parse(json), null, 2);
    } catch {
      return json;
    }
  };

  const openModal = (content) => {
    setModalContent(formatJSON(content));
    setModalOpen(true);
    setTimeout(() => {
      if (modalRef.current) {
        modalRef.current.classList.add('open');
      }
    }, 10);
  };

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.classList.remove('open');
    }
    setTimeout(() => {
      setModalOpen(false);
    }, 300); // Match this with the transition duration
  };

  const showToast = (message, type = 'info') => {
    setToast({ show: true, message, type });
    showNotice(message, type)
    setTimeout(() => {
      setToast({ show: false, message: '', type: '' });
    }, 3000);
  };

  const startRenameSession = (id, currentName) => {
    setEditingSessionId(id);
    setNewSessionName(currentName);
  };

  const cancelRenameSession = () => {
    setEditingSessionId(null);
    setNewSessionName('');
  };

  const confirmRenameSession = async (id) => {
    if (!newSessionName || !newSessionName.trim()) {
      showToast('Please enter a new name for the session');
      return;
    }
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/session/rename_session`,
        { session_id: id, new_name: newSessionName },
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );
      addApiResponse(response.data);
      fetchSessionList();
      setEditingSessionId(null);
      setNewSessionName('');
      showToast('Session renamed successfully');
    } catch (error) {
      console.error('Error renaming session:', error);
      addApiResponse({ status: false, message: 'Error renaming session' });
      showToast('Failed to rename session');
    }
  };

  const initiateDeleteSession = (sessionId, sessionName) => {
    setDeleteConfirmation({ id: sessionId, name: sessionName });
  };

  const cancelDeleteSession = () => {
    setDeleteConfirmation(null);
  };

  const deleteSession = async (sessionId) => {
    try {
      setIsDeleting(true);
      setDeleteConfirmation(null); // Close the confirmation modal

      const response = await axios.post(
        `${API_BASE_URL}/api/v1/session/delete_session`,
        { session_id: sessionId },
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );

      // Fetch updated session list after deletion
      const updatedListResponse = await fetchSessionList();
      
      const hasRemainingSessions = updatedListResponse.data?.sessions && 
                                  Array.isArray(updatedListResponse.data.sessions) && 
                                  updatedListResponse.data.sessions.length > 0;

      if (!hasRemainingSessions) {        
        dispatch(clearSessionData())
      } else {
        const firstSessionId = updatedListResponse.data.sessions[0].session_id;
        Cookies.set('session_id', firstSessionId);
        await getSessionDetails(firstSessionId);
      }

      showToast('Session deleted successfully');
    } catch (error) {
      console.error('Error deleting session:', error);
      showToast('Failed to delete session');
    } finally {
      setIsDeleting(false);
    }
  };

  const confirmDeleteSession = async () => {
    if (deleteConfirmation) {
      await deleteSession(deleteConfirmation.id);
    }
  };

  const openSessionList = () => {
    setShowSessionList(true);
  };

  const renameSession = async (sessionId, newName) => {
    try {
      setIsRenaming(true);
      const response = await axios.post(`${API_BASE_URL}/api/v1/session/rename_session`, {
        session_id: sessionId,
        new_name: newName
      }, {
        headers: { 
          Authorization: `Bearer ${Cookies.get('token')}` 
        }
      });
      
      await fetchSessionList();
      setEditingSessionId(null);
      setNewSessionName('');
      showToast('Session renamed successfully', 'success');
    } catch (error) {
      console.error('Error renaming session:', error);
      showToast('Failed to rename session', 'error');
    } finally {
      setIsRenaming(false);
    }
  };

  const formatPrettyJson = () => {
    try {
      const parsed = JSON.parse(modalContent);
      setModalContent(JSON.stringify(parsed, null, 2));
    } catch (error) {
      showToast('Invalid JSON format', 'error');
    }
  };

  const renderUserAvatar = () => {
    // Check if accountInfo.userToken
    if(accountInfo.userToken !== '') {
      return <AccountMenu accountInfo={accountInfo} auth={auth} />
    }
    {/* Render the authentication form conditionally */}
    return (
    <IconButton onClick={toggleAuthForm} disabled={isAnyProcessRunning}>
        <Typography variant="button">Login</Typography> {/* Login button */}
    </IconButton>
    )
  };

  // Centralized feature request handling
  const handleFeatureRequest = (featureId, featureType) => {
    // Track which features were requested
    const requestData = {
      featureId,
      featureType, // 'resolution', 'ai_model', etc.
      timestamp: new Date(),
      sessionId: sessionId
    };
    
    // You could send this to your backend
    console.log('Feature requested:', requestData);
    
    // Show consistent feedback
    showToast(`Thank you for your interest! We'll notify you when this feature becomes available.`, 'success');
    
    return true; // Return true to indicate request was recorded
  };

  // Move renderProcessContent inside App
  const renderProcessContent = (processId) => {
    const currentChainIndex = ProcessChainManager.processOrder.indexOf(processId);
    const prevProcess = ProcessChainManager.processOrder[currentChainIndex - 1];
    const nextProcess = ProcessChainManager.processOrder[currentChainIndex + 1];
    const prevData = glowData[prevProcess][`${prevProcess}_response`] || {};
    const data = glowData[processId][`${processId}_response`] || {};
    const ProcessComponent = processComponents[processId];
    if (!ProcessComponent) return null;
    console.log(`Process data ${processId}_response`, data);
    
    return (
      <ProcessComponent
        prevData={prevData}
        data={data}
        isAnyProcessRunning={isAnyProcessRunning}
        onComplete={(updateData = {}) => {    
          if (updateData?.processId && updateData?.data) {
            dispatch(updateGlowData({glowId: updateData.processId, data: updateData.data}))
          }
          handleProcessTransition(nextProcess)
        }}
        sessionId={sessionId} 
        showNotice={showNotice}    
      />
    );
  };

  // Move handleProcessSelect inside App
  const handleProcessSelect = async (processId) => {
    // Simply set the current process without any checks
    setCurrentProcess(processId);
  };

  // Add this helper function to compare input text with session data
  const hasInputChanged = (currentInput) => {
    const savedInput = sessionData?.inputPrompt;
    return !savedInput || currentInput.trim() !== savedInput.trim();
  };

  // Handle session registering with idea and company id
  const initializeSessionWithIdea = async (isNew) => {
    if (!isNew) return sessionId;
    
    try {
      setIsCreating(true);
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/company_project/create_idea`,
        { company_id: selectedCompany, input_prompt: inputText },
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );

      
      if (response.data.status) {
        const newSessionId = response.data.data.session_id;
        
        // Clear the cache before setting new session
        ProcessChainManager.clearAllCache();
        getSessionDetails(newSessionId); 
        Cookies.set('session_id', newSessionId);
        addApiResponse(response.data);
                      
        fetchSessionList();
        showToast('New session created successfully', 'success');
        return newSessionId;
      }
    } catch (error) {
      console.error('Error creating session:', error);
      addApiResponse({ status: false, message: 'Error creating session' });
      showToast('Failed to create new session', 'error');
      throw error;
    } finally {
      setIsCreating(false);
    }
  }

  // Update handleRunMainProcess to use hasInputChanged instead
  const handleRunMainProcess = async (targetProcess = 'glow_08') => {
    if (isAnyProcessRunning) return;
    
    try {
      setIsAnyProcessRunning(true);
      
      const isNew = hasInputChanged(inputText);
      
      // Initialize session (new or existing)
      const targetSessionId = await initializeSessionWithIdea(isNew);
      
      // Update UI state based on target process
      setCurrentProcess(targetProcess);
      
      // Show appropriate toast message
      const toastMessage = targetProcess === 'glow_61' 
        ? 'Moving to Video Creation' 
        : 'Moving to Initial Questions';
      showToast(toastMessage, 'success');
      
    } catch (error) {
      console.error('Error transitioning to process:', error);
      showToast(error.message || 'Failed to transition', 'error');
    } finally {
      setIsAnyProcessRunning(false);
    }
  };

  const handleProcessTransition = async ( nextProcessId ) => {    
    if (!nextProcessId) return;
    try {
      setIsAnyProcessRunning(true);

      // Check if next process is preloaded
      let response = glowData[nextProcessId];
      
      if (!response || Object.keys(response).length === 0) {
        await dispatch(runProcess({ processId: nextProcessId }))          
      }
      setCurrentProcess(nextProcessId);
      showNotice(processConfig.processes.find(p => p.id === nextProcessId)?.completedText)
      setIsAnyProcessRunning(false);
      // Preload next process
      const subsequentProcess = ProcessChainManager.processOrder[
        ProcessChainManager.processOrder.indexOf(nextProcessId) + 1
      ];
      
      if (subsequentProcess && !completedProcesses[subsequentProcess]) {
        dispatch(runProcess({ processId: subsequentProcess }))  
      }

    } catch (error) {
      console.error('Error in process transition:', error);
      showNotice(error.message || 'Error transitioning to next process', 'error')
    } finally {
      setIsAnyProcessRunning(false);
    }
  };

  const handleButtonHighlight = (highlightInfo) => {
    if (highlightInfo) {
      const button = document.getElementById(highlightInfo.buttonId);
      if (button) {
        // Add highlight class for styled-components
        button.classList.add('highlight');
        
        // Add direct styles
        button.style.animation = `pulse ${highlightInfo.highlight.duration}ms infinite`;
        button.style.backgroundColor = highlightInfo.highlight.color;
        button.style.boxShadow = '0 0 10px rgba(253, 152, 0, 0.5)';
      }
    } else {
      // Clear all button highlights
      ['start-analysis-button', ...[...document.querySelectorAll('[id^="glow-"]')].map(el => el.id)]
        .forEach(id => {
          const button = document.getElementById(id);
          if (button) {
            button.classList.remove('highlight');
            button.style.animation = '';
            button.style.backgroundColor = '';
            button.style.boxShadow = '';
          }
        });
    }
  };

  const handleSessionSelect = async (selectedSessionId) => {
    try {
      Cookies.set('session_id', selectedSessionId);
      await getSessionDetails(selectedSessionId);
      setShowSessionList(false);
      setCurrentProcess('idea_description');
    } catch (error) {
      console.error('Error selecting session:', error);
      showToast('Failed to load session', 'error');
    }
  };

  const handleDeleteSession = (sessionId) => {
    initiateDeleteSession(sessionId);
  };

  const handleRenameSession = (sessionId) => {
    setEditingSessionId(sessionId);
  };

  return (
      <div className="App" onClick={null}>
        <AppBar 
          position="fixed" 
          sx={{ 
            zIndex: 1200,
            backgroundImage: 'unset',
            bgcolor: isDarkTheme ? '#1C1C1E' : '#FFFFFF',
            borderBottom: `1px solid var(--color-border)`,
            boxShadow: 'none',
            height: '60px',
            // color: isDarkTheme ? '#FFFFFF' : '#1C1C1E',
            '& .MuiIconButton-root': { 
              color: isDarkTheme ? '#FFFFFF' : '#1C1C1E'
            }
          }}
        >
          <Toolbar sx={{ height: '60px', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {isMobile && (
                <IconButton 
                  edge="start" 
                  onClick={() => setMobileOpen(!mobileOpen)}
                  sx={{ display: { sm: 'none' } }}
                >
                  <FaBars />
                </IconButton>
              )}
              <IconButton 
                onClick={handleSessionListClick}
                sx={{ display: { xs: 'none', sm: 'block' } }}
                disabled={isAnyProcessRunning || isCreating}
              >
                <FaList /> 
              </IconButton>
              <IconButton onClick={handleDevToolsClick} disabled={isAnyProcessRunning}>
                <FaTools />
              </IconButton>
              <IconButton 
                onClick={toggleTheme} 
                disabled={isAnyProcessRunning}
                sx={{ 
                  color: isDarkTheme ? '#FFFFFF' : '#1C1C1E',
                  '&:hover': {
                    color: 'var(--color-primary)'
                  }
                }}
              >
                <ContrastIcon />
              </IconButton>
              <IconButton 
                onClick={() => setCompanyDrawerOpen(true) }
                sx={{ display: { xs: 'none', sm: 'block' } }}
                disabled={isAnyProcessRunning || isCreating}
              >
                <FaBuilding /> 
              </IconButton>
            </Box>
            
            {/* Logo - centered on mobile */}
            <Box sx={{ 
              position: 'absolute', 
              margin: '0 auto',
              left: '0',
              right: '0',
              width: { xs: 'fit-content', sm: 'fit-content' }  // Changed fixed width to fit-content
            }}>
              <img 
                src={isDarkTheme ? '/images/logo-dark-desktop.png' : '/images/logo-light-desktop.png'} 
                alt="Logo" 
                style={{ 
                  height: isMobile ? '35px' : '63px',
                  width: 'auto',
                  objectFit: 'contain',  // Added to maintain aspect ratio
                  maxWidth: 'none'       // Added to prevent container constraints
                }}
              />
            </Box>
            { renderUserAvatar() }
          </Toolbar>
        </AppBar>

        <CreditFlag 
          accountInfo={accountInfo}
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={noticeState.open}
          onClose={handleNoticeClose}
          key={noticeState.vertical + noticeState.horizontal}
          autoHideDuration={noticeState.duration}
        >
          <Alert
            onClose={handleNoticeClose}
            severity={noticeState.severity}
            variant='filled'
            sx={{ width: '100%' }}
          >
            { noticeState.message ?? ''}
          </Alert>
        </Snackbar>
        <Box sx={{ display: 'flex', pt: '60px' }}>
          {/* Desktop Stepper */}
          <Box sx={{ 
            display: { xs: 'none', sm: 'block' },
            width: '280px',
            flexShrink: 0,
            position: 'fixed',
            left: 0,
            top: '60px',
            height: 'calc(100vh - 60px)',
            overflowY: 'auto',
            borderRight: `1px solid ${theme.palette.divider}`
          }}>
            <MultiLevelStepper 
              onProcessSelect={handleProcessSelect}
              activeProcessId={currentProcess}
              completedProcesses={completedProcesses}
              isProcessRunning={isAnyProcessRunning || sessionStatus === 'loading'}
              isDarkTheme={isDarkTheme}
              onButtonHighlight={handleButtonHighlight}
              sessionId={sessionId}
            />
          </Box>

          {/* Main Content */}
          <Box 
            component="main" 
            sx={{ 
              flexGrow: 1,
              width: { xs: '100%', sm: `calc(100% - 280px)` },
              ml: { xs: 0, sm: '280px' },
              p: { xs: 2, sm: 3 },  // Adjusted padding
              height: 'calc(100vh - 60px)',
              overflow: 'auto',
              '& .process-view-container': {  // Add this
                width: '100%',
                maxWidth: '100%',
                '& .MuiFormControl-root': {
                  width: '100%'
                }
              }
            }}
          >
            {(!currentProcess || currentProcess === 'idea_description') ? (
              <IdeaInputCard 
                inputText={inputText}
                setInputText={setInputText}
                hasUnsavedChanges={hasUnsavedChanges}
                setHasUnsavedChanges={setHasUnsavedChanges}
                onRun={handleRunMainProcess}
                config={processConfig.initialProcess}
                isDarkTheme={isDarkTheme}
              />
            ) : (
              renderProcessContent(currentProcess)
            )}
          </Box>
        </Box>

        {/* Session List Dialog */}
        {showSessionList && (
          <Dialog 
            open={showSessionList} 
            onClose={() => setShowSessionList(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              Sessions
              <IconButton
                onClick={() => setShowSessionList(false)}
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <FaTimes />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <List>
                {sessionList.filter(session => session.company_id === selectedCompany).map((session) => (
                  <ListItem
                    key={session.session_id}
                    selected={session.session_id === sessionId}
                    onClick={() => handleSessionSelect(session.session_id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <ListItemText 
                      primary={session.session_name || `Session ${session.session_id}`} 
                      secondary={new Date(session.created_at).toLocaleString()}
                    />
                    <Box>
                      <IconButton 
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingSessionId(session.session_id);
                        }}
                        disabled={isRenaming}
                      >
                        <FaEdit />
                      </IconButton>
                      <IconButton 
                        onClick={(e) => {
                          e.stopPropagation();
                          initiateDeleteSession(session.session_id, session.session_name);
                        }}
                        disabled={isDeleting}
                      >
                        <FaTrash />
                      </IconButton>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </DialogContent>
          </Dialog>
        )}

        {/* Dev Tools Panel */}
        {devToolOpen && (
          <Dialog 
            open={devToolOpen} 
            onClose={() => setDevToolOpen(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              Developer Tools
              <IconButton
                onClick={() => setDevToolOpen(false)}
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <FaTimes />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ mb: 2 }}>
                <h3>Session Details</h3>
                <pre style={{ overflow: 'auto' }}>
                  {JSON.stringify(sessionData, null, 2)}
                </pre>
              </Box>
              <Box>
                <h3>API Responses</h3>
                <pre style={{ overflow: 'auto' }}>
                  {JSON.stringify(apiResponses, null, 2)}
                </pre>
              </Box>
            </DialogContent>
          </Dialog>
        )}
        
        {/* Pass firebaseApp to AuthForms */}
        {showAuthForm && 
          <Dialog 
            open={showAuthForm} 
            maxWidth="sm"
            fullWidth
          >
            <DialogContent sx={{backgroundColor: 'background.default'}}>
              <Authenticate 
              auth={auth}
              showNotice={showNotice}
              setAuthFormVisible={setShowAuthForm}
              />
            </DialogContent>
        </Dialog>}

        {/* Mobile Drawer - only add this */}
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { 
              width: 280,
              backgroundColor: theme.palette.background.default
            },
          }}
        >
          <MultiLevelStepper 
            onProcessSelect={handleProcessSelect}
            activeProcessId={currentProcess}
            completedProcesses={completedProcesses}
            isProcessRunning={isAnyProcessRunning || sessionStatus === 'loading'}
            isDarkTheme={isDarkTheme}
            onButtonHighlight={handleButtonHighlight}
            sessionId={sessionId}
          />
        </Drawer>

        {/* Company management */}
        <Drawer
          sx={{
            '& .MuiDrawer-paper': { 
              width: 400,
            },
          }}
          anchor="left"
          open={companyDrawerOpen}
          onClose={() => setCompanyDrawerOpen(false)} 
        >
          <Companies />
        </Drawer>
      </div>
  );
}

export default Journey;
