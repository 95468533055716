import React, { useState, useEffect } from 'react';
import ViewerHeader from '../common/ViewerHeader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Chip,
  Tooltip,
} from '@mui/material';
import {
  AlertCircle,
  CheckCircle,
  Clock,
  Play,
  Pause,
  RefreshCw,
  Eye,
  Filter,
  ArrowUpDown
} from 'lucide-react';

const VIDEO_TYPES = [
  { id: 'PV', name: 'Product Validation' },
  { id: 'AG', name: 'API Guide' },
  { id: 'AF', name: 'Analysis Framework' },
];


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const STATUS_BADGES = {
  pending: { color: 'bg-yellow-100 p-1 text-yellow-800', icon: Clock },
  processing: { color: 'bg-blue-100 p-1 text-blue-800', icon: RefreshCw },
  completed: { color: 'bg-green-100 p-1 text-green-800', icon: CheckCircle },
  failed: { color: 'bg-red-100 p-1 text-red-800', icon: AlertCircle },
};

const SAMPLE_VIDEOS = [
  // {
  //   id: 1,
  //   input_prompt: "Create a validation video for an AI-powered knowledge visualization tool",
  //   video_type: "PV",
  //   status: "completed",
  //   created_at: "2024-01-02T10:00:00Z",
  //   started_at: "2024-01-02T10:01:00Z",
  //   completed_at: "2024-01-02T10:05:00Z",
  //   estimated_duration: "5 minutes",
  //   actual_duration: "4 minutes",
  //   voice_by: "morgan_freeman",
  //   target_video_duration: 30,
  //   preview_url: "/api/placeholder/640/360",
  //   progress: 100,
  // },
  // {
  //   id: 2,
  //   input_prompt: "Create an API guide for REST endpoints",
  //   video_type: "AG",
  //   status: "processing",
  //   created_at: "2024-01-02T10:10:00Z",
  //   started_at: "2024-01-02T10:11:00Z",
  //   estimated_duration: "6 minutes",
  //   voice_by: "female_ref",
  //   target_video_duration: 45,
  //   progress: 60,
  // },
];

const VideoRequestManager = ({ sessionId, showNotice }) => {
  
  const [videos, setVideos] = useState(SAMPLE_VIDEOS);
  const [newRequest, setNewRequest] = useState({
    input_prompt: '',
    video_type: 'PV',
    target_video_duration: 30,
    voice_by: 'morgan_freeman'
  });
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [previewURL, setPreviewURL] = useState(null)

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const formatDuration = (seconds) => {
    return `${seconds} seconds`;
  };

  const formatDateTime = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const calculateProgress = (video) => {
    if (video.status === 'completed') return 100;
    if (video.status === 'pending') return 0;
    return video.progress || 0;
  };

  const fetchVideoRequests = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/queue_manager/get_video_requests`, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` }
      });
      
      const data = response.data;
      if (data.status && data.status_code === 200) {
        const videoList = data.data.map((video, index) => ({
          id: index,
          input_prompt: video.user_input,
          video_type: video.video_type,
          status: video.request_status.toLowerCase(),
          created_at: video.process_creation_time,
          started_at: video.glow1_start_time,
          completed_at: video.glow3_end_time || null,
          estimated_duration: video.glow1_duration || 'N/A',
          actual_duration: video.glow2_duration || 'N/A',
          voice_by: video.voice_by,
          target_video_duration: video.target_video_duration,
          preview_url: "/api/placeholder/640/360", // Placeholder URL, adjust as necessary
          progress: video.process_status === "Completed" ? 100 : (video.glow3_status === "Processing" ? 60 : 0),
        }));
        setVideos(videoList);
      } else {
        console.error('Failed to fetch video requests:', data.message);
      }
    } catch (error) {
      console.error('Error fetching video requests:', error);
    }
  };

  useEffect(() => {
    // fetchVideoRequests();
  }, []);

  const handleAddRequestToQueue = async (requestBody) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/v1/queue_manager/add_request_to_queue`, 
        requestBody,
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` }}
      );

      const data = await response.data();
      if (data.status && data.message === "Request added to queue successfully" && data.status_code === 200) {
        console.log('Request added to queue:', data);
        showNotice('Request added to queue!')
        return data;
      } else {
        console.error('Failed to add request to queue:', data);
        showNotice('Failed to add request to queue', 'error')
        return { status: false, message: 'Failed to add request to queue' };
      }
      // Optionally update state or handle success
    } catch (error) {
      console.error('Error adding request to queue:', error);
      // Optionally handle error
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const requestBody = {
      input_prompt: newRequest.input_prompt,
      session_id: sessionId, // Use the session ID from the cookie
      video_type: newRequest.video_type,
      number_of_target_group: 2, // Adjust as necessary
      target_video_duration: newRequest.target_video_duration,
      voice_by: newRequest.voice_by,
      fps: 12,
      augmentation_level: 0.02,
      width: 1280,
      height: 720
    };
    handleAddRequestToQueue(requestBody).then((response) => {
      // Add new data to table
      const request = {
        ...requestBody,
        id: Date.now(),
        status: 'pending',
        created_at: new Date().toISOString(),
        estimated_duration: '5 minutes',
        progress: 0,
      };      
      setVideos(prev => [request, ...prev]);

      // Reset new request object
      setNewRequest({
        input_prompt: '',
        video_type: '',
        target_video_duration: 30,
        voice_by: 'morgan_freeman'
      });
    })
  };

  return (
    <>
      <ViewerHeader
        title="Create Your Video"
        subtitle="Choose from our variety of video types and start creating your professional video in minutes"
        processId="glow_91"
      />
      <Box sx={{ p: 3 }}>
        {/* Request Form */}
        <Card className="mb-8">
          <CardHeader title="est" />
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="flex flex-column-md flex-row items-center justify-between gap-4">
                <div className="flex-grow" >
                  <label className="block text-sm font-medium mb-1">Video Type</label>
                  <Select
                    value={newRequest.video_type}
                    className="w-full"
                    onChange={(e) => setNewRequest(prev => ({ ...prev, video_type: e.target.value }))}
                  >
                    {VIDEO_TYPES.map(type => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="flex-grow" >
                  <label className="block text-sm font-medium mb-1">Voice</label>
                  <Select
                    value={newRequest.voice_by}
                    className="w-full"
                    onChange={(e) => setNewRequest(prev => ({ ...prev, voice_by: e.target.value }))}
                  >
                    <MenuItem value="morgan_freeman">Morgan Freeman</MenuItem>
                    <MenuItem value="female_ref">Female Reference</MenuItem>
                  </Select>
                </div>

                <div className="flex-grow" >
                  <label className="block text-sm font-medium mb-1">Duration (seconds)</label>
                  <TextField
                    type="number"
                    className="w-full"
                    value={newRequest.target_video_duration}
                    onChange={(e) => setNewRequest(prev => ({ ...prev, target_video_duration: parseInt(e.target.value) }))}
                    min="1"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Prompt</label>
                <TextField
                  value={newRequest.input_prompt}
                  className="w-full"
                  onChange={(e) => setNewRequest(prev => ({ ...prev, input_prompt: e.target.value }))}
                  placeholder="Enter video prompt"
                  fullWidth
                  required
                />
              </div>

              <Button type="submit" className="w-full" variant="outlined">
                Create Video
              </Button>
            </form>
          </CardContent>
        </Card>

        {/* Video List */}
        <Card>
          <CardHeader className="flex flex-row items-center justify-between" title='Video Requests'>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <span className="text-sm">View:</span>
                <Select defaultValue="my">
                  <MenuItem value="my">My Videos</MenuItem>
                  <MenuItem value="top">Top Videos</MenuItem>
                  <MenuItem value="all">All Videos</MenuItem>
                </Select>
              </div>
              <Button variant="outlined" size="small">
                <Filter className="w-4 h-4 mr-1" />
                Filter
              </Button>
            </div>
          </CardHeader>
          <CardContent>
            <div className="rounded-md border overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr>
                    <th scope="col" className="w-10 px-6 py-3"></th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium uppercase tracking-wider cursor-pointer"
                      onClick={() => handleSort('video_type')}
                    >
                      Type <ArrowUpDown className="inline w-4 h-4 ml-1" />
                    </th>
                    <th scope="col" className="px-6 py-3 text-xs font-medium uppercase tracking-wider">
                      Prompt
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium uppercase tracking-wider cursor-pointer"
                      onClick={() => handleSort('status')}
                    >
                      Status <ArrowUpDown className="inline w-4 h-4 ml-1" />
                    </th>
                    <th scope="col" className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Est. Time</th>
                    <th scope="col" className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Actual Time</th>
                    <th scope="col" className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Progress</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider">Actions</th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium uppercase tracking-wider cursor-pointer"
                      onClick={() => handleSort('created_at')}
                    >
                      Created <ArrowUpDown className="inline w-4 h-4 ml-1" />
                    </th>
                    <th scope="col" className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Started</th>
                    <th scope="col" className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Completed</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {videos.map((video) => (
                    <tr key={video.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                      </td>
                      <td className="px-6 py-4">
                        <Tooltip title={VIDEO_TYPES.find(t => t.id === video.video_type)?.name || video.video_type}>
                          <Chip label={video.video_type} variant="outlined" />
                        </Tooltip>
                      </td>
                      <td className="px-6 py-4">
                        <p className="truncate max-w-xs" title={video.input_prompt}>{video.input_prompt}</p>
                      </td>
                      <td className="px-6 py-4">
                        <Chip
                          className={STATUS_BADGES[video.status].color}
                          label={video.status}
                          icon={React.createElement(STATUS_BADGES[video.status].icon, {
                            className: 'w-4 h-4 mr-1 inline'
                          })}
                        />
                      </td>
                      <td className="px-6 py-4 text-sm">{formatDateTime(video.created_at)}</td>
                      <td className="px-6 py-4 text-sm">{video.started_at ? formatDateTime(video.started_at) : '-'}</td>
                      <td className="px-6 py-4 text-sm">{video.completed_at ? formatDateTime(video.completed_at) : '-'}</td>
                      <td className="px-6 py-4 text-sm">{video.estimated_duration}</td>
                      <td className="px-6 py-4 text-sm">{video.actual_duration || '-'}</td>
                      <td className="px-6 py-4">
                        <div className="w-full rounded-full h-2.5">
                          <div
                            className="bg-blue-600 h-2.5 rounded-full"
                            style={{ width: `${calculateProgress(video)}%` }}
                          ></div>
                        </div>
                        <span className="text-xs mt-1">
                          {calculateProgress(video)}%
                        </span>
                      </td>
                      <td className="px-6 py-4 text-right text-sm font-medium">
                        <div className="flex justify-end gap-2">
                          {video.status === 'pending' && (
                            <Button size="small" variant="outlined">
                              <Play className="w-4 h-4" />
                            </Button>
                          )}
                          {video.status === 'processing' && (
                            <Button size="small" variant="outlined" className="text-red-600">
                              <Pause className="w-4 h-4" />
                            </Button>
                          )}
                          {video.status === 'completed' && (
                            <Button size="small" variant="outlined" onClick={() => { setPreviewURL(video.preview_url ? video.preview_url : null) }}>
                              <Play className="w-4 h-4" />
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>
        
        <Dialog open={previewURL != null}>
          <DialogTitle>Video Preview</DialogTitle>
          <DialogContent>
            <div className="aspect-video rounded-lg overflow-hidden">
              <img
                src={previewURL}
                alt="Video preview"
                className="w-full h-full object-cover"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setPreviewURL(null) }}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default VideoRequestManager;