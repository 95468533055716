import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  styled,
  createTheme,
  ThemeProvider,
  Fade,
  Chip,
  IconButton,
  Tooltip,
  useMediaQuery,
  Container,
  Divider,
  Modal,
  Paper,
  Zoom,
  TextField
} from '@mui/material';
import {
  PlayCircle,
  TrendingUp,
  Close,
  Info,
  ArrowForward,
  CheckCircle,
  BarChart,
  Assessment
} from '@mui/icons-material';
import YouTube from 'react-youtube';

// Enhanced theme with more customization
const theme = createTheme({
  palette: {
    primary: {
      main: '#FD9800',
      light: '#FDB544',
      dark: '#D68100',
      contrastText: '#FFFFFF',
    },
    grey: {
      200: '#EFEFEF'
    },
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF'
    }
  },
  typography: {
    h1: {
      fontSize: '3.5rem',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h4: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    }
  },
  shape: {
    borderRadius: 12,
  },
  shadows: [
    'none',
    '0px 2px 8px rgba(0, 0, 0, 0.1)',
    '0px 4px 16px rgba(0, 0, 0, 0.1)',
    '0px 8px 24px rgba(0, 0, 0, 0.1)',
    // ... rest of the shadows
  ],
});

// Enhanced styled components
const MainContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  backgroundColor: 'var(--color-surface)',
  color: 'var(--color-text)'
}));

const GradientTypography = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: theme.spacing(2),
}));

const VideoPreviewCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  width: 240,
  flexShrink: 0,
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[3],
    '& .overlay': {
      opacity: 1
    }
  }
}));

const FeatureCard = styled(Paper, {
  shouldForwardProp: prop => prop !== 'active',
})(({ theme, active }) => ({
  padding: theme.spacing(3),
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  backgroundColor: active ? theme.palette.primary.main : theme.palette.background.paper,
  color: active ? theme.palette.primary.contrastText : theme.palette.text.primary,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[3],
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: active ? 'transparent' : `linear-gradient(90deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  '&:hover::before': {
    opacity: 1,
  },
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// Add sample video data with real YouTube IDs and thumbnails
const sampleVideos = [
  {
    id: '9xBvPr7m-3k', // This is just an example ID - we need real ones
    title: 'Validate Your Product with Glowtest',
    thumbnail: `https://img.youtube.com/vi/9xBvPr7m-3k/mqdefault.jpg`,
    duration: '0:34'
  },
  {
    id: 'jRxjUQbIOrk', // Updated with your new video ID
    title: 'Market Analysis',
    thumbnail: `https://img.youtube.com/vi/jRxjUQbIOrk/mqdefault.jpg`,
    duration: '0:36'
  },
  // Add more sample videos as needed
];

// Add YouTube player options
const youtubeOpts = {
  height: '100%',
  width: '100%',
  playerVars: {
    autoplay: 1,
    modestbranding: 1,
    rel: 0
  },
};

const Glow30Processor = ({ data, onContinue, isDarkTheme }) => {
  const [activeStep, setActiveStep] = useState(null);
  const [videoModal, setVideoModal] = useState({ open: false, videoId: null });
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [emailModal, setEmailModal] = useState({ open: false, email: '' });

  const features = [
    {
      icon: "🎯",
      title: "83% of startups fail due to poor market validation",
      subtitle: "Stop Wasting Time on Guesswork",
      description: "While your competitors spend months on market research, our AI tools help you define and validate your concept in days.",
      highlight: "AI-Powered Market Research & Strategy"
    },
    {
      icon: "🎥",
      title: "Save up to 90% on video production costs",
      subtitle: "Create Professional Videos Without the Cost",
      description: "Traditional video production costs thousands and takes weeks. Our AI creates stunning videos in minutes.",
      highlight: "AI Video Generation Technology"
    },
    {
      icon: "📈",
      title: "2.4x average improvement in ad performance",
      subtitle: "Reach Your Perfect Audience",
      description: "Stop burning money on wrong audiences. Our AI precisely targets your ideal customers across platforms.",
      highlight: "Smart Campaign Management"
    },
    {
      icon: "📊",
      title: "Reduce market entry risks by 75%",
      subtitle: "Make Data-Driven Decisions",
      description: "No more gut decisions. Get clear, actionable insights from real market data.",
      highlight: "Real-Time Analytics & Insights"
    }
  ];

  const handleVideoClick = (videoId) => {
    setVideoModal({ open: true, videoId });
  };

  const handleCTAClick = () => {
    setEmailModal({ ...emailModal, open: true });
  };

  const StatBadge = ({ value, label }) => (
    <Box sx={{ textAlign: 'center', p: 2 }}>
      <Typography variant="h4" color="primary" gutterBottom>
        {value}
      </Typography>
      <Typography variant="body2" color="var(--color-text-secondary)">
        {label}
      </Typography>
    </Box>
  );

  // Update the video gallery section
  const VideoGallery = () => (
    <Stack
      direction="row"
      spacing={2}
      sx={{ 
        overflowX: 'auto',
        pb: 2,
        px: 1,
        '&::-webkit-scrollbar': {
          height: 6,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.2)',
          borderRadius: 3,
        }
      }}
    >
      {sampleVideos.map((video, index) => (
        <VideoPreviewCard 
          key={video.id} 
          onClick={() => handleVideoClick(video.id)}
        >
          <Box sx={{ position: 'relative' }}>
            <img 
              src={video.thumbnail} 
              alt={video.title}
              style={{ 
                width: '100%', 
                height: 135, 
                objectFit: 'cover' 
              }}
            />
            <Box 
              className="overlay"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                bgcolor: 'rgba(0,0,0,0.4)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                opacity: 0,
                transition: 'opacity 0.2s'
              }}
            >
              <PlayCircle sx={{ fontSize: 48, color: 'white' }} />
            </Box>
            <Chip
              label={video.duration}
              size="small"
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 8,
                bgcolor: 'rgba(0,0,0,0.75)',
                color: 'white'
              }}
            />
          </Box>
          <CardContent>
            <Typography variant="subtitle2">{video.title}</Typography>
          </CardContent>
        </VideoPreviewCard>
      ))}
    </Stack>
  );

  return (
    <ThemeProvider theme={theme}>
      <MainContainer 
        maxWidth="lg" 
        sx={{
          bgcolor: isDarkTheme ? 'var(--color-background)' : '#FFFFFF',
          color: isDarkTheme ? 'var(--color-text-primary)' : '#1C1C1E'
        }}
      >
        {/* Enhanced Hero Section */}
        <Fade in timeout={1000}>
          <Box sx={{ textAlign: 'center', mb: 8 }}>
            <Chip
              label="AI-POWERED VIDEO CREATION"
              color="primary"
              size="small"
              sx={{ mb: 3 }}
            />
            <GradientTypography variant="h1" component="h1">
              Transform Your Ideas Into Market Success
            </GradientTypography>
            <Typography variant="h5" color="var(--color-text-secondary)" sx={{ mb: 4, maxWidth: 700, mx: 'auto' }}>
              Create stunning, market-tested videos with AI-powered technology
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                endIcon={<ArrowForward />}
                onClick={handleCTAClick}
                sx={{ 
                  borderRadius: 50,
                  px: 4,
                  py: 2,
                  mb: 4,
                  boxShadow: 3,
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: 4,
                  }
                }}
              >
                Request your Custom Videos Now
              </Button>
            </Stack>
          </Box>
        </Fade>

        {/* Stats Section */}
        <Box sx={{ mb: 8 }}>
          <Card elevation={2} sx={{ backgroundColor:'var(--card-bg)' }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              justifyContent="center"
              sx={{ py: 3 }}
            >
              <StatBadge value="90%" label="Cost Reduction" />
              <StatBadge value="2.4x" label="Performance Boost" />
              <StatBadge value="10k+" label="Active Users" />
            </Stack>
          </Card>
        </Box>

        {/* Enhanced Video Gallery */}
        <Box sx={{ mb: 8 }}>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 3 }}>
            <PlayCircle color="primary" sx={{ fontSize: 32 }} />
            <Typography variant="h4">
              Sample AI-Generated Videos
            </Typography>
            <Tooltip title="Click to play demo videos">
              <IconButton size="small">
                <Info />
              </IconButton>
            </Tooltip>
          </Stack>
          <VideoGallery />
        </Box>

        {/* Enhanced Features Grid */}
        <Box sx={{ 
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          gap: 3,
          mb: 8
        }}>
          {features.map((feature, index) => (
            <Zoom in timeout={500} style={{ transitionDelay: `${index * 100}ms` }}>
              <FeatureCard
                key={index}
                active={activeStep === index ? 'active' : ''}
                elevation={hoveredFeature === index ? 3 : 1}
                onMouseEnter={() => setHoveredFeature(index)}
                onMouseLeave={() => setHoveredFeature(null)}
                onClick={() => setActiveStep(activeStep === index ? null : index)}
              >
                <div className="flex gap-4">
                  <div className={`text-2xl ${
                    activeStep === index ? 'bg-white' : 'bg-orange-50'
                  } p-2 rounded-full h-12 w-12 flex items-center justify-center`}>
                    {feature.icon}
                  </div>
                  <div>
                    <h3 className="font-bold text-lg mb-2">{feature.title}</h3>
                    <p className={activeStep === index ? 'text-orange-100' : 'text-[#fd9800]'}>
                      {feature.subtitle}
                    </p>
                    <p className="mb-4">{feature.description}</p>
                    <p className={activeStep === index ? 'text-white' : 'text-[#fd9800]'}>
                      {feature.highlight}
                    </p>
                  </div>
                </div>
              </FeatureCard>
            </Zoom>
          ))}
        </Box>

        {/* Enhanced CTA Section */}
        <Card 
          sx={{ 
            p: 6,
            textAlign: 'center',
            backgroundColor:'var(--card-bg)',
            // background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`,

            border: `1px solid var(--border-color)`,
          }}
          elevation={0}
        >
          <Typography variant="h3" fontWeight="bold" color="text.primary" mb={4}>
            Market Testing Shouldn't Break Your Budget
          </Typography>

          {/* Price Comparison Cards */}
          <Stack 
            direction={{ xs: 'column', md: 'row' }} 
            spacing={3} 
            mb={4}
            justifyContent="center"
          >
            {/* Professional Commercial Card */}
            <Card sx={{ 
              flex: 1, 
              p: 3,
              bgcolor: 'grey.50',
              minWidth: { xs: '100%', md: '250px' }
            }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Professional Commercial
              </Typography>
              <Typography variant="h4" color="error.main" fontWeight="bold">
                $50,000-$150,000
              </Typography>
              <Typography variant="body2" color="var(--color-text-secondary)" mt={1}>
                Per 30-60 second video
              </Typography>
              <Typography variant="caption" color="var(--color-text-secondary)" sx={{ mt: 2, display: 'block' }}>
                Source: Zippia's Video Production Analysis, 2023
              </Typography>
            </Card>

            {/* Product Demo Card */}
            <Card sx={{ 
              flex: 1, 
              p: 3,
              bgcolor: 'grey.50',
              minWidth: { xs: '100%', md: '250px' }
            }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Product Demo Video
              </Typography>
              <Typography variant="h4" color="error.main" fontWeight="bold">
                $8,000-$15,000
              </Typography>
              <Typography variant="body2" color="var(--color-text-secondary)" mt={1}>
                Per professional demo
              </Typography>
              <Typography variant="caption" color="var(--color-text-secondary)" sx={{ mt: 2, display: 'block' }}>
                Source: Yans Media Production Costs, 2023
              </Typography>
            </Card>

            {/* Our AI Solution Card */}
            <Card sx={{ 
              flex: 1, 
              p: 3,
              bgcolor: 'primary.main',
              color: 'white',
              minWidth: { xs: '100%', md: '250px' }
            }}>
              <Typography variant="h6" color="inherit" gutterBottom>
                Our AI Platform
              </Typography>
              <Typography variant="h4" fontWeight="bold">
                $997/month
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Unlimited Test Videos
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                Generate in minutes, not weeks
              </Typography>
            </Card>
          </Stack>

          {/* Total Savings Highlight */}
          <Box sx={{ 
            bgcolor: 'success.light', 
            p: 3, 
            borderRadius: 2,
            mb: 4
          }}>
            <Typography variant="h5" color="success.dark" textAlign="center">
              Save $24,000+ on Your Market Testing (10 Videos)
            </Typography>
            <Typography variant="body2" color="success.dark" textAlign="center" mt={1}>
              Based on minimum professional production costs
            </Typography>
          </Box>

          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<ArrowForward />}
            onClick={handleCTAClick}
            sx={{ 
              borderRadius: 50,
              px: 4,
              py: 2,
              mb: 4,
              boxShadow: 3,
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: 4,
              }
            }}
          >
            Start Market Testing Now
          </Button>

          {/* Trust Badges */}
          <Divider sx={{ mb: 4 }} />
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={4} 
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <CheckCircle color="primary" />
              <Typography color="var(--color-text-secondary)">Same-Day Video Generation</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <CheckCircle color="primary" />
              <Typography color="var(--color-text-secondary)">Cancel Anytime</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <CheckCircle color="primary" />
              <Typography color="var(--color-text-secondary)">Expert Support Included</Typography>
            </Stack>
          </Stack>
        </Card>

        {/* Video Modal */}
        <StyledModal
          open={videoModal.open}
          onClose={() => setVideoModal({ open: false, videoId: null })}
        >
          <Box sx={{ 
            width: isMobile ? '90vw' : '80vw',
            maxWidth: 900,
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 1,
            position: 'relative'
          }}>
            <IconButton
              sx={{ position: 'absolute', right: -40, top: -40, color: 'white' }}
              onClick={() => setVideoModal({ open: false, videoId: null })}
            >
              <Close />
            </IconButton>
            {videoModal.videoId && (
              <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
                <YouTube
                  videoId={videoModal.videoId}
                  opts={youtubeOpts}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Box>
            )}
          </Box>
        </StyledModal>

        {/* Email Collection Modal */}
        <StyledModal
          open={emailModal.open}
          onClose={() => setEmailModal({ open: false, email: '' })}
        >
          <Box sx={{ 
            width: { xs: '90vw', sm: '400px' },
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 4,
            position: 'relative'
          }}>
            <IconButton
              sx={{ position: 'absolute', right: 8, top: 8 }}
              onClick={() => setEmailModal({ open: false, email: '' })}
            >
              <Close />
            </IconButton>
            
            <Typography variant="h5" fontWeight="bold" mb={3}>
              Get Your First AI Video Demo
            </Typography>
            
            <Typography variant="body2" color="var(--color-text-secondary)" mb={3}>
              Enter your business email to receive a personalized demo video for your industry
            </Typography>
            
            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Business Email"
                variant="outlined"
                value={emailModal.email}
                onChange={(e) => setEmailModal({ ...emailModal, email: e.target.value })}
                placeholder="john@company.com"
              />
              
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  // Here we would handle the email submission
                  console.log('Email submitted:', emailModal.email);
                  // TODO: Add API call to save email
                  //
                }}
              >
                Get My Demo Video
              </Button>
              
              <Typography variant="caption" color="var(--color-text-secondary)" textAlign="center">
                By continuing, you agree to our Terms of Service and Privacy Policy
              </Typography>
            </Stack>
          </Box>
        </StyledModal>
      </MainContainer>
    </ThemeProvider>
  );
};

export default Glow30Processor;
