import React from 'react';
import './glow_11_viewer_prodprice.css';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import processConfig from '../../config/processIndex.json';

const PricingDashboard = ({ data }) => {
  if (!data) {
    return <div>No pricing data available</div>;
  }

  // Destructure the data with default values
  const {
    product_cost_breakdown = [],
    market_pricing = [],
    suggested_price_points = [],
    customer_metrics = { cac: {}, ltv: {} }
  } = data;

  // Create data for the pricing comparison chart
  const pricingData = market_pricing.map(item => ({
    segment: item.segment,
    marketPrice: item.price,
    suggestedPrice: suggested_price_points.find(
      s => s.segment === item.segment
    )?.price || 0
  }));

  return (
    <div className="pricing-dashboard">
      {/* Product Cost Breakdown */}
      <div className="dashboard-card cost-breakdown">
        <h3>Product Cost Breakdown</h3>
        <div className="card-content">
          <ul>
            {product_cost_breakdown.map((item) => (
              <li key={item.component}>
                <span>{item.component}</span>
                <span>${item.cost}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Pricing Comparison Chart */}
      <div className="dashboard-card pricing-chart">
        <h3>Pricing Comparison</h3>
        <div className="card-content">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={pricingData}>
              <XAxis 
                dataKey="segment" 
                interval={0}
                height={60}
                tick={(props) => {
                  const { x, y, payload } = props;
                  const value = payload.value;
                  const maxLength = 10;

                  if (value.length > maxLength) {
                    const mid = Math.ceil(value.length / 2);
                    return (
                      <g transform={`translate(${x},${y})`}>
                        <text
                          fontSize={16}
                          fill="#FFFFFF"
                          textAnchor="end"
                          transform="rotate(-45)"
                        >
                          <tspan x="0" dy="0">{value.slice(0, mid)}-</tspan>
                          <tspan x="0" dy="16">{value.slice(mid)}</tspan>
                        </text>
                      </g>
                    );
                  }

                  return (
                    <g transform={`translate(${x},${y})`}>
                      <text
                        fontSize={16}
                        fill="#FFFFFF"
                        textAnchor="end"
                        transform="rotate(-45)"
                      >
                        {value}
                      </text>
                    </g>
                  );
                }}
              />
              <YAxis 
                tick={{ 
                  fontSize: 16,
                  fill: "#FFFFFF"
                }}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              <Bar 
                dataKey="marketPrice" 
                name="Market Price" 
                fill="#8884d8"
                barSize={30}
              />
              <Bar 
                dataKey="suggestedPrice" 
                name="Suggested Price" 
                fill="#82ca9d"
                barSize={30}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Customer Metrics Table */}
      <div className="dashboard-card customer-metrics">
        <h3>Customer Metrics</h3>
        <div className="card-content">
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ borderBottom: '1px solid var(--border-color)' }}>
                <th style={{ textAlign: 'left', padding: '8px' }}>Segment</th>
                <th style={{ textAlign: 'right', padding: '8px' }}>CAC</th>
                <th style={{ textAlign: 'right', padding: '8px' }}>LTV</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(customer_metrics.cac).map((segment) => (
                <tr 
                  key={segment}
                  style={{ borderBottom: '1px solid var(--border-color-light)' }}
                >
                  <td style={{ padding: '8px' }}>{segment}</td>
                  <td style={{ textAlign: 'right', padding: '8px' }}>
                    ${customer_metrics.cac[segment]}
                  </td>
                  <td style={{ textAlign: 'right', padding: '8px' }}>
                    ${customer_metrics.ltv[segment]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PricingDashboard;