import React, { useState, useEffect } from 'react';
import { TextField, Button, Box } from '@mui/material';

export default function CompanyForm({ onSubmit, initialData, onCancel }) {
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    if (initialData) {
      setCompanyName(initialData.company_name);
    } else {
      setCompanyName('');
    }
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (initialData) {
      onSubmit({ ...initialData, company_name: companyName });
    } else {
      onSubmit(companyName);
    }
    setCompanyName('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" alignItems="center">
        <TextField
          label="Company Name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <Box ml={2} display="flex" alignItems="center">
          <Button type="submit" variant="contained" color="primary"  size="small">
            {initialData ? 'Update' : 'Add'}
          </Button>
            {initialData && (
              <Button onClick={onCancel} variant="outlined" color="error" sx={{ ml: 1 }}  size="small">
              Cancel
            </Button>
          )}
        </Box>
      </Box>
    </form>
  );
}