import { Link } from '@mui/material';
import { Menu } from 'lucide-react'
export default function AdminLayout({ children }) {
  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside className="hidden md:flex md:flex-col md:w-64 bg-white shadow-md">
        <div className="p-4 border-b">
          <h2 className="text-2xl font-semibold">Admin Dashboard</h2>
        </div>
        <nav className="flex-1 p-4">
          <ul className="space-y-2">
            <li>
              <Link href="/admin" className="block p-2 hover:bg-gray-200 rounded">
                Dashboard
              </Link>
            </li>
            <li>
              <Link href="/admin/companies" className="block p-2 hover:bg-gray-200 rounded">
                Company Management
              </Link>
            </li>
          </ul>
        </nav>
      </aside>

      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Mobile header */}
        <header className="md:hidden bg-white shadow-md p-4 flex items-center">
          <button className="text-gray-500 focus:outline-none focus:text-gray-700">
            <Menu className="h-6 w-6" />
            <span className="sr-only">Open sidebar</span>
          </button>
          <h1 className="ml-4 text-lg font-semibold">Admin Dashboard</h1>
        </header>

        {/* Page content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          {children}
        </main>
      </div>
    </div>
  )
}

